import React, { useState, useEffect } from 'react';
import CreateAvailability from './CreateAvailability';
import { message } from 'antd';
import * as availabilityActions from '@/actions/availabilityActions';
import * as branchActions from '@/actions/branchActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import momentTimezone from 'moment-timezone';
import { useHistory } from 'react-router-dom';

const dayInitState = {
    active: false,
    startTime: '',
    endTime: '',
    message: ''
};

const AvailabilityModal = (props) => {
    const history = useHistory();
    const { availability, branchId, closeModal } = props;
    // closeModal, modal, index
    const dispatch = useDispatch();
    const { userId } = useSelector((state) => state.auth);
    const [createAvailabilityModal, setCreateAvailabilityModal] = useState(false);
    const [timezone, setTimezone] = useState({
        value: momentTimezone.tz.guess(),
        message: ''
    });
    const [alwaysAvailable, setAlwaysAvailable] = useState(false);
    const [sunday, setSunday] = useState({
        ...dayInitState
    });
    const [monday, setMonday] = useState({
        ...dayInitState
    });
    const [tuesday, setTuesday] = useState({
        ...dayInitState
    });
    const [wednesday, setWednesday] = useState({
        ...dayInitState
    });
    const [thursday, setThursday] = useState({
        ...dayInitState
    });
    const [friday, setFriday] = useState({
        ...dayInitState
    });
    const [saturday, setSaturday] = useState({
        ...dayInitState
    });
    const clearAvailabilityData = async () => {
        setSunday({ ...dayInitState });
        setMonday({ ...dayInitState });
        setTuesday({ ...dayInitState });
        setWednesday({ ...dayInitState });
        setThursday({ ...dayInitState });
        setFriday({ ...dayInitState });
        setSaturday({ ...dayInitState });
    };

    const initializeUpdateAvailability = async () => {
        const daySetters = {
            Sunday: setSunday,
            Monday: setMonday,
            Tuesday: setTuesday,
            Wednesday: setWednesday,
            Thursday: setThursday,
            Friday: setFriday,
            Saturday: setSaturday
        };

        const avail = availability;
        if (avail) {
            Object.keys(daySetters).forEach((day) => {
                const dayProp = avail[day.toLowerCase()];
                const isActive = dayProp ? true : false;
                const startTime = isActive ? dayProp.startTime : '';
                const endTime = isActive ? dayProp.endTime : '';
                daySetters[day]((prev) => ({
                    ...prev,
                    active: isActive,
                    startTime: startTime,
                    endTime: endTime
                }));
            });
            if (avail.alwaysAvailable) {
                setAlwaysAvailable(true);
            }
            setTimezone({
                value: avail.timezone ? avail.timezone : ''
            });
        }
        setCreateAvailabilityModal(true);
    };

    const saveTimings = async () => {
        let validity = await validate();
        console.log('always available',alwaysAvailable)
        if (validity == true) {
            let obj = {
                alwaysAvailable,
                sunday,
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday,
                timezone: timezone.value,
                branchId: branchId,
                updateBranch: true
            };
            let response;
            if (availability && availability._id) {
                obj.availabilityId = availability._id;
                response = await dispatch(
                    availabilityActions.updateAvailability({ ...props, obj }, history)
                );
            } else {
                response = await dispatch(
                    availabilityActions.createAvailability({ ...props, obj }, history)
                );
            }

            if (response) {
                clearAvailabilityData();
                setCreateAvailabilityModal(false);

                let obj = {
                    userId: userId,
                    populateAvailability: true,
                    userType: 'PHARMACY'
                };
                dispatch(branchActions.getBranches({ ...props, obj }, history));
                closeModal(false);
            }
        } else {
            message.error('Fix the errors below');
        }
    };
    const validate = async () => {
        let validity = true;
        let noTimingChosen = false;
        if (!timezone.value) {
            setTimezone({ ...timezone, message: 'Timezone is required' });
            validity = false;
            return;
        } else {
            setTimezone({ ...timezone, message: '' });
        }

        if (
            sunday &&
            !sunday.active &&
            monday &&
            !monday.active &&
            tuesday &&
            !tuesday.active &&
            wednesday &&
            !wednesday.active &&
            thursday &&
            !thursday.active &&
            friday &&
            !friday.active &&
            saturday &&
            !saturday.active
        ) {
            noTimingChosen = true;
            validity = false;
        }
        if (
            (sunday && sunday.active && !sunday.startTime) ||
            (monday && !monday.active && !monday.startTime) ||
            (tuesday && !tuesday.active && !tuesday.startTime) ||
            (wednesday && !wednesday.active && !wednesday.startTime) ||
            (thursday && !thursday.active && !thursday.startTime) ||
            (friday && !friday.active && !friday.startTime) ||
            (saturday && !saturday.active && !saturday.startTime)
        ) {
            if (sunday && sunday.active && !sunday.startTime) {
                setSunday({
                    ...sunday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (monday && monday.active && !monday.startTime) {
                setMonday({
                    ...monday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (tuesday && tuesday.active && !tuesday.startTime) {
                setTuesday({
                    ...tuesday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (wednesday && wednesday.active && !wednesday.startTime) {
                setWednesday({
                    ...wednesday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (thursday && thursday.active && !thursday.startTime) {
                setThursday({
                    ...thursday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (friday && friday.active && !friday.startTime) {
                setFriday({
                    ...friday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (saturday && saturday.active && !saturday.startTime) {
                setSaturday({
                    ...saturday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
        }

        if(alwaysAvailable){
            validity = true
        }
        return validity;
    };

    useEffect(() => {
        if (props.modal && props.modal == true) {
            setCreateAvailabilityModal(true);
            initializeUpdateAvailability();
        } else {
            setCreateAvailabilityModal(false);
            closeModal(props.index);
        }
    }, []);

    return (
        <CreateAvailability
            availability={availability}
            createAvailabilityModal={createAvailabilityModal}
            setCreateAvailabilityModal={setCreateAvailabilityModal}
            sunday={sunday}
            setSunday={setSunday}
            monday={monday}
            setMonday={setMonday}
            tuesday={tuesday}
            setTuesday={setTuesday}
            wednesday={wednesday}
            setWednesday={setWednesday}
            thursday={thursday}
            setThursday={setThursday}
            friday={friday}
            setFriday={setFriday}
            saturday={saturday}
            setSaturday={setSaturday}
            saveTimings={saveTimings}
            clearAvailabilityData={clearAvailabilityData}
            initializeUpdateAvailability={initializeUpdateAvailability}
            closeModal={closeModal}
            timezone={timezone}
            setTimezone={setTimezone}
            alwaysAvailable={alwaysAvailable}
            setAlwaysAvailable={setAlwaysAvailable}
            index={props.index}
            {...props}
        />
    );
};

export default AvailabilityModal;
