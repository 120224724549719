import React, { useState, useEffect, memo } from 'react';
import '@/assets/css/hospitalView.css';
import LowerSection from './LowerSection';
import { useParams } from 'react-router-dom';
import * as therapistActions from '@/actions/therapistActions';
import * as doctorActions from '@/actions/doctorActions';
import * as consultantActions from '@/actions/consultantActions';
import * as nurseActions from '@/actions/nurseActions';
import * as caregiverActions from '@/actions/caregiverActions';
import * as reviewActions from '@/actions/reviewActions';
import * as messagingActions from '@/actions/messagingActions';
import * as generalActions from '@/actions/generalActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { Select } from 'antd';
import * as marketingActions from '@/actions/marketingAction';
import profilePlaceholderImg from '@/assets/images/profilePlaceholder.jpeg';
import placeholderCover from '@/assets/images/coverPlaceholder.jpeg';
import MessageModal from '../../../modals/MessageModal';
import ReviewModal from '../../../modals/ReviewModal';
import * as availabilityActions from '@/actions/availabilityActions';
import { FaXTwitter } from 'react-icons/fa6';

const { Option } = Select;

const actionsMap = {
    doctor: doctorActions.getDoctorById,
    therapist: therapistActions.getTherapistById,
    consultant: consultantActions.getConsultantById,
    nurse: nurseActions.getNurseById,
    caregiver: caregiverActions.getCaregiverById
};

const getStatCountsMap = {
    doctor: doctorActions.getDoctorStatCounts,
    consultant: consultantActions.getConsultantStatCounts,
    therapist: therapistActions.getTherapistStatCounts,
    nurse: nurseActions.getNurseStatCounts,
    caregiver: caregiverActions.getCaregiverStatCounts
};

const DetailView = (props) => {
    const dispatch = useDispatch();
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [rating, setRating] = useState(0);
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    let { id } = useParams();

    const {
        marketingInfo,
        auth: { isAuthenticated, userType },
        history
    } = props;
    const memberType = props.location.pathname.split('/')[1].split('-')[0];
    const member = useSelector(
        (state) => state[`${memberType === 'doctor' ? 'doctors' : memberType}`]
    )[`${memberType}Single`];

    const memberStats = useSelector(
        (state) =>
            state[`${memberType === 'doctor' ? 'doctors' : memberType}`][`${memberType}StatCounts`]
    );
    console.log(memberType);

    const getMember = async () => {
        const action = actionsMap[memberType];
        if (action) {
            await dispatch(action({ ...props, [`${memberType}Id`]: id }, props.history));
        }
    };

    const [tab, setTab] = useState('general');
    const tabs = ['doctors', 'general', 'blogs', 'following', 'reviews'];
    const handleTab = (string) => {
        if (tabs.includes(string)) {
            setTab(string);
            props.history.push(`/${memberType}-view/${id}?tab=${string}`);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        getMember();
        getMarketingInfo();
        getMemberStatCounts();
        let searchQuery = queryString.parse(props.location && props.location.search);
        if (searchQuery && !searchQuery.tab) {
            setTab('general');
        } else if (searchQuery && searchQuery.tab) {
            let tab = searchQuery && searchQuery.tab;
            setTab(tab);
        }
    }, [id]);

    const getMemberStatCounts = async () => {
        let obj = {
            [`${memberType}Id`]: id
        };
        const action = getStatCountsMap[memberType];
        if (action) {
            await dispatch(getStatCountsMap[memberType]({ ...props, obj }, props.history));
        }
    };

    const getMarketingInfo = async () => {
        let obj = {
            userId: id
        };
        await props.marketingActions.getMarketingInfo({ ...props, obj }, props.history);
    };

    useEffect(() => {
        let obj = {
            userId: id
        };
        props.availabilityActions.getViewAvailability({ ...props, obj }, props.history);
    }, []);

    const handleCancel = () => {
        setModalOpen(false);
    };

    const addReview = async () => {
        let obj = {
            rating: rating,
            reviewer: props.auth.user._id,
            reviewerType: props.auth.user.userType,
            reviewedUser: member?._id,
            reviewedUserType: memberType.toUpperCase(),
            description: description,
            shortDescription: shortDescription
        };
        let postedReview = await props.reviewActions.postReview({ ...props, obj }, props.history);
        if (postedReview) {
            setModalOpen(false);
        }
    };

    const sendMessage = async () => {
        if (!message) {
            message.error('Message field cannot be empty');
        } else {
            let obj = {
                message: message,
                receiver: member?._id,
                receiverType: member?.userType
            };
            let sendMessage = await props.messagingActions.createConversation(
                { ...props, obj },
                props.history
            );
            if (sendMessage) {
                setMessageModal(false);
                setMessage('');
            } else {
                console.log('error');
            }
        }
    };

    let topBarStyle = {};
    if (marketingInfo && marketingInfo.topBarContent) {
        if (marketingInfo.topBarContent.backgroundColor) {
            topBarStyle.backgroundColor = marketingInfo.topBarContent.backgroundColor;
        }
    }

    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };

    const makeCall = async () => {
        window.open(
            `tel:${member && member.countryCode && member.countryCode}${
                member && member.phoneNo && member.phoneNo
            }`
        );
    };
    const goToSite = async () => {
        if (member && member.website) {
            window.open(`https://${member && member.website && member.website}`);
        }
    };

    useEffect(() => {
        if (member) {
            if (member?.hospitalBranches?.length > 0) {
                if (props.location && props.location.search) {
                    let search = queryString.parse(props.location.search);
                    console.log('branchId', search.branchId);
                    if (search.branchId) {
                        setSelectedAddress(search.branchId);
                        for (let i = 0; i < member.hospitalBranches.length; i++) {
                            console.log(i);
                            let branch = member.hospitalBranches[i];
                            if (branch._id === search.branchId) {
                                setSelectedAddressIndex(i);
                                console.log('selected', i, branch._id, search.branchId);
                                break;
                            }
                        }
                        // setSelectedAddressIndex()
                    } else {
                        setSelectedAddress(
                            member.hospitalBranches[0] && member.hospitalBranches[0]._id
                        );
                        setSelectedAddressIndex(0);
                    }
                } else {
                    setSelectedAddress(
                        member.hospitalBranches[0] && member.hospitalBranches[0]._id
                    );
                    setSelectedAddressIndex(0);
                }
            }
        }
    }, [member]);

    const lines = ['title', 'header', 'grabber', 'line1', 'line2', 'line3', 'line4', 'line5'];

    const renderLines = (content) => {
        return lines.map((line) => {
            if (content[line]) {
                return (
                    <p
                        style={{
                            ...(content?.[line].style || {}),
                            padding: '10px 0px',
                            textAlign: 'center'
                        }}
                    >
                        {content?.[line]?.text}
                    </p>
                );
            }
        });
    };

    return (
        <div className='hospital-view-background'>
            {member && (
                <div className='hospital-view-main'>
                    <div className='hospital-view-header'>
                        <div className='hospital-view-header-container'>
                            <div className='hospital-view-name'>
                                <p style={{ fontSize: member?.hospitalName ? 16 : 18 }}>
                                    {member.firstName + ' ' + member.lastName}
                                </p>
                                <small style={{ fontSize: 12 }}>{member?.hospitalName}</small>
                            </div>
                            <div className='hospital-view-heder-right'>
                                <div className='hospital-view-section'>
                                    <div
                                        className='hospital-view-sec'
                                        onClick={() => handleTab('blogs')}
                                    >
                                        <p>
                                            {memberStats && memberStats.posts
                                                ? memberStats.posts
                                                : 0}{' '}
                                            Posts
                                        </p>
                                    </div>
                                    <div className='hospital-view-sec'>
                                        <p>
                                            {memberStats && memberStats.followers
                                                ? memberStats.followers
                                                : 0}{' '}
                                            Followers
                                        </p>
                                    </div>
                                    <div className='hospital-view-sec'>
                                        <p>
                                            {memberStats && memberStats.likes
                                                ? memberStats.likes
                                                : 0}{' '}
                                            Likes
                                        </p>
                                    </div>
                                    <div
                                        className='hospital-view-sec'
                                        onClick={() => handleTab('reviews')}
                                    >
                                        <p>
                                            {memberStats && memberStats.reviews
                                                ? memberStats.reviews
                                                : 0}{' '}
                                            Reviews
                                        </p>
                                    </div>
                                </div>
                                <div className='hospital-view-social'>
                                    {member.shareSocial && (
                                        <>
                                            <i
                                                className={
                                                    member.social &&
                                                    member.shareSocial &&
                                                    member.social.instagram
                                                        ? `hospital-social-insta insta link fe fe-instagram`
                                                        : 'hospital-social-insta disabledIcon fe fe-instagram'
                                                }
                                                onClick={() => {
                                                    member.social &&
                                                        member.shareSocial &&
                                                        member.social.instagram &&
                                                        window.open(
                                                            `https://${
                                                                member.social &&
                                                                member.social.instagram &&
                                                                member.social.instagram
                                                            }`
                                                        );
                                                }}
                                            />

                                            <div
                                                onClick={() => {
                                                    member.social &&
                                                        member.shareSocial &&
                                                        member.social.twitter &&
                                                        window.open(
                                                            `https://${
                                                                member.social &&
                                                                member.social.twitter &&
                                                                member.social.twitter
                                                            }`
                                                        );
                                                }}
                                            >
                                                <FaXTwitter
                                                    className={
                                                        member.social &&
                                                        member.shareSocial &&
                                                        member.social.twitter
                                                            ? 'hospital-social-twit twit link fe fe-twitter'
                                                            : 'hospital-social-twit disabledIcon fe fe-twitter'
                                                    }
                                                    style={{ height: 14, width: 14 }}
                                                />
                                            </div>
                                            {
                                                <i
                                                    className={
                                                        member.social &&
                                                        member.shareSocial &&
                                                        member.social.linkedin
                                                            ? 'hospital-social-linked linkedin link fe fe-linkedin'
                                                            : 'hospital-social-linked disabledIcon fe fe-linkedin'
                                                    }
                                                    onClick={() => {
                                                        member.social &&
                                                            member.shareSocial &&
                                                            member.social.linkedin &&
                                                            window.open(
                                                                `https://${
                                                                    member.social &&
                                                                    member.social.linkedin &&
                                                                    member.social.linkedin
                                                                }`
                                                            );
                                                    }}
                                                />
                                            }

                                            <i
                                                className={
                                                    member.social &&
                                                    member.shareSocial &&
                                                    member.social.facebook
                                                        ? 'hospital-social-fb fb link fe fe-facebook'
                                                        : 'hospital-social-fb disabledIcon fe fe-facebook'
                                                }
                                                onClick={() => {
                                                    member.social &&
                                                        member.shareSocial &&
                                                        member.social.facebook &&
                                                        window.open(
                                                            `https://${
                                                                member.social &&
                                                                member.social.facebook &&
                                                                member.social.facebook
                                                            }`
                                                        );
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hospital-upper'>
                        {marketingInfo && marketingInfo.displayTopBar ? (
                            <div className='member-view-cover-split'>
                                <div className='hospital-view-img-split'>
                                    {member.coverImage ? (
                                        <img
                                            src={
                                                member.coverImage
                                                    ? member.coverImage
                                                    : placeholderCover
                                            }
                                        />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <div
                                    className='hospital-view-img-split2'
                                    style={
                                        (marketingInfo.topBarContentType != 'image' ||
                                            marketingInfo.topBarContentType != 'Image') &&
                                        marketingInfo.topBarContent.background && {
                                            backgroundColor: marketingInfo.topBarContent.background
                                        }
                                    }
                                >
                                    {marketingInfo.topBarContentType == 'image' ||
                                    marketingInfo.topBarContentType == 'Image' ? (
                                        <img
                                            src={
                                                marketingInfo.topBarContent &&
                                                marketingInfo.topBarContent.image
                                                    ? marketingInfo.topBarContent.image
                                                    : placeholderCover
                                            }
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                ...topBarStyle,
                                                padding: 10,
                                                width: '100%',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {renderLines(marketingInfo.topBarContent)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className='hospital-view-img'>
                                <img
                                    src={member.coverImage ? member.coverImage : placeholderCover}
                                />
                            </div>
                        )}

                        <div className='hospital-profile-container'>
                            <div className='hospital-upper-left'>
                                <div className='hospital-image-container'>
                                    <div className='hospital-veiw-image'>
                                        <img
                                            src={
                                                member.profileImage
                                                    ? member.profileImage
                                                    : profilePlaceholderImg
                                            }
                                            alt='proPic'
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {console.log('member', member)}

                    <LowerSection
                        {...props}
                        openMessageModal={() => setMessageModal(true)}
                        openReviewModal={() => setModalOpen(true)}
                        member={member}
                        memberType={memberType}
                        index={selectedAddressIndex}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddress={selectedAddress}
                        selectedAddressIndex={selectedAddressIndex}
                        setSelectedAddressIndex={setSelectedAddressIndex}
                        viewAvailabilities={props.availabilities}
                        id={id}
                        tab={tab}
                        setTab={setTab}
                        history={history}
                        getMember={getMember}
                        getMemberStatCounts={getMemberStatCounts}
                    />

                    {isAuthenticated && (
                        <MessageModal
                            messageModal={messageModal}
                            setMessageModal={setMessageModal}
                            message={message}
                            setMessage={setMessage}
                            sendMessage={sendMessage}
                        />
                    )}
                    {isAuthenticated && userType === 'PATIENT' && (
                        <ReviewModal
                            modalOpen={modalOpen}
                            handleCancel={handleCancel}
                            rating={rating}
                            setRating={setRating}
                            shortDescription={shortDescription}
                            setShortDescription={setShortDescription}
                            description={description}
                            setDescription={setDescription}
                            addReview={addReview}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state?.auth,
    profile: state?.auth?.userProfile,
    therapist: state?.therapist,
    marketing: state?.marketing,
    marketingInfo: state?.marketing?.marketingInfo,
    availability: state?.availability,
    availabilities: state?.availability?.viewAvailabilities
});
const mapDispatchToProps = (dispatch) => ({
    reviewActions: bindActionCreators(reviewActions, dispatch),
    messagingActions: bindActionCreators(messagingActions, dispatch),
    marketingActions: bindActionCreators(marketingActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(DetailView));
