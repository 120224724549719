import React from 'react';
import {
    UserOutlined,
    FileOutlined,
    PieChartOutlined,
    BookOutlined,
    CalendarOutlined,
    HeartOutlined,
    BellOutlined,
    MessageOutlined,
    StarOutlined,
    DollarOutlined,
    CreditCardOutlined,
    IdcardOutlined,
    SafetyOutlined,
    DashboardOutlined,
    SettingOutlined,
    ShoppingCartOutlined
} from '@ant-design/icons';
import VideoContainer from '@components/NewProfile/Shared/Meeting/VideoContainer';
import Dashboard from '@components/NewProfile/DoctorProfile/Dashboard';
import Notifications from '@components/NewProfile/Shared/Notifications/Notifications';
import Messaging from '@components/NewProfile/Shared/Messaging/Messaging';
import Verifications from '@components/NewProfile/DoctorProfile/Verifications';
import Prospects from '@components/NewProfile/Shared/Prospects';
import Payments from '@components/NewProfile/Shared/Payments';
import StripeDashboard from '@components/NewProfile/DoctorProfile/Dashboard/Stripe';
import Documents from '@components/NewProfile/Shared/Documents';
import MeetingEnded from '@components/NewProfile/Shared/Meeting/MeetingEnded';
import Appointments from '@components/NewProfile/DoctorProfile/Appointments';
import Profile from '@components/NewProfile/DoctorProfile/Profile';
import Credentials from '@components/NewProfile/Shared/Credentials';
import Blogs from '@components/NewProfile/Shared/Blogs';
import CreateBlog from '@components/NewProfile/Shared/Blogs/Create';
import SingleBlog from '@components/Blogs/SingleView';
import EditBlog from '@components/NewProfile/Shared/Blogs/Edit';
import Marketing from '@components/NewProfile/Shared/Marketing';
import Favourites from '@components/NewProfile/Shared/Favourites';
import Reviews from '@components/NewProfile/Shared/Reviews';
import Statistics from '@components/NewProfile/DoctorProfile/DataVisualization';
import Management from '@components/NewProfile/HospitalProfile/Management';
import Success from '../../components/NewProfile/Shared/Appointments/Payment/Success';
import Failure from '../../components/NewProfile/Shared/Appointments/Payment/Failure';
import Subscription from '../../components/NewProfile/Shared/Subscription';

export default [
    {
        name: 'Dashboard',
        path: '/doctor/dashboard',
        component: Dashboard,
        icon: <DashboardOutlined />
    },
    {
        name: 'Account',
        path: '/doctor/profile',
        component: Profile,
        icon: <UserOutlined />,
        exact: true
    },
    {
        name: 'Credentials',
        path: '/doctor/credentials',
        component: Credentials,
        icon: <IdcardOutlined />,
        exact: true
    },
    {
        name: 'Verifications',
        path: '/doctor/verifications',
        component: Verifications,
        icon: <SafetyOutlined />,
        exact: true
    },
    {
        name: 'Documents',
        path: '/doctor/documents',
        component: Documents,
        icon: <FileOutlined />,
        exact: true
    },
    {
        name: 'Appointments',
        path: '/doctor/appointments',
        component: Appointments,
        icon: <CalendarOutlined />,
        exact: true,
        children: [
            {
                path: '/doctor/appointments/:appointmentId/success',
                component: Success,
                exact: true
            },
            {
                path: '/doctor/appointments/:appointmentId/failure',
                component: Failure,
                exact: true
            },
            {
                path: '/doctor/appointments/meeting/:appointmentId/:sessionId/:token',
                component: VideoContainer,
                exact: true
            },
            {
                path: '/doctor/appointments/meeting/:appointmentId/meeting-ended',
                component: MeetingEnded,
                exact: true
            }
        ]
    },
    {
        name: 'Subscriptions',
        path: '/doctor/subscriptions',
        component: Subscription,
        icon: <ShoppingCartOutlined />,
        exact: true
    },
    {
        name: 'Management',
        path: '/doctor/management',
        component: Management,
        icon: <SettingOutlined />,
        exact: true
    },
    {
        name: 'Prospects',
        path: '/doctor/prospects',
        component: Prospects,
        icon: <HeartOutlined />,
        exact: true
    },
    {
        name: 'Favourites',
        path: '/doctor/favourites',
        component: Favourites,
        icon: <StarOutlined />,
        exact: true
    },
    {
        name: 'Notifications',
        path: '/doctor/notifications',
        component: Notifications,
        icon: <BellOutlined />,
        exact: true
    },
    {
        name: 'Reviews',
        path: '/doctor/reviews',
        component: Reviews,
        icon: <StarOutlined />,
        exact: true
    },
    {
        name: 'Messaging',
        path: '/doctor/chats',
        component: Messaging,
        icon: <MessageOutlined />,
        exact: true
    },
    {
        name: 'Blogs',
        path: '/doctor/blogs',
        component: Blogs,
        icon: <BookOutlined />,
        exact: true,
        children: [
            {
                path: '/doctor/blogs/createblog',
                component: CreateBlog,
                exact: true
            },
            {
                path: '/doctor/blogs/edit/:id',
                component: EditBlog,
                exact: true
            },
            {
                path: '/doctor/blogs/:postId',
                component: SingleBlog,
                exact: false
            }
        ]
    },
    {
        name: 'Marketing',
        path: '/doctor/marketing',
        component: Marketing,
        icon: <PieChartOutlined />,
        exact: true
    },
    // {
    //     name: 'Statistics',
    //     path: '/doctor/statistics',
    //     component: Statistics,
    //     icon: <LineChartOutlined />,
    //     exact: true
    // },
    {
        name: 'Payments',
        path: '/doctor/payments',
        component: Payments,
        icon: <DollarOutlined />,
        exact: true
    },
    {
        name: 'Stripe',
        path: '/doctor/stripe-dashboard',
        component: StripeDashboard,
        icon: <CreditCardOutlined />,
        exact: true
    }
];
