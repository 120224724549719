import React from 'react';
import Card from './Card';
import { Button } from 'antd';
import DoctorCard from '@/components/Cards/Doctor';
import ConsultantCard from '@/components/Cards/Consultant';
import CaregiverCard from '@/components/Cards/Caregiver';
import NurseCard from '@/components/Cards/Nurse';
import HospitalCard from '@/components/Cards/Hospital';
import TherapistCard from '@/components/Cards/Therapist';

const getMemberCard = (item, type) => {
    switch (type) {
        case 'DOCTOR':
            return <DoctorCard key={item.id} doctor={item} />;
        case 'HOSPITAL':
            return <HospitalCard key={item.id} hospital={item} />;
        case 'CONSULTANT':
            return <ConsultantCard key={item.id} consultant={item} />;
        case 'CAREGIVER':
            return <CaregiverCard key={item.id} caregiver={item} />;
        case 'THERAPIST':
            return <TherapistCard key={item.id} therapist={item} />;
        case 'NURSE':
            return <NurseCard key={item.id} nurse={item} />;
        default:
            return null;
    }
};

const CardRow = ({ searchPageLink, data, rowRef, type, ...props }) => {
    const getFormattedMemberType = (type) => {
        return `${type.slice(0, 1).toUpperCase()}${type.slice(1).toLowerCase()}`;
    };
    const formattedType = getFormattedMemberType(type);
    return (
        <div
            className='cardRow '
            ref={rowRef}
            style={{
                overflow: 'hidden',
                flexWrap: 'nowrap',
                scrollBehavior: 'smooth',
                padding: '10px'
            }}
        >
            {data && data.length > 0 ? (
                data.map((item, index) => getMemberCard(item, item.userType))
            ) : (
                <div
                    className=''
                    style={{
                        margin: '0px auto',
                        alignItems: 'center',
                        height: 300,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <p>You do not have any past appointments with any {formattedType}s.</p>
                    <p>Search for a {formattedType} instead.</p>
                    <Button
                        type='primary'
                        style={{ marginTop: 10 }}
                        onClick={() => props.history.push(searchPageLink)}
                    >
                        Search Now!
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CardRow;
