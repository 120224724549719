import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as messagingActions from '@/actions/messagingActions';
import '../card.css';
import { Button, Tag, Tooltip } from 'antd';
import * as prospectActions from '@/actions/prospectActions';
import malePlaceholder from '@/assets/img/malePlaceholder.png';
import femalePlaceholder from '@/assets/img/femalePlaceholder.jpg';
import {
    CalendarOutlined,
    MailOutlined,
    PhoneOutlined,
    QrcodeOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { FaXTwitter } from 'react-icons/fa6';
import { FaLinkedinIn, FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import MessageModal from '../../common/MessageModal';
import { setRegister } from '../../Auth/AuthHelper';
import ButtonGroup from 'antd/es/button/button-group';
import CredentialModal from '../Common/CredentialModal';
import { IoChatbubbleOutline } from 'react-icons/io5';
import QrCodeModal from '../Common/QrCodeModal';
import { getPhoneNumber } from '../Common/Functions';
import { message as toast } from 'antd';

const NurseCard = (props) => {
    const { nurse } = props;
    const [qrModal, setQrModal] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const auth = useSelector((state) => state.auth);
    const [alreadyAddedToProspect, setAlreadyAddedToProspect] = useState(false);
    const [credentialModal, setCredentialModal] = useState(false);
    let genderPlaceholderImg = malePlaceholder;

    if (nurse && nurse.gender && nurse.gender == 'Female') {
        genderPlaceholderImg = femalePlaceholder;
    }
    const { formatted: formattedPhoneNo, phone } = getPhoneNumber(nurse);

    useEffect(() => {
        if (auth?.isAuthenticated && auth?.userType == 'PATIENT') {
            checkIfProspectAdded();
        }
    }, []);
    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };
    const makeCall = async () => {
        window.open(`tel:${phone}`);
    };
    const goToSite = async () => {
        if (nurse && nurse.website) {
            window.open(`https://${nurse && nurse.website && nurse.website}`);
        }
    };
    const visitSocials = (social) => {
        addToProspects();
        nurse.social?.[social] && window.open(`https://${nurse.social[social]}`);
    };

    const sendMessage = async () => {
        if(!auth.isAuthenticated){
            dispatch(setRegister(true));
            return;
        }
        let obj = {
            message: message,
            receiver: nurse._id,
            receiverType: 'NURSE'
        };
        let sendMessage = await dispatch(
            messagingActions.createConversation({ ...props, obj }, history)
        );
        if (sendMessage) {
            setMessageModal(false);
            setMessage('');
        } else {
            console.log('error');
        }
    };

    const links = {
        share: <ShareAltOutlined style={{ fontSize: 15 }} />,
        linkedin: <FaLinkedinIn style={{ fontSize: 16 }} />,
        facebook: <FaFacebookSquare style={{ fontSize: 16 }} />,
        twitter: <FaXTwitter style={{ fontSize: 15 }} />,
        instagram: <FaInstagram style={{ fontSize: 16 }} />
    };

    const requestAppointment = () => {
        addToProspects();
        if (!auth.isAuthenticated) {
            dispatch(setRegister(true));
            return;
        }
        if (auth.userType !== 'PATIENT') {
            toast.error('Only patients can book appointments');
            return;
        }
        if (auth.isAuthenticated && auth.userType == 'PATIENT') {
            let url = `/patient/appointments/new?step=2&member=${nurse._id}&memberType=NURSE`;
            if (nurse?.branch) {
                const branchId = nurse.branch._id || '';
                url += `&branch=${branchId}`;
            }
            history.push(url);
        }
    };

    const checkIfProspectAdded = async () => {
        if (auth?.userType == 'PATIENT') {
            let obj = {
                user: nurse && nurse._id,
                userType: 'NURSE',
                prospect: auth.userId,
                prospectType: auth.userType,
                prospectTypeName: 'PATIENT'
            };
            let addToProspect = await dispatch(
                prospectActions.checkIfProspectAdded({ ...props, obj }, history)
            );

            if (addToProspect) {
                setAlreadyAddedToProspect(true);
            }
        }
    };
    const addToProspects = async () => {
        let obj = {
            user: nurse?._id,
            userType: 'NURSE',
            prospect: auth?.userId,
            prospectType: auth?.userType,
            prospectTypeName: 'PATIENT',
            nurse: nurse?._id
        };
        if (!alreadyAddedToProspect && auth?.userType == 'PATIENT') {
            await dispatch(prospectActions.addToProspects({ ...props, obj }, history));
        }
    };

    const handleShare = async () => {
        let urlString = window.location.origin + `/nurse-view/${nurse._id}`;

        if (navigator.share) {
            try {
                await navigator.share({
                    title: nurse.firstName + ' ' + nurse.lastName,
                    text: 'Check out this content!',
                    url: urlString
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content', error);
            }
        } else {
            console.log('Web Share API is not supported in your browser.');
        }
    };

    const experience =
        nurse?.experiences?.filter((exp) => exp.current === true)?.[0] || nurse?.experiences?.[0];

    return (
        <div className={'user-card'} style={{ '--card-color': 'var(--nurse)' }}>
            <div className='main'>
                <div className='socials'>
                    {nurse.shareSocial &&
                        Object.entries(links).map(([socialMedia, icon]) => (
                            <Button
                                key={socialMedia} // Add a unique key for each button
                                type='text'
                                style={{
                                    padding: '2px 5px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                disabled={
                                    socialMedia === 'share'
                                        ? false
                                        : !nurse.social || !nurse.social[socialMedia]
                                }
                                onClick={() =>
                                    socialMedia === 'share'
                                        ? handleShare()
                                        : visitSocials(socialMedia)
                                }
                            >
                                {icon}
                            </Button>
                        ))}
                </div>
                <div className='avatar-container'>
                    <div className='avatar'>
                        <img src={nurse?.profileImage || genderPlaceholderImg} />
                    </div>
                    <div className='qr' onClick={() => setQrModal(true)}>
                        <QrcodeOutlined />
                    </div>
                </div>

                <div className='info'>
                    <div
                        onClick={() => {
                            addToProspects();
                            let urlString = `/nurse-view/${nurse._id}`;
                            if (nurse?.branch?._id) {
                                urlString += `?branchId=${nurse.branch._id}`;
                            }
                            history.push(urlString);
                        }}
                        className='name'
                        style={{ color: 'var(--nurse)' }}
                    >
                        {nurse.firstName && nurse.firstName} {nurse.lastName && nurse.lastName}
                    </div>
                    {(nurse.nurseType || nurse.bnmcRegistrationType) && (
                        <div className='speciality' style={{ textAlign: 'center' }}>
                            {nurse.nurseType} <br /> <small>{nurse.bnmcRegistrationType}</small>
                        </div>
                    )}
                    <div className='extra' onClick={() => setCredentialModal(true)}>
                        <Tooltip title='View Credentials'>
                            {nurse.educations?.slice(0, 2).map((education, index) => (
                                <>
                                    {education.degree}
                                    {index !== nurse?.educations?.length - 1 && ', '}
                                </>
                            ))}{' '}
                            {experience && (
                                <>
                                    <br /> {experience.position + ', ' + experience.organization}
                                </>
                            )}
                            {!nurse.experiences?.length &&
                                !nurse.educations?.length &&
                                'View credentials'}
                        </Tooltip>
                    </div>
                </div>

                <div className='hospital'>{nurse?.hospitalName || 'Independant'}</div>
            </div>

            {/* <div className='practice-areas'>
                {practiceAreas.map((area) => (
                    <Tag
                        className='area'
                        key={area}
                        style={{ backgroundColor: 'transparent', cursor: 'pointer' }}
                    >
                        {area}
                    </Tag>
                ))}
            </div> */}

            <div className='about'>
                <div className='title'>About</div>
                <div className='desc'>{nurse?.profileSummary}</div>
            </div>

            <div className='actions'>
                <ButtonGroup>
                    <Tooltip
                        title={`Send a email to ${nurse.firstName && nurse.firstName} ${
                            nurse.lastName && nurse.lastName
                        } at ${nurse.email && nurse.email}`}
                    >
                        <Button
                            onClick={() =>
                                sendMail(nurse.email && nurse.email, 'Contact Nurse', 'Hi there!')
                            }
                            disabled={!nurse && nurse.email}
                        >
                            <MailOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip title={formattedPhoneNo}>
                        <Button onClick={() => makeCall()} disabled={!phone}>
                            <PhoneOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={`${
                            nurse.allowChat
                                ? `Click to message ${nurse.firstName && nurse.firstName} ${
                                      nurse.lastName && nurse.lastName
                                  }`
                                : 'Messaging unavailable'
                        }`}
                    >
                        <Button
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            disabled={!(nurse && nurse.allowChat)}
                            onClick={() => {
                                if(!auth.isAuthenticated){
                                    
                                    dispatch(setRegister(true));
                                    return;
                                }
                                if (setMessageModal && nurse.allowChat) {
                                    addToProspects();
                                    setMessageModal(true);
                                }
                            }}
                        >
                            <IoChatbubbleOutline />
                        </Button>
                    </Tooltip>
                </ButtonGroup>

                <Tooltip
                    title={`Request an appointment with ${nurse.firstName && nurse.firstName} ${
                        nurse.lastName && nurse.lastName
                    }`}
                >
                    <Button onClick={requestAppointment}>
                        Appointment <CalendarOutlined />
                    </Button>
                </Tooltip>
            </div>

            <MessageModal
                open={messageModal}
                setOpen={setMessageModal}
                message={message}
                setMessage={setMessage}
                sendMessage={sendMessage}
            />

            <CredentialModal
                open={credentialModal}
                onCancel={() => setCredentialModal(false)}
                user={nurse}
                userType={'NURSE'}
            />
            <QrCodeModal
                open={qrModal}
                onCancel={() => setQrModal(false)}
                user={nurse}
                userType={'NURSE'}
            />
        </div>
    );
};

export default NurseCard;
