const UserTypes = [
    {
        value: 'PATIENT',
        label: 'Patient',
        disabled: false
    },
    {
        value: 'HOSPITAL',
        label: 'Organization',
        disabled: false
    },
    {
        value: 'DOCTOR',
        label: 'Doctor',
        disabled: false
    },
    {
        value: 'NURSE',
        label: 'Nurse',
        disabled: false
    },
    {
        value: 'CONSULTANT',
        label: 'Consultant',
        disabled: false
    },
    {
        value: 'PHARMACY',
        label: 'Pharmacy',
        disabled: false
    },
    {
        value: 'CAREGIVER',
        label: 'Caregiver',
        disabled: false
    },
    {
        value: 'THERAPIST',
        label: 'Therapist',
        disabled: false
    },
    {
        value: 'AMBULANCE',
        label: 'Ambulance',
        disabled: true
    },
    {
        value: 'LABORATORY',
        label: 'Laboratory',
        disabled: true
    }
];

export default UserTypes;
