import React from 'react';
import './pBlogCard.css';
import { IoPricetagOutline, IoHeart, IoShare } from 'react-icons/io5';
import moment from 'moment';
import malePlaceholder from '../../assets/img/malePlaceholder.png';
import femalePlaceholder from '../../assets/img/femalePlaceholder.jpg';
import he from 'he';
import { useHistory } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';

const PBlogCard = ({ blog, type, body }) => {
    let genderPlaceholderImg = malePlaceholder;
    const history = useHistory();
    if (blog?.author?.gender == 'Female') {
        genderPlaceholderImg = femalePlaceholder;
    }
    return (
        <>
            <div className={type === 'normal' ? 'header-blogs-upper-normal' : 'header-blogs-upper'}>
                <div
                    className={
                        type === 'normal'
                            ? 'header-blogs-cat-normal pointer'
                            : 'header-blogs-cat pointer'
                    }
                >
                    <IoPricetagOutline />
                    {blog &&
                        blog.category &&
                        blog.category?.slice(0, 2).map((item, index) => (
                            <Tooltip title={item}>
                                <Tag
                                    className='tag-ellipsis'
                                    onClick={() => {
                                        console.log('item', item);
                                        history.push({
                                            pathname: `/blogs/search`,
                                            search: `?category=${item}`
                                        });
                                    }}
                                    key={index}
                                >
                                    {item}
                                    {index == blog.category.length && ','}
                                </Tag>
                            </Tooltip>
                        ))}
                </div>
                <p
                    className={type === 'normal' ? 'header-blog-title-normal' : 'header-blog-title'}
                    onClick={() => history.push('/blogs/' + blog._id)}
                >
                    {blog ? blog.title : 'Blog title'}
                </p>

                <div className={type === 'normal' ? 'header-blog-body-normal' : 'header-blog-body'}>
                    <p>
                        {blog && blog.body && he.decode(blog && blog.body.replace(/<[^>]+>/g, ''))}
                    </p>
                </div>

                <div
                    className={type === 'normal' ? 'header-blog-lower-normal' : 'header-blog-lower'}
                >
                    <div className='header-blog-img-grid'>
                        <div className='header-blog-img'>
                            <img
                                src={
                                    blog && blog.author && blog.author.profileImage
                                        ? blog.author.profileImage
                                        : genderPlaceholderImg
                                }
                                onError={(e) => (e.target.src = { genderPlaceholderImg })}
                            />
                        </div>
                        <div
                            className={
                                type === 'normal' ? 'header-blog-date-normal' : 'header-blog-date'
                            }
                        >
                            <>
                                {' '}
                                {blog &&
                                blog.author &&
                                blog.author.userType == 'HOSPITAL' &&
                                blog.author.hospitalName ? (
                                    <p
                                        onClick={() =>
                                            history.push({
                                                pathname: `/blogs/search`,
                                                search: `?author=${
                                                    blog.author &&
                                                    blog.author._id &&
                                                    blog.author._id
                                                }`
                                            })
                                        }
                                    >
                                        {' '}
                                        {blog.author.hospitalName}{' '}
                                    </p>
                                ) : (
                                    <>
                                        <p
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/blogs/search`,
                                                    search: `?author=${
                                                        blog.author &&
                                                        blog.author._id &&
                                                        blog.author._id
                                                    }`
                                                })
                                            }
                                        >
                                            {blog &&
                                                blog.author &&
                                                blog.author.firstName &&
                                                blog.author.firstName}{' '}
                                            {blog &&
                                                blog.author &&
                                                blog.author.lastName &&
                                                blog.author.lastName}
                                        </p>
                                        <small
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/blogs/search`,
                                                    search: `?author=${
                                                        blog.identity && blog.identity
                                                    }`
                                                })
                                            }
                                            style={{ display: 'block' }}
                                        >
                                            {blog &&
                                                blog.author &&
                                                blog.author.hospitalName &&
                                                blog.author.hospitalName}
                                        </small>
                                    </>
                                )}
                            </>
                            <p
                                onClick={() =>
                                    history.push({
                                        pathname: `/blogs/search`,
                                        search: `?date=${blog.createdDate}`
                                    })
                                }
                            >
                                {blog && moment(blog.createdDate).format('MMMM D YYYY').split(',')}
                            </p>
                        </div>
                    </div>
                    <div className='header-blogs-option-grid pointer'>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '10px',
                                color: '#778da9'
                            }}
                        >
                            <IoHeart /> {blog && blog.likesCount ? blog.likesCount : 0}
                            <p style={{ marginLeft: '5px' }}>likes</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '10px',
                                color: '#778da9'
                            }}
                        >
                            <IoShare />
                            {blog && blog.shares && blog.shares}
                            <p style={{ marginLeft: '5px' }}>share</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PBlogCard;
