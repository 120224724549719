import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;
if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getCaregivers = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (props && props.obj) {
            Axios.post(`${REACT_APP_API_URI}/api/caregivers/getCaregivers`, props.obj)
                .then((res) => {
                    if (res) {
                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_CAREGIVERS,
                                payload: {
                                    caregivers: res.data.caregivers
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_CAREGIVERS,
                                payload: {
                                    caregivers: res.data.caregivers
                                }
                            });
                        }

                        return resolve(true);
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        console.log(error.response.data);
                        // message.error("Caregivers Data fetching failed");

                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_CAREGIVERS,
                                payload: {
                                    caregivers: []
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_CAREGIVERS,
                                payload: {
                                    caregivers: []
                                }
                            });
                        }

                        // message.error(error.response.data.message[0].messages[0].message);
                        return resolve(false);
                    }
                });
        }
    });
};
export const getCaregiverById = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/caregivers/getCaregiverDetail/${props.caregiverId}`)
            .then((res) => {
                if (res) {
                    console.log('in here', history);

                    dispatch({
                        type: Types.GET_SINGLE_CAREGIVER,
                        payload: {
                            caregiverSingle: res.data.caregiverSingle
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Caregiver Data fetching failed');
                    history.push('/notFound');
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};


export const getCaregiverSearchResults = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/caregivers/getCaregiverSearchResults`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_CAREGIVER_SEARCH_RESULTS,
                        payload: {
                            caregivers: res.data.caregivers,
                            caregiversCount: res.data.caregiversCount
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Caregivers retrieval failed');
                    dispatch({
                        type: Types.GET_CAREGIVER_SEARCH_RESULTS,
                        payload: {
                            caregivers: [],
                            caregiversCount: 0
                        }
                    });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getCaregiverStatCounts = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/caregivers/getCaregiverStatCounts`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_CAREGIVER_STAT_COUNTS,
                        payload: {
                            counts: res.data.counts
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};




export const getCaregiverForAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/caregivers/getCaregiverForAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_CAREGIVER_FOR_APPOINTMENT,
                        payload: {
                            caregiver: res.data.caregiver
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getCaregiverDashboardInfo = (props, history) => async (dispatch) => {
    try {
        const response = await Axios.get(`${REACT_APP_API_URI}/api/caregivers/getCaregiverDashboard`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        });
        dispatch({
            type: Types.GET_CAREGIVER_DASHBOARD,
            payload: {
                dashboard: response.data.data
            }
        });
        return true;
    } catch (error) {
        if (error && error.response) {
            console.log(error.response.data);
            message.error(
                error.response.data.message ? error.response.data.message : 'Server error'
            );
            // message.error(error.response.data.message[0].messages[0].message);
            return false;
        }
    }
};

export const getLineChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/caregivers/getLineChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: res.data.lineChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: null
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getPieChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/caregivers/getPieChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: res.data.pieChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: []
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

