import React, { useState } from 'react';
import { Button } from 'antd';
import ConfirmModal from '../../../modals/ConfirmModal';
import { Tag, Tooltip } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import * as appointmentActions from '@/actions/appointmentActions';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import CheckFiles from '../Meeting/CheckFiles';
import AppointmentPayment from './modals/AppointmentPayment';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import _ from 'lodash';
import Table from '@/components/NewProfile/Shared/Table';
import { DateTime } from 'luxon';

const AppointmentTable = (props) => {
    const {
        appointments,
        open,
        setOpen,
        userType,
        auth,
        cancelAppointment,
        approveAppointment,
        openEditAppointment,
        setChosenUser,
        setReviewModal,
        limit,
        page,
        history,
        appointmentPaymentModal,
        setAppointmentPaymentModal,
        appointmentPaymentObj,
        setAppointmentPaymentObj
    } = props;

    const { videoToken: token } = useSelector((state) => state.appointments);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [view, setView] = useState('files');
    const [viewDocumentsModal, setViewDocumentsModal] = useState(null);
    const [selected, setSelected] = useState(0);
    function modalClose() {
        setModalOpen(false);
    }
    const downloadDocument = (document) => {
        if (document) {
            Axios({
                url: `/api/documents/files/${document.filename}`,
                method: 'GET',
                responseType: 'blob'
            }).then((res) => FileDownload(res.data, `${document.originalname}`));
        }
    };
    const handleDelete = () => {};

    const goToVideoCall = async (item) => {
        checkVideCallEligibility(item);
        checkVideCallEligibility2(item);

        let obj = {
            sessionId: item.videoSessionId,
            appointmentId: item._id
        };
        let callTokenApi = await dispatch(appointmentActions.getVideoToken({ ...props, obj }));
        if (callTokenApi) {
            history.push(`appointments/meeting/${item._id}/${item.videoSessionId}/${callTokenApi}`);
        }
    };

    const checkVideCallEligibility2 = (item) => {
        let valid = true;

        let today = DateTime.now().toFormat('dd/MM/yyyy');
        let now = DateTime.now().toFormat('HH:mm');
        let startTime = DateTime.fromFormat(item.appointmentStartTime, 'HH:mm');
        let endTime = DateTime.fromFormat(item.appointmentEndTime, 'HH:mm');

        console.log(today, now, startTime, endTime);
        if (
            DateTime.fromFormat(item.appointmentDate, 'dd/MM/yyyy').toFormat('dd/MM/yyyy') !== today
        ) {
            valid = false;
        }
        if (!now >= startTime && !now <= endTime) {
            valid = false;
        }
        console.log(now, startTime, endTime, !now >= startTime && !now <= endTime);
        if (item.consultationMode !== 'Online') {
            valid = false;
        }
        return valid;
    };

    const checkVideCallEligibility = (item) => {
        let valid = true;

        let today = moment(new Date()).format('DD/MM/YYYY');
        let now = moment(moment(new Date()).format('HH:mm'), 'HH:mm');
        let startTime = moment(item.appointmentStartTime, 'HH:mm');
        let endTime = moment(item.appointmentEndTime, 'HH:mm');

        console.log(today, now, startTime, endTime);
        if (moment(item.appointmentDate).format('DD/MM/YYYY') != today) {
            valid = false;
        }
        if (now.isBetween(startTime, endTime) == false) {
            valid = false;
        }
        console.log(now, startTime, endTime, now.isBetween(startTime, endTime));
        if (!item.consultationMode == 'Online') {
            valid = false;
        }
        return valid;
        // return true
    };

    let columns = [
        {
            title: <span className='dragHandler'>Date</span>,
            dataIndex: 'appointmentDate',
            width: 140,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;

                return (
                    <div>
                        {new Date(record.appointmentStartDateTime).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 140 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Time Slot</span>,
            width: 200,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                let timeSlotEndTime = moment
                    .tz(record.appointmentEndTime, 'HH:mm', record.appointmentTimeZone)
                    .tz(moment.tz.guess())
                    .format('HH:mm A');
                let timeSlotStartTime = moment
                    .tz(record.appointmentStartTime, 'HH:mm', record.appointmentTimeZone)
                    .tz(moment.tz.guess())
                    .format('HH:mm A');
                let timeSlot = record.appointmentStartTime && (
                    <>
                        {timeSlotStartTime} - {timeSlotEndTime}{' '}
                    </>
                );

                return <div>{timeSlot}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        },
        {
            title: <span className='dragHandler'>City</span>,
            width: 200,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                let city = '';
                if (userType != 'PATIENT') {
                    city = record?.user?.contact?.billing?.billingCity;
                }
                if (userType == 'PATIENT') {
                    if (record?.branch) {
                        city = record?.branch?.location?.branchCity;
                    } else {
                        city = record?.member?.contact?.billing?.billingCity;
                    }
                }
                return <div>{city}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Country</span>,
            width: 200,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                let country = '';
                if (userType != 'PATIENT') {
                    country = record?.user?.contact?.billing?.billingCountry;
                }
                if (userType == 'PATIENT') {
                    if (record?.branch) {
                        country = record?.branch?.location?.branchCountry;
                    } else {
                        country = record?.member?.contact?.billing?.billingCountry;
                    }
                }
                return <div>{country}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        }
    ];

    if (userType == 'PATIENT') {
        columns = [
            ...columns,

            {
                title: <span className='dragHandler'>Hospital / Agency</span>,
                width: 350,
                render: (_, record) => {
                    if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                    return <div>{record?.hospitalName || 'Individual'}</div>;
                },
                onHeaderCell: () => {
                    return {
                        style: { whiteSpace: 'pre-wrap', minWidth: 350 }
                    };
                }
            }
        ];
    }

    if (userType == 'HOSPITAL' || userType == 'PATIENT') {
        columns = [
            ...columns,
            {
                title: <span className='dragHandler'>Service Provider</span>,
                width: 300,
                render: (_, record) => {
                    if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                    let name = record.member &&
                        record.member.firstName &&
                        record.member.lastName && (
                            <>
                                {record.member.firstName} {record.member.lastName}
                            </>
                        );

                    return <div>{name}</div>;
                },
                onHeaderCell: () => {
                    return {
                        style: { whiteSpace: 'pre-wrap', minWidth: 350 }
                    };
                }
            },
            {
                title: <span className='dragHandler'>Provider Type</span>,
                width: 300,
                render: (_, record) => {
                    if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                    let name = record.member && (
                        <>
                            {record.member.userType.slice(0, 1) +
                                record.member.userType.slice(1).toLowerCase()}
                        </>
                    );

                    return <div>{name}</div>;
                },
                onHeaderCell: () => {
                    return {
                        style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                    };
                }
            }
        ];
    }

    if (userType !== 'PATIENT') {
        columns = [
            ...columns,
            {
                title: <span className='dragHandler'>Patient</span>,
                width: 250,
                render: (_, record) => {
                    if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                    let name = record.user && record.user.firstName && record.user.lastName && (
                        <>
                            {record.user.firstName} {record.user.lastName}
                        </>
                    );

                    return <div>{name}</div>;
                    // Rest of the code
                },
                onHeaderCell: () => {
                    return {
                        style: { whiteSpace: 'pre-wrap', minWidth: 350 }
                    };
                }
            }
        ];
    }

    columns = [
        ...columns,
        {
            title: <span className='dragHandler'>Email</span>,
            width: 200,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {auth?.userType !== 'PATIENT' ? record?.userEmail : record?.member?.email}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        },

        {
            title: <span className='dragHandler'>Phone No.</span>,
            width: 200,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                let phoneNo;
                if (userType == 'PATIENT') {
                    phoneNo =
                        record.member && record.member.phoneNo && record.member.countryCode ? (
                            <>
                                {' '}
                                ({record.member.countryCode}
                                {') '}
                                {record.member.phoneNo}{' '}
                            </>
                        ) : (
                            <>N/A</>
                        );
                }
                if (userType !== 'PATIENT') {
                    phoneNo = record.user && record.user.phoneNo && record.user.countryCode && (
                        <>
                            {' '}
                            ({record.user.countryCode}
                            {') '}
                            {record.user.phoneNo}{' '}
                        </>
                    );
                }
                return <div>{phoneNo}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Appointment Type</span>,
            width: 200,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                if (!record.consultationMode) return null;
                return <Tag> {record.consultationMode ? record.consultationMode.toUpperCase() : ''}</Tag>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Appointment Status</span>,
            width: 200,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                if (!record.appointmentStatus) return null;
                return <Tag> {record.appointmentStatus ? record.appointmentStatus : ''}</Tag>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Payment Status</span>,
            width: 200,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                if (!record.paymentStatus) return null;
                return <Tag> {record.paymentStatus}</Tag>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div style={{ justifyContent: 'center', display: 'flex', gap: 10 }}>
                        {openEditAppointment &&
                            (record.appointmentStatus === 'PENDING' ||
                                record.appointmentStatus === 'ACCEPTED') && (
                                <Tooltip title='Edit Appointment'>
                                    <Button
                                        size='small'
                                        onClick={() => openEditAppointment(record)}
                                        type='default'
                                    >
                                        Edit{' '}
                                    </Button>
                                </Tooltip>
                            )}
                        {record.files && record.files.length > 0 && (
                            <Tooltip title='View Submitted Documents'>
                                <Button
                                    size='small'
                                    type='default'
                                    onClick={() => {
                                        setSelected(index);
                                        setView('Files');
                                        setViewDocumentsModal(true);
                                    }}
                                >
                                    {' '}
                                    View Submitted Documents
                                </Button>
                            </Tooltip>
                        )}

                        {record.notes && record.notes.length > 0 && (
                            <Tooltip title='View Notes'>
                                <Button
                                    size='small'
                                    type='default'
                                    onClick={() => {
                                        setSelected(index);
                                        setView('Notes');
                                        setViewDocumentsModal(true);
                                    }}
                                >
                                    View Notes
                                </Button>
                            </Tooltip>
                        )}

                        {record.paymentStatus == 'PENDING' &&
                            auth.userType == 'PATIENT' &&
                            record.appointmentStatus != 'COMPLETED' &&
                            record.appointmentStatus != 'CANCELLED' &&
                            record.appointmentStatus != 'REJECTED' && (
                                <Tooltip title='Pay for appointment'>
                                    <Button
                                        size='small'
                                        type='default'
                                        onClick={() => {
                                            setAppointmentPaymentModal(true);
                                            setAppointmentPaymentObj(record);
                                        }}
                                    >
                                        Pay
                                    </Button>
                                </Tooltip>
                            )}
                        {record.appointmentStatus != 'COMPLETED' &&
                            record.appointmentStatus != 'CANCELLED' &&
                            record.appointmentStatus != 'NO SHOW' &&
                            record.appointmentStatus != 'REJECTED' && (
                                <Tooltip
                                    title={`${
                                        record.requestBy !== auth.userType ? 'Reject' : 'Cancel'
                                    } Appointment`}
                                >
                                    {' '}
                                    <Button
                                        size='small'
                                        type='default'
                                        onClick={() =>
                                            cancelAppointment ? cancelAppointment(record._id) : null
                                        }
                                        style={{
                                            display: 'flex',
                                            color: 'red'
                                        }}
                                    >
                                        {record.requestBy !== auth.userType ? 'Reject' : 'Cancel'}
                                    </Button>
                                </Tooltip>
                            )}

                        {record.appointmentStatus == 'PENDING' &&
                            record.appointmentStatus != 'CANCELLED' &&
                            record.appointmentStatus != 'REJECTED' &&
                            record.requestBy != userType && (
                                <Tooltip title='Approve Appointment'>
                                    {' '}
                                    <Button
                                        size='small'
                                        type='default'
                                        onClick={() =>
                                            approveAppointment
                                                ? approveAppointment(record._id)
                                                : null
                                        }
                                    >
                                        Approve
                                    </Button>
                                </Tooltip>
                            )}
                        {record.appointmentStatus == 'COMPLETED' && auth.userType == 'PATIENT' && (
                            <Tooltip title='Leave a Review'>
                                <Button
                                    size='small'
                                    onClick={() => {
                                        setChosenUser(record.member && record.member);
                                        setReviewModal(true);
                                    }}
                                >
                                    {' '}
                                    Review{' '}
                                </Button>
                            </Tooltip>
                        )}
                        {auth.userType != 'HOSPITAL' &&
                            record.videoSessionId &&
                            record.appointmentStatus === 'ACCEPTED' &&
                            !(
                                record.paymentStatus == 'PENDING' &&
                                record.appointmentMode == 'Online'
                            ) && (
                                // checkVideCallEligibility(item) == true &&
                                <Tooltip title='Start Video Call'>
                                    <Button
                                        size='small'
                                        type='primary'
                                        onClick={() => goToVideoCall(record)}
                                    >
                                        <VideoCameraOutlined /> Start Appointment
                                    </Button>
                                </Tooltip>
                            )}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 250 }
                };
            }
        }
    ];
    const numberOfBlankRows = limit - appointments.length;
    let dataSource = [
        ...appointments,
        ...Array.from({ length: numberOfBlankRows }).map(() => ({}))
    ];
    return (
        <>
            {' '}
            <Table tableName='Appointments' columns={columns} data={dataSource} />
            {modalOpen && (
                <ConfirmModal
                    onClose={modalClose}
                    acceptMethod={handleDelete}
                    headerText='Delete Blog post'
                    bodyText='Are you sure you want to delete this post?'
                />
            )}
            <CheckFiles
                view={view}
                setView={setView}
                appointment={appointments[selected]}
                viewDocumentModal={viewDocumentsModal}
                setViewDocumentModal={setViewDocumentsModal}
            />
            {userType == 'PATIENT' && (
                <AppointmentPayment
                    appointmentPaymentModal={appointmentPaymentModal}
                    setAppointmentPaymentModal={setAppointmentPaymentModal}
                    setAppointmentPaymentObj={setAppointmentPaymentObj}
                    appointmentPaymentObj={appointmentPaymentObj}
                    {...props}
                />
            )}
        </>
    );
};

export default AppointmentTable;
