import React, { useEffect, useState } from 'react';
import '../Preview/preview.css';
import { PlusOutlined } from '@ant-design/icons';
import UpdateContent from '../Content/UpdateContent';
import { useSelector } from 'react-redux';
import { Tooltip, Switch, Button } from 'antd';
import placeholderCover from '@/assets/images/coverPlaceholder.jpeg';

const initialState = {
    text: '',
    style: {
        fontFamily: 'Inter Var',
        fontSize: '14px',
        color: '#000',
        fontWeight: 400
    }
};

const BottomBar = (props) => {
    const { handleSwitch, saveMarketingInfo } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { marketingInfo } = useSelector((state) => state.marketing);

    const { bottomBarContentType, displayBottomBar, bottomBarContent } = marketingInfo || {};

    const [bannerType, setBannerType] = useState('Text');

    useEffect(() => {
        if (bottomBarContentType) {
            setBannerType(bottomBarContentType);
        }
    }, [bottomBarContentType]);

    const [content, setContent] = useState({
        title: { ...initialState },
        header: { ...initialState },
        grabber: { ...initialState },
        line1: { ...initialState },
        line2: { ...initialState },
        line3: { ...initialState },
        line4: { ...initialState },
        image: '',
        backgroundColor: '#fff'
    });

    useEffect(() => {
        if (bottomBarContent) {
            setContent(bottomBarContent);
        }
    }, [bottomBarContent]);

    let bottomBarStyle = {
        backgroundColor: content?.backgroundColor || '#fff'
    };

    const lines = ['header', 'grabber', 'line1', 'line2', 'line3', 'line4', 'line5'];

    const renderLines = (content) => {
        return lines.map((line) => {
            if (content[line]) {
                return (
                    <p
                        style={{
                            ...(content?.[line].style || {}),
                            padding: '10px 0px',
                            textAlign: 'center'
                        }}
                    >
                        {content?.[line]?.text}
                    </p>
                );
            }
        });
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
                style={{
                    display: 'flex',
                    gap: 20,
                    marginBottom: 10,
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ display: 'flex', gap: 10 }}>
                    <Switch
                        checked={displayBottomBar}
                        onChange={(checked) => handleSwitch(checked, 'BOTTOMBAR')}
                    />
                    <div>Display Bottom Bar</div>
                </div>
                <Button type='default' onClick={() => setIsModalOpen(true)}>
                    Edit
                </Button>
            </div>

            {displayBottomBar && (
                <div className='hospital-view-box3 bar'>
                    {bannerType !== 'image' && bannerType !== 'Image' && (
                        <div className='hospital-view-box1-header'>
                            <p>{content?.title?.text || 'Bottom Bar'}</p>
                        </div>
                    )}

                    <div className='hospital-view-box1-body'>
                        <div
                            className='hospital-view-box'
                            style={
                                (bannerType != 'image' || bannerType != 'Image') &&
                                content.backgroundColor && {
                                    backgroundColor: content.backgroundColor
                                }
                            }
                        >
                            {bannerType == 'image' || bannerType == 'Image' ? (
                                <img
                                    src={
                                        content && content.image ? content.image : placeholderCover
                                    }
                                />
                            ) : (
                                <div style={{ ...bottomBarStyle, padding: '10px 0px' }}>
                                    {renderLines(content)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isModalOpen && (
                <UpdateContent
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    type={'BOTTOMBAR'}
                    contentType={bannerType}
                    toggleContentType={(e) => {
                        setBannerType(e.target.value);
                    }}
                    save={saveMarketingInfo}
                    content={content}
                />
            )}
        </div>
    );
};

export default BottomBar;
