export const specializationAreas = [
    'Allergy and Immunology',
    'Anesthesiology',
    'Cardiology',
    'Dermatology',
    'Emergency Medicine',
    'Endocrinology',
    'Family Medicine',
    'Gastroenterology',
    'Geriatrics',
    'Hematology',
    'Infectious Disease',
    'Internal Medicine',
    'Medical Genetics',
    'Nephrology',
    'Neurology',
    'Obstetrics and Gynecology',
    'Ophthalmology',
    'Orthopedic Surgery',
    'Otolaryngology (ENT)',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'Psychiatry',
    'Pulmonology',
    'Radiation Oncology',
    'Radiology',
    'Rheumatology',
    'Sports Medicine',
    'Surgery',
    'Urology',
    'Veterinary Anesthesia and Analgesia',
    'Veterinary Behavior',
    'Veterinary Dentistry',
    'Veterinary Dermatology',
    'Veterinary Emergency and Critical Care',
    'Veterinary General Practitioner',
    'Veterinary Internal Medicine',
    'Veterinary Laboratory Animal Medicine',
    'Veterinary Microbiology',
    'Veterinary Nutrition',
    'Veterinary Ophthalmology',
    'Veterinary Pathology',
    'Veterinary Pharmacology',
    'Veterinary Radiology',
    'Veterinary Rehabilitation and Physical Therapy',
    'Veterinary Surgery',
    'Veterinary Theriogenology',
    'Veterinary Toxicology',
    'Veterinary Zoological Medicine',
    'Veterinary Avian Medicine',
    'Veterinary Bovine Medicine',
    'Veterinary Equine Medicine',
    'Veterinary Exotic Animal Medicine',
    'Veterinary Food Animal Medicine',
    'Veterinary Feline Medicine',
    'Veterinary Swine Health Management',
    'Veterinary Wildlife Medicine',
    'Veterinary Shelter Medicine',
    'Veterinary Forensics',
    'Veterinary Public Health',
    'Veterinary Sports Medicine and Rehabilitation',
    'Veterinary Acupuncture and Alternative Medicine',
    'Veterinary Epidemiology'
];

export const specializationAreasOptions = specializationAreas.reduce((acc, value, index) => {
    return (acc = [...acc, { value: value, id: index + 1 }]);
}, []);

export const blogSpecializationAreas = [
    ...specializationAreas,
    'Mental Health',
    'Nutrition and Diet',
    'Industry Trends',
    'Fitness and Exercise',
    'Medical Research',
    'Sleep',
    'Clinical Research',
    'Diabetes',
    'Supplements',
    'Health and Wellness',
    'Pediatrics articles',
    'Medical Conditions',
    'Medical Treatments',
    'Healthy Living',
    "Women's Health",
    "Men's Health",
    'Child Health',
    'Senior Health',
    'Disease Prevention',
    'Alternative Medicine',
    'Patient Stories'
];

export const blogSpecializationAreasOptions = specializationAreas.reduce((acc, value, index) => {
    return (acc = [...acc, { value: value, id: index + 1 }]);
}, []);
