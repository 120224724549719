import React, { memo } from 'react';

const RegNoDocs = (props) => {
    const { css, regNo, hospitalName, pharmacist } = props;
    return (
        <div className={`Order-sections-first ${css}`}>
            <div>
                <p>
                    Registration Number For {pharmacist ? 'Pharmacist' : 'Pharmacy'}{' '}
                    {hospitalName} : {regNo}
                </p>
            </div>
        </div>
    );
};

export default memo(RegNoDocs);
