import React, { useRef, useEffect, useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import Currencies from '@/assets/json/Currencies.json';
import languageOptions from '@/assets/json/Languages.json';
import Countries from '@/assets/json/UpdatedCountries.json';
import { Select, Alert } from 'antd';
import { Tooltip } from 'antd';
import AvailabilityModal from './AvailabilityModal';
import GoogleMapReact from 'google-map-react';
import { FiEdit2, FiSave } from 'react-icons/fi';
import Geolocate from '../../../MiniComponents/Geolocate';
import Search from '../../../MiniComponents/CanadaPost/Search';
import './customLocationModal.css';
import useWindowSize from '@/hooks/useWindowSize';

const uniqueCurrencies = [...new Set(Currencies.map((currency) => currency.currency.code))].filter(
    Boolean
);

const CreateLocation = (props) => {
    const {
        item,
        index,
        onChange,
        checkFields,
        updateAddress,
        hospitalName,
        onChangeCC,
        onChangeMapCoords,
        onChangeAddressUsingMap,
        setState,
        state,
        currentRef,
        closeLocationModal,
        Marker,
        validated,
        availability
    } = props;
    const { width } = useWindowSize();
    let country = localStorage.getItem('country_short');
    let countryCondition =
        country != 'US' && country != 'CAN' && country != 'CN' && country != 'USA';

    let countryRef = useRef();
    let currencyRef = useRef();

    const daysOfWeek = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
    ];
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    useEffect(() => {
        let check = checkFields(index);
        if (check) {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    }, [
        item.languages.value,
        item.currency.value,
        item.countryCode.value,
        item.phoneNo.value,
        item.branchAddress.value,
        item.branchCity.value,
        item.branchState.value,
        item.branchZip.value,
        item.branchCountry.value,
        item.branchCoords.lng,
        item.branchCoords.lat,
        item.email.value,
        item.website.value,
        item.name.value
    ]);

    const renderSchedule = (day, schedule) => {
        const daySchedule = schedule && schedule[day];
        if (daySchedule) {
            const { startTime, endTime } = daySchedule;
            return (
                <p key={day}>
                    <div>{day.substr(0, 1).toUpperCase() + day.substr(1)} : </div>
                    <div>
                        <span>{startTime}</span> - <span>{endTime}</span>
                    </div>
                </p>
            );
        }
        return null;
    };
    const renderedSchedule = daysOfWeek.map((day) => renderSchedule(day, availability)).filter((item)=>item);

    console.log('item.availabity', item.availability);
    return (
        <Modal
            centered
            title={item.branchId ? 'Edit Location' : 'Create Location'}
            className='customModal multiPage locationModal'
            open={item.locationModal}
            maskClosable={false}
            onCancel={() => closeLocationModal(true)}
            footer={[
                <Button
                    key='submit'
                    type='primary'
                    disabled={isSaveDisabled}
                    onClick={(e) => currentRef.saveProfile(e, index)}
                >
                    Save
                </Button>,
                <>
                    {item.branchId && (
                        <Button
                            disabled={validated ? false : true}
                            style={{
                                cursor: validated ? 'pointer' : 'not-allowed'
                            }}
                            onClick={() => currentRef.setModal(index)}
                        >
                            {Object.keys(item.availability).length > 0 ? 'Update' : 'Create'}{' '}
                            Availability
                        </Button>
                    )}
                </>
            ]}
        >
            <div className={`input-fields `}>
                <label className={`${!item.name.isValid ? 'error' : ''} `}>
                    Hospital Name:
                    <input
                        name='name'
                        placeholder='Hospital Name'
                        disabled={true}
                        value={hospitalName.value}
                    />
                </label>

                <label className={`${!item.website.isValid ? 'error' : ''} `}>
                    Website:
                    <input
                        autoComplete='off'
                        name='website'
                        placeholder='Website address'
                        value={item.website.value}
                        onChange={(e) => onChange(e, index)}
                    />
                    {item.website.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.website.message}
                        </p>
                    )}
                </label>

                <label className={`${!item.email.isValid ? 'error' : ''} `}>
                    Hospital Email:
                    <input
                        autoComplete='off'
                        name='email'
                        placeholder='Email address'
                        value={item.email.value}
                        onChange={(e) => onChange(e, index)}
                    />
                    {item.email.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.email.message}
                        </p>
                    )}
                </label>
                {countryCondition ? (
                    <label>
                        Hospital Location:
                        <i className='fa fa-map-marker' />
                        <Geolocate chooseAddress={(address) => updateAddress(address, index)} />
                    </label>
                ) : (
                    <label>
                        Hospital Location:
                        <i className='fa fa-map-marker' />
                        <Search
                            handleSelectionCanadaPost={
                                (item) => {}
                                // currentRef.handleSelectionCanadaPost(item, index)
                            }
                        />
                    </label>
                )}

                <label className={`${!item.branchAddress.isValid ? 'error' : ''} one-half`}>
                    Hospital Address*:
                    <input
                        autoComplete='off'
                        name='branchAddress'
                        value={item.branchAddress.value}
                        placeholder='Hospital Address'
                        onChange={(e) => onChange(e, index)}
                    />
                    {item.branchAddress.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.branchAddress.message}
                        </p>
                    )}
                </label>
                <label className={`${!item.branchCountry.isValid ? 'error' : ''} one-half`}>
                    Hospital Country*:
                    <input
                        autoComplete='off'
                        name='branchCountry'
                        value={item.branchCountry.value}
                        placeholder='Hospital Country'
                        onChange={(e) => onChange(e, index)}
                    />
                    {item.branchCountry.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.branchCountry.message}
                        </p>
                    )}
                </label>

                <label className={`${!item.branchCity.isValid ? 'error' : ''} one-third`}>
                    City*:
                    <input
                        autoComplete='off'
                        placeholder='City'
                        name='branchCity'
                        value={item.branchCity.value}
                        onChange={(e) => onChange(e, index)}
                    />
                    {item.branchCity.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.branchCity.message}
                        </p>
                    )}
                </label>
                <label className={`${!item.branchState.isValid ? 'error' : ''} one-third`}>
                    Province/State*:
                    <input
                        autoComplete='off'
                        placeholder='Province/State'
                        name='branchState'
                        value={item.branchState.value}
                        onChange={(e) => onChange(e, index)}
                    />
                    {item.branchState.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.branchState.message}
                        </p>
                    )}
                </label>
                <label className={`${!item.branchZip.isValid ? 'error' : ''} one-third`}>
                    Postal/Zip Code*:
                    <input
                        autoComplete='off'
                        placeholder='Postal/Zip Code'
                        name='branchZip'
                        value={item.branchZip.value}
                        onChange={(e) => onChange(e, index)}
                    />
                    {item.branchZip.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.branchZip.message}
                        </p>
                    )}
                </label>

                <div className='enableEditMap' style={{ width: '45%' }}>
                    <span>
                        Choose coordinates: [{item.branchCoords.lng} , {item.branchCoords.lat}]
                    </span>
                    <div
                        style={{ marginLeft: 'auto', cursor: 'pointer' }}
                        onClick={async (e) => {
                            e.preventDefault();
                            if (item.adjustPin) {
                                onChangeAddressUsingMap(
                                    { lng: item.branchCoords.lng, lat: item.branchCoords.lat },
                                    index
                                );
                            } else {
                                state.formDirty = true;
                                state.branches[index].adjustPin = true;
                                setState(state);
                            }
                        }}
                    >
                        {!item.adjustPin ? (
                            <Tooltip title='Click to adjust map marker to set latitude and longitude'>
                                <FiEdit2 />
                            </Tooltip>
                        ) : (
                            <Tooltip title='Lock Location'>
                                <FiSave />
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className='mapDiv' style={{ marginTop: 10, width: 350 }}>
                    <GoogleMapReact
                        key={index}
                        style={{
                            display: 'flex',
                            height: 250,
                            width: '100%',
                            position: 'relative',
                            left: 0,
                            right: 0,
                            margin: '0px 0px 10px 0px',
                            maxWidth: 700
                        }}
                        bootstrapURLKeys={{
                            key: 'AIzaSyAdko-SWObgLgKd-2GGQutJkNxVTps5FsU'
                        }}
                        name='map'
                        center={item.center}
                        defaultZoom={item.zoom}
                        onDrag={(e) => {
                            if (item.adjustPin) {
                                onChangeMapCoords(e, index);
                            }
                        }}
                        onClick={(e) => {
                            // onChangeMapCoords(e, index)
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                            currentRef.handleApiLoaded(map, maps, item.markerCenter)
                        }
                    >
                        <Marker
                            lat={
                                item.markerCenter && item.markerCenter.lat
                                    ? item.markerCenter.lat
                                    : parseFloat(localStorage.getItem('lat'))
                            }
                            lng={
                                item.markerCenter && item.markerCenter.lng
                                    ? item.markerCenter.lng
                                    : parseFloat(localStorage.getItem('lng'))
                            }
                            text='My Marker'
                        />
                    </GoogleMapReact>
                </div>

                <label className={`one-half ${item.branchCoords.lng == '' && 'error'}`}>
                    Longitude:
                    <input
                        autoComplete='off'
                        name='longitude'
                        value={item.branchCoords.lng}
                        placeholder='Longitude'
                        onChange={(e) => onChange(e, index)}
                    />
                </label>
                <label className={`one-half ${item.branchCoords.lat == '' && 'error'}`}>
                    Latitude:
                    <input
                        autoComplete='off'
                        name='latitude'
                        value={item.branchCoords.lat}
                        placeholder='Latitude'
                        onChange={(e) => onChange(e, index)}
                    />
                    {item.branchCoords.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.branchCoords.message}
                        </p>
                    )}
                </label>
                <div className='two-part-inputs' style={{ display: 'flex', columnGap: 10 }}>
                    <label
                        ref={countryRef}
                        id='countryCode'
                        className={`${!item.countryCode.isValid ? 'error' : ''} cc-label ${
                            item.countryDrop && 'focused'
                        }`}
                        tabIndex={0}
                        style={{ position: 'relative', flex: 1 }}
                    >
                        Country Code*:
                        <Select
                            value={item.countryCode.value}
                            showSearch
                            style={{ width: '100%' }}
                            onChange={(val) => onChangeCC(val, index, item)}
                        >
                            {Countries.map((country, index) => (
                                <Select.Option key={index} value={country.dialCode}>
                                    {country.dialCode}
                                </Select.Option>
                            ))}
                        </Select>
                        {item.countryCode.message && (
                            <p>
                                {' '}
                                <i className='fe fe-alert-triangle' /> {item.countryCode.message}
                            </p>
                        )}
                    </label>
                    <label
                        className={`${!item.phoneNo.isValid ? 'error' : ''} `}
                        style={{ flex: 1 }}
                    >
                        Phone No*:
                        <input
                            autoComplete='off'
                            name='phoneNo'
                            placeholder='Phone No.'
                            value={item.phoneNo.value}
                            onChange={(e) => onChange(e, index)}
                        />
                        {item.phoneNo.message && (
                            <p>
                                {' '}
                                <i className='fe fe-alert-triangle' /> {item.phoneNo.message}
                            </p>
                        )}
                    </label>
                </div>

                <label className={`${!item.languages.isValid ? 'error' : ''}`}>
                    Languages*:
                    <Select
                        placeholder='Choose Language'
                        value={item.languages?.value || []}
                        // search
                        mode='multiple'
                        id='language'
                        style={{ backgroundColor: 'white !important' }}
                        showSearch='true'
                        selection='true'
                        onChange={(value) => {
                            console.log(value);
                            state.branches[index].languages = {
                                ...item.languages,
                                value: value,
                                isValid: true,
                                message: ''
                            };
                            state.formDirty = true;
                            setState(state);
                        }}
                        options={languageOptions}
                    />
                    {item.languages.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.languages.message}
                        </p>
                    )}
                </label>

                <label
                    ref={currencyRef}
                    className={`cur-label ${item.currencyDrop && 'focused'} ${
                        !item.currency.isValid ? 'error' : ''
                    }`}
                    tabIndex={0}
                >
                    Currency*
                    <Select
                        showSearch
                        value={item.currency.value}
                        onChange={(val) => {
                            state.branches[index].currency = {
                                ...state.branches[index].currency,
                                value: val,
                                isValid: true,
                                message: ''
                            };
                            state.formDirty = true;
                            setState(state);
                        }}
                    >
                        {uniqueCurrencies.map((item) => {
                            return <Select.Option value={item}>{item}</Select.Option>;
                        })}
                    </Select>
                    {item.currency.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.currency.message}
                        </p>
                    )}
                </label>

                <label className='textareaLabel'>
                    Hospital Profile:
                    <textarea
                        value={item.profileSummary.value}
                        onChange={(e) => onChange(e, index)}
                        name='profileSummary'
                        placeholder=''
                        rows='4'
                    />
                    {item.profileSummary.message ? (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {item.profileSummary.message}
                        </p>
                    ) : (
                        <p> *Field is optional.</p>
                    )}
                </label>
                {(availability || item.branchId ) && (
                    <Alert
                        style={{ width: width > 450 ? '30%' : '100%' }}
                        message={`Click on the ${
                            Object.keys(item.availability).length > 0 ? 'Update' : 'Create'
                        } availability button below to enter location availability`}
                        type='error'
                    />
                )}

                <Divider />

                {availability && (
                    <label>
                        Location Availability
                        <div className='locAvailBox'>
                            {availability.timezone && (
                                <p>
                                    <div>Timezone : </div>
                                    <div>{availability.timezone}</div>
                                </p>
                            )}
                            {Object.keys(availability).length > 0 ? (
                                <>{renderedSchedule}</>
                            ) : (
                                <p>No schedule available</p>
                            )}
                        </div>
                    </label>
                )}

                {item.modal ? (
                    <AvailabilityModal
                        availability={item.availability}
                        modal={item.modal}
                        branchId={item.branchId}
                        index={index}
                        branch={item}
                        setModal={currentRef.setModal}
                        {...currentRef.props}
                    />
                ) : null}
            </div>
        </Modal>
    );
};

export default CreateLocation;
