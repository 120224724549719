import React, { useState, useEffect } from 'react';
import './blogSearch.css';
import { DatePicker, Select } from 'antd';
import { IoPricetagOutline } from 'react-icons/io5';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as blogActions from '@/actions/blogActions';
import moment from 'moment';
import { getLocationShorts } from '../Utilities/LocationShorts';
import { blogSpecializationAreas } from '@/assets/json/DoctorPracticeAreas';
import { PharmacyBlogCategories } from '../../assets/json/PharmacyBlogCategories';
const { Option } = Select;

const BlogSearch = (props) => {
    const { page, setPage, limit, setLimit } = props;
    const [title, setTitle] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [cats, setCats] = useState(null);
    const [sort, setSort] = useState(null);
    const [date, setDate] = useState(null);
    const [criteria, setCriteria] = useState('');
    const [author, setAuthor] = useState(null);
    const [disableInput, setDisableInput] = useState({
        title: false,
        category: false,
        date: false,
        sortby: false
    });

    const sortby = [
        { name: 'Newest', value: 'Posted: Newest First' },
        { name: 'Oldest', value: 'Posted: Oldest First' },
        { name: 'Likes', value: 'Likes: Most Liked' },
        { name: 'Comments', value: 'Comments: Most Commented' }
    ];
    const { nameWise, titleWise, categoryWise, history } = props;
    console.log(nameWise);
    const handleSearchInput = (e, date, name) => {
        if (e && name && date) {
            if (name === 'search') {
                if (e.target.value) {
                    setSearchTerm(e.target.value);
                } else {
                    setSearchTerm('');
                }
            }
            if (name === 'cats') {
                setCats(e);
            }
            if (name === 'sort') {
                console.log(e);

                setSort(e);
            }
            if (name === 'date') {
                setDate(e);
            }
        }
    };

    const handleReset = () => {
        setSearchTerm('');
        setCriteria('');
        setTitle('');
        setDisableInput({
            ...disableInput,
            title: false,
            category: false,
            date: false,
            sortby: false
        });
        setDate(null);
        setCats(null);
        setSort(null);
        if (author) {
            props.history.push(`/blogs/search?author=${author}`);
        } else {
            props.history.push(`/blogs/search`);
        }
    };

    useEffect(() => {
        getPosts();
    }, [props.location.search, limit, page]);

    const searchBlog = () => {
        let searchString = `/blogs/search?`;

        if (cats) {
            searchString += `&category=${cats}`;
        }

        if (date) {
            searchString += `&date=${new Date(date).toISOString()}`;
        }

        if (searchTerm) {
            searchString += `&searchTerm=${searchTerm}`;
        }
        if (author) {
            searchString += `&author=${author}`;
        }
        if (sort) {
            searchString += `&sort=${sort}`;
        }

        props.history.push(searchString);
    };

    const getPosts = async () => {
        let query = new URLSearchParams(
            props.history && props.history.location && props.history.location.search
        );
        let search = query.get('searchTerm');
        let author = query.get('author');
        let date = query.get('date');
        let category = query.get('category');

        if (search) {
            setSearchTerm(search);
        }
        if (author) {
            setAuthor(author);
        }
        if (date) {
            setDate(moment(date));
        }
        if (category) {
            setCats(category);
        }

        let obj = {
            category: category,
            date,
            skip: 0,
            page: page,
            perPage: limit,
            // userId: props.auth.user._id,
            // lawfirm: props.auth.user.lawfirm,
            sortBy: sort,
            search: searchTerm,
            mainSearch: true
        };

        if (searchTerm) {
            obj.search = searchTerm;
        }
        if (author) {
            obj.author = author;
        }
        if (sort) {
            obj.sort = sort;
        }
        if (date) {
            obj.date = date;
        }
        let country = getLocationShorts().country;
        if (country) {
            obj.country = country;
        }

        console.log(author);

        props.blogActions.getPosts(obj, props.history);
    };

    const getPostsUsingSearch = async () => {
        let obj = {
            search: searchTerm
        };
        props.blogActions.getPostsUsingSearch({ ...props, obj }, props.history);
    };

    useEffect(() => {
        getPostsUsingSearch();
    }, [searchTerm]);

    return (
        <div className={props.type !== 'blog' ? 'list-search5' : 'list-search5'}>
            <div className='bg-search-sec' style={{ backgroundColor: 'white' }}>
                {' '}
                <input
                    style={{ width: '100%', height: '100%' }}
                    disabled={disableInput.title}
                    name='title'
                    type='text'
                    placeholder='Search'
                    value={searchTerm}
                    onChange={(e) => handleSearchInput(e, 'something', 'search')}
                />
                {searchTerm && (
                    <div className='suggBox'>
                        <div className='suggSec'>
                            <span>NameWise</span>
                            {nameWise?.length > 0 ? (
                                nameWise.map((item) => (
                                    <div className='suggItem'>
                                        <div>
                                            <img src={item.coverImage} />
                                            <div>
                                                <p
                                                    onClick={() =>
                                                        history.push('/blogs/' + item._id)
                                                    }
                                                >
                                                    {item.title}
                                                </p>
                                                <div className='names'>
                                                    {item?.author?.hospitalName && (
                                                        <>
                                                            <small
                                                                onClick={() => {
                                                                    history.push({
                                                                        pathname: `/blogs/search`,
                                                                        search: `?author=${item?.identity}`
                                                                    });
                                                                    setSearchTerm(
                                                                        item?.author?.hospitalName
                                                                    );
                                                                }}
                                                            >
                                                                {item?.author?.hospitalName}
                                                            </small>{' '}
                                                            |{' '}
                                                        </>
                                                    )}

                                                    <small
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `/blogs/search`,
                                                                search: `?author=${item?.author?._id}`
                                                            });
                                                            setSearchTerm(
                                                                item?.author?.firstName +
                                                                    ' ' +
                                                                    item?.author?.lastName
                                                            );
                                                        }}
                                                    >
                                                        {item?.author?.firstName}{' '}
                                                        {item?.author?.lastName}
                                                    </small>
                                                    <div className='cat'>
                                                        <IoPricetagOutline />{' '}
                                                        {item &&
                                                            item.category &&
                                                            item.category.map((it, ind) => {
                                                                if (ind < 2) {
                                                                    return (
                                                                        <small
                                                                            onClick={() => {
                                                                                history.push({
                                                                                    pathname: `/blogs/search`,
                                                                                    search: `?category=${it}`
                                                                                });
                                                                                setSearchTerm(it);
                                                                            }}
                                                                            key={ind}
                                                                        >
                                                                            {it}
                                                                            {ind ==
                                                                                item.category
                                                                                    .length && ','}
                                                                        </small>
                                                                    );
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span>No matching Blogs</span>
                            )}
                        </div>
                        <div className='suggSec'>
                            <span>TitleWise</span>
                            {titleWise?.length > 0 ? (
                                titleWise.map((item) => (
                                    <div className='suggItem'>
                                        <div>
                                            <img src={item.coverImage} />
                                            <div>
                                                <p
                                                    onClick={() =>
                                                        history.push('/blogs/' + item._id)
                                                    }
                                                >
                                                    {item.title}
                                                </p>
                                                <div className='names'>
                                                    {item?.author?.hospitalName && (
                                                        <>
                                                            <small
                                                                onClick={() => {
                                                                    history.push({
                                                                        pathname: `/blogs/search`,
                                                                        search: `?author=${item?.identity}`
                                                                    });
                                                                    setSearchTerm(
                                                                        item?.author?.hospitalName
                                                                    );
                                                                }}
                                                            >
                                                                {item?.author?.hospitalName}
                                                            </small>{' '}
                                                            |{' '}
                                                        </>
                                                    )}

                                                    <small
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `/blogs/search`,
                                                                search: `?author=${item?.author?._id}`
                                                            });
                                                            setSearchTerm(
                                                                item?.author?.firstName +
                                                                    ' ' +
                                                                    item?.author?.lastName
                                                            );
                                                        }}
                                                    >
                                                        {item?.author?.firstName}{' '}
                                                        {item?.author?.lastName}
                                                    </small>
                                                    <div className='cat'>
                                                        <IoPricetagOutline />{' '}
                                                        {item &&
                                                            item.category &&
                                                            item.category.map((it, ind) => {
                                                                if (ind < 2) {
                                                                    return (
                                                                        <small
                                                                            onClick={() => {
                                                                                history.push({
                                                                                    pathname: `/blogs/search`,
                                                                                    search: `?category=${it}`
                                                                                });
                                                                                setSearchTerm(it);
                                                                            }}
                                                                            key={ind}
                                                                        >
                                                                            {it}
                                                                            {ind ==
                                                                                item.category
                                                                                    .length && ','}
                                                                        </small>
                                                                    );
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span>No matching Blogs</span>
                            )}
                        </div>
                        <div className='suggSec'>
                            <span>CategoryWise</span>
                            {categoryWise?.length > 0 ? (
                                categoryWise.map((item) => (
                                    <div className='suggItem'>
                                        <div>
                                            <img src={item.coverImage} />
                                            <div>
                                                <p
                                                    onClick={() =>
                                                        history.push('/blogs/' + item._id)
                                                    }
                                                >
                                                    {item.title}
                                                </p>
                                                <div className='names'>
                                                    {item?.author?.hospitalName && (
                                                        <>
                                                            <small
                                                                onClick={() => {
                                                                    history.push({
                                                                        pathname: `/blogs/search`,
                                                                        search: `?author=${item?.identity}`
                                                                    });
                                                                    setSearchTerm(
                                                                        item?.author?.hospitalName
                                                                    );
                                                                }}
                                                            >
                                                                {item?.author?.hospitalName}
                                                            </small>{' '}
                                                            |{' '}
                                                        </>
                                                    )}

                                                    <small
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `/blogs/search`,
                                                                search: `?author=${item?.author?._id}`
                                                            });
                                                            setSearchTerm(
                                                                item?.author?.firstName +
                                                                    ' ' +
                                                                    item?.author?.lastName
                                                            );
                                                        }}
                                                    >
                                                        {item?.author?.firstName}{' '}
                                                        {item?.author?.lastName}
                                                    </small>
                                                    <div className='cat'>
                                                        <IoPricetagOutline />{' '}
                                                        {item &&
                                                            item.category &&
                                                            item.category.map((it, ind) => {
                                                                if (ind < 2) {
                                                                    return (
                                                                        <small
                                                                            onClick={() => {
                                                                                history.push({
                                                                                    pathname: `/blogs/search`,
                                                                                    search: `?category=${it}`
                                                                                });
                                                                                setSearchTerm(it);
                                                                            }}
                                                                            key={ind}
                                                                        >
                                                                            {it}
                                                                            {ind ==
                                                                                item.category
                                                                                    .length && ','}
                                                                        </small>
                                                                    );
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span>No matching Blogs</span>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className='bg-sort-inputs'>
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Area of Specialization'
                    onChange={(e, value) => handleSearchInput(e, value, 'cats')}
                    value={cats}
                    disabled={disableInput.category}
                >
                    {[...blogSpecializationAreas, ...PharmacyBlogCategories].map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className='bg-sort-inputs'>
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Sort by'
                    disabled={disableInput.sortby}
                    onChange={(e, value) => handleSearchInput(e, value, 'sort')}
                    value={sort}
                >
                    {sortby.map((item, index) => (
                        <Option key={index} value={item.name}>
                            {item.value}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className='bg-sort-inputs'>
                <DatePicker
                    name='dob'
                    value={date}
                    placeholder='Search by date'
                    onChange={(e, value) => handleSearchInput(e, value, 'date')}
                    style={{ width: '100%', height: '40px', borderRadius: '4px' }}
                    disabled={disableInput.date}
                    borderd={false}
                />
            </div>
            {/* </div> */}
            <div className='btn-primary' onClick={handleReset}>
                <p>Reset</p>
            </div>
            <a
                onClick={() => {
                    searchBlog();
                }}
            >
                <div className='btn-primary'>
                    <p>Search</p>
                </div>
            </a>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        allBlogs: state.blog.allBlogs,
        popularBlogs: state.blog.popularPosts,
        recentBlogs: state.blog.recentPosts,
        blogs: state.blog,
        titleWise: state.blog.titleWise,
        nameWise: state.blog.nameWise,
        categoryWise: state.blog.categoryWise
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch),
        blogActions: bindActionCreators(blogActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BlogSearch);
