import * as Types from '../actions/types';

const init = {
    dashboard: {
        appointmentsToday: [],
        pendingApprovals: 0,
        upcomingAppointments: 0,
        cancelledAppointments: 0,
        failedAppointments: 0,
        missedAppointments: 0,
        rejectedAppointments: 0,
        completedAppointments: 0,
        noShowAppointments: 0
    },
    doctors: [],
    featuredDoctors: [],
    doctorSingle: null,
    searchResults: {
        doctors: [],
        doctorsCount: 0,
        doctorsSuggestions: []
    },
    doctorForAppointment: {
        doctor: {},
        timeSlots: [],
        availableDays: [],
        availability: {}
    },

    doctorStatCounts: {
        posts: 0,
        likes: 0,
        reviews: 0,
        followers: 0
    },
    services: [],
    weeklyStatistics: []
};

const doctorReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_DOCTORS: {
            return {
                ...state,
                doctors: action.payload.doctors
            };
        }
        case Types.GET_FEATURED_DOCTORS: {
            return {
                ...state,
                featuredDoctors: action.payload.doctors
            };
        }
        case Types.GET_SINGLE_DOCTOR: {
            return {
                ...state,
                doctorSingle: action.payload.doctorSingle
            };
        }
        case Types.CLEAR_DOCTORS: {
            return {
                ...state,
                doctors: []
            };
        }

        case Types.GET_DOCTOR_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    doctors: action.payload.doctors,
                    doctorsCount: action.payload.doctorsCount
                }
            };
        }
        case Types.CLEAR_DOCTOR_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    doctors: [],
                    doctorsCount: 0
                }
            };
        }
        case Types.GET_DOCTOR_STAT_COUNTS: {
            return {
                ...state,
                doctorStatCounts: {
                    posts: action.payload.counts && action.payload.counts.posts,
                    likes: action.payload.counts && action.payload.counts.likes,
                    reviews: action.payload.counts && action.payload.counts.reviews,
                    followers: action.payload.counts && action.payload.counts.followers
                }
            };
        }

        case Types.GET_DOCTOR_EXTRACTED_TIME_SLOTS: {
            return {
                ...state,
                doctorForAppointment: {
                    ...state.doctorForAppointment,
                    availableDays: action.payload.availableDays,
                    timeSlots: action.payload.timeSlots,
                    availability: action.payload.doctorAvailability
                }
            };
        }
        case Types.GET_DOCTOR_FOR_APPOINTMENT: {
            return {
                ...state,
                doctorForAppointment: {
                    ...state.doctorForAppointment,
                    doctor: action.payload.doctor
                }
            };
        }

        case Types.CLEAR_APPOINTMENT_MEMBER: {
            return {
                ...state,
                doctorForAppointment: {
                    timeSlots: [],
                    availability: {},
                    doctor: {}
                }
            };
        }
        case Types.GET_MY_SERVICES: {
            return {
                ...state,
                services: action.payload.services
            };
        }
        case Types.GET_MY_WEEKLY_STATISTICS: {
            return {
                ...state,
                weeklyStatistics: action.payload.weeklyStatistics
            };
        }

        case Types.GET_DOCTOR_DASHBOARD: {
            return {
                ...state,
                dashboard: action.payload.dashboard
            };
        }
        default:
            return state;
    }
};

export default doctorReducer;
