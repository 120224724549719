import * as Types from '../../actions/types';

export const clearState = (value) => (dispatch) => {
    dispatch({
        type: Types.CLEAR_AUTH_MODALS_STATE
    });
};
export const setRegProp = (value) => (dispatch) => {
    dispatch({
        type: Types.SET_REG_PROP,
        payload: {
            regProp: value
        }
    });
};

export const setEmailVerifiedMsg = (value) => (dispatch) => {
    dispatch({
        type: Types.SET_EMAIL_VERIFIED_MSG,
        payload: {
            emailVerifiedMsg: value
        }
    });
};

export const setRegister = (value, userType = "PATIENT") => (dispatch) => {
    console.log(value, userType)
    dispatch({
        type: Types.SET_SIGN_UP_MODAL,
        payload: {
            signUpModal: value,
            modalUserType: userType
        }
    });
};

export const setUserType = (value) => (dispatch) => {
    dispatch({
        type: Types.SET_MODAL_USERTYPE,
        payload: {
            modalUserType: value ? value : "PATIENT"
        }
    });
};

export const setLogin = (value, userType = "PATIENT") => (dispatch) => {
    dispatch({
        type: Types.SET_SIGN_IN_MODAL,
        payload: {
            signInModal: value,
            modalUserType: userType
        }
    });
};

export const setPhoneSignIn = (value) => (dispatch) => {
    dispatch({
        type: Types.SET_SIGN_IN_WITH_PHONE_MODAL,
        payload: {
            signInWithPhoneModal: value
        }
    });
};

export const setPhoneSignUp = (value) => (dispatch) => {
    dispatch({
        type: Types.SET_SIGN_UP_WITH_PHONE_MODAL,
        payload: {
            signUpWithPhoneModal: value
        }
    });
};

export const setResetPass = (value) => (dispatch) => {
    
    dispatch({
        type: Types.SET_RESET_PASS_MODAL,
        payload: {
            resetPassModal: value
        }
    });
};

export const setForgotPass = (value) => (dispatch) => {
    dispatch({
        type: Types.SET_FORGOT_PASS_MODAL,
        payload: {
            forgotPassModal: value
        }
    });
};
