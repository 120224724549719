import { Divider, Tag } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime, Duration } from 'luxon';

const CurrentSubscription = () => {
    const { upcomingInvoice, userProfile, productTypes, invoices } = useSelector(
        (state) => state.auth
    );
    const {
        userLimit,
        featuredUserLimit,
        numOfUsers,
        numOfFeaturedUsers,
        featured,
        currentSubscribedPackage,
        currentSubscribedBillingCycle
    } = userProfile;

    const lastInvoiceDate =
        invoices.length > 0 && invoices[invoices.length - 1]?.createdAt
            ? DateTime.fromISO(invoices[invoices.length - 1].createdAt).toFormat('MMMM d, yyyy')
            : invoices.length > 0 && invoices[invoices.length - 1]?.created
            ? DateTime.fromMillis(invoices[invoices.length - 1].created * 1000).toFormat(
                  'MMMM d, yyyy'
              )
            : 'N/A';

    let upcomingInvoiceDate = upcomingInvoice.next_payment_attempt
        ? DateTime.fromMillis(upcomingInvoice.next_payment_attempt * 1000).toFormat('MMMM d, yyyy')
        : DateTime.fromFormat(lastInvoiceDate, 'MMMM d, yyyy')
              .plus({
                  year: 1
              })
              .toFormat('MMMM d, yyyy') || 'N/A';

    const DoctorPrice = productTypes.find(
        (product) => product.productName === 'Doctor'
    )?.productPrice;
    const FeaturedDoctorPrice = productTypes.find(
        (product) => product.productName === 'Featured Doctor'
    )?.productPrice;
    const FeaturedHospitalPrice = productTypes.find(
        (product) => product.productName === 'Featured Hospital'
    )?.productPrice;
    const BasePackage = productTypes.find(
        (product) => product.productName === currentSubscribedPackage
    )?.productPrice;

    const billingCycle = currentSubscribedBillingCycle === 'Monthly' ? 1 : 12;
    const totalDoctorPrice = parseFloat(DoctorPrice) * parseInt(userLimit - 1, 10) * billingCycle;
    const totalFeaturedDoctorPrice =
        parseFloat(FeaturedDoctorPrice) * parseInt(featuredUserLimit, 10) * billingCycle;
    const totalFeaturedHospitalPrice = parseFloat(FeaturedHospitalPrice) * 1 * billingCycle;
    const totalBasePackagePrice = parseFloat(BasePackage) * billingCycle;
    const totalPrice =
        totalDoctorPrice +
        totalFeaturedDoctorPrice +
        totalFeaturedHospitalPrice +
        totalBasePackagePrice;

    console.log(
        totalBasePackagePrice,
        currentSubscribedBillingCycle,
        BasePackage,
        currentSubscribedPackage,
        totalPrice
    );
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: 450,
                padding: 20,
                backgroundColor: 'white',
                borderRadius: 4
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginBottom: 10
                }}
            >
                <div style={{ marginBottom: 10 }}>
                    <h3 style={{ fontSize: 20, color: 'rgba(0,0,0,0.7)', fontWeight: 500 }}>
                        Essentials
                    </h3>
                    <small>Current plan</small>
                </div>
                <div>
                    {featured && (
                        <Tag
                            style={{
                                color: '#fff',
                                backgroundColor: 'var(--primary)'
                            }}
                        >
                            Featured
                        </Tag>
                    )}
                </div>
            </div>

            <Divider style={{ margin: '10px 0px' }} />
            <div
                style={{
                    display: 'flex',
                    gap: 5,
                    padding: '0px 10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 70
                }}
            >
                <div style={{ padding: '5px 10px' }}>
                    <h3
                        style={{
                            textAlign: 'center',
                            fontSize: 22,
                            fontWeight: 400,
                            marginBottom: 5,
                            color: 'rgba(0,0,0,0.8)'
                        }}
                    >
                        {userLimit - featuredUserLimit}
                    </h3>
                    <div style={{ color: 'rgba(0,0,0,0.7)' }}>Regular Doctor(s)</div>
                </div>
                <Divider
                    type='vertical'
                    style={{ height: '50%', width: 1, backgroundColor: 'rgba(0,0,0,0.1)' }}
                />
                <div style={{ padding: '5px 10px' }}>
                    <h3
                        style={{
                            textAlign: 'center',
                            fontSize: 22,
                            fontWeight: 400,
                            marginBottom: 5,
                            color: 'rgba(0,0,0,0.8)'
                        }}
                    >
                        {featuredUserLimit}
                    </h3>
                    <div style={{ color: 'rgba(0,0,0,0.7)' }}>Featured Doctor(s)</div>
                </div>
            </div>
            <Divider style={{ margin: '10px 0px' }} />

            <div className='datas'>
                <div className='dataRow'>
                    <div>Total per year</div>
                    <div>${totalPrice}</div>
                </div>
                <div className='dataRow'>
                    <div>
                        Base Subscription Package (Yealy): ${parseInt(BasePackage)} *{' '}
                        {billingCycle == 'Monthly' ? 1 : '12'}
                    </div>
                    <div>${totalBasePackagePrice}</div>
                </div>
                <div className='dataRow'>
                    <div>
                        Per Doctor/month: $0 * 1 + ${DoctorPrice} * {userLimit - 1}
                    </div>
                    <div>${totalDoctorPrice / 12}</div>
                </div>
                <div className='dataRow'>
                    <div>
                        Per Feat. Doctor/month: ${FeaturedDoctorPrice} * {featuredUserLimit}
                    </div>
                    <div>${totalFeaturedDoctorPrice}</div>
                </div>
                <div className='dataRow'>
                    <div>Feat. Hospital/month: ${FeaturedHospitalPrice} * 1</div>
                    <div>${totalFeaturedHospitalPrice / 12}</div>
                </div>
                <div className='dataRow'>
                    <div>Payment Frequency:</div>
                    <div>{currentSubscribedBillingCycle}</div>
                </div>

                <div className='dataRow'>
                    <div>Last Payment</div>
                    <div>{lastInvoiceDate}</div>
                </div>
                <div className='dataRow'>
                    <div>Next Payment:</div>
                    <div>{upcomingInvoiceDate}</div>
                </div>

                <div className='dataRow'>
                    <div>Subscription Expires:</div>
                    <div>{upcomingInvoiceDate}</div>
                </div>
            </div>
        </div>
    );
};

export default CurrentSubscription;
