import React, { useState, useEffect } from 'react';
import { message, Modal, Radio, Button, Select } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as branchActions from '@/actions/branchActions';
import * as generalActions from '@/actions/generalActions';
import '@/assets/css/wizard.css';
import person from '@/assets/img/Product Feedback.svg';
import Geolocate from './../../MiniComponents/Geolocate';
import { geocodeByAddress } from 'react-places-autocomplete';
import Search from '../../MiniComponents/CanadaPost/Search';
import { CgArrowLongRight } from 'react-icons/cg';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { THERAPIST_TYPES } from '../../../assets/json/TherapistTypes';
import { getWorkTypes } from '../../../assets/json/WorkTypes';

const therapistWorkType = [
    "I work for a therapist's agency",
    'I own a therapist agency',
    'I am an independent therapist'
];

const stepFooters = {
    step1: [
        {
            title: 'Enhanced visibility and Exposure',
            description:
                'Featured law firms appear at the top of relevant searches and categories, ensuring that potential clients notice your firm first.'
        },
        {
            title: 'Credibility and Trust',
            description:
                'Users often gravitate towards featured listings, perceiving them as reputable and reliable sources for medical expertise.'
        },
        {
            title: 'Digital Presence',
            description:
                'Register on Medasq to maintain a competitive edge, your firm remains visible and accessible in the evolving medical landscape.'
        }
    ],
    step2: [
        {
            title: 'Increased Client Engagement',
            description:
                'Featured Doctors receive more attention, leading to increased client inquiries and consultations.'
        },
        {
            title: 'Efficient Client Acquisition',
            description:
                'Clients can easily find your firm, review your profile, and schedule appointments, making the process efficient and convenient.'
        },
        {
            title: 'Global Reach',
            description:
                'Registering your law firm on Medasq opens up your practice to a global clientele expanding your reach and accessing new markets.'
        }
    ],
    step3: [
        {
            title: 'Secure and Confidential',
            description:
                "Data security and confidentiality are paramount at Medasq. Clients' sensitive medical matters are handled with the utmost confidentiality."
        },
        {
            title: 'Enhanced Medical Access',
            description:
                "This digital presence is crucial in today's medical landscape, offering easy access and connection between doctors and clients."
        },
        {
            title: 'Transparent Pricing',
            description:
                'Clients can easily understand the fees associated with consultations, making it simpler to budget for medical assistance.'
        }
    ]
};

const initState = {
    value: '',
    message: '',
    isValid: true
};

const SignUpWIzard = (props) => {
    let { wizard, setWizard, packages, productTypes, auth } = props;
    const { user } = auth || {};
    const { userType } = user || {};
    const WORK_TYPES = getWorkTypes(user.userType);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [slide, setSlide] = useState(1);
    const [chosenPackage, setChosenPackage] = useState('Essentials');
    const [billingType, setBillingType] = useState('trial');
    const stripe = useStripe();
    const elements = useElements();
    const [yearlyBill, setYearlyBill] = useState(0);
    const [cardElementError, setCardElementError] = useState('');
    const [cardElementValid, setCardElementValid] = useState(false);
    const [stage1Button, setStage1Button] = useState();
    const [stage3Button, setStage3Button] = useState();
    const [billingCity, setBillingCity] = useState({ ...initState });
    const [featured, setFeatured] = useState(false);
    const [workType, setWorkType] = useState({ ...initState, value: WORK_TYPES[0].id });
    const [billingCountry, setBillingCountry] = useState({ ...initState });
    const [billingAddress, setBillingAddress] = useState({ ...initState });
    const [billingState, setBillingState] = useState({ ...initState });
    const [billingZip, setBillingZip] = useState({ ...initState });
    const [billingCoordinates, setBillingCoordinates] = useState({
        lat: '',
        lng: ''
    });

    const [firstName, setFirstName] = useState({ ...initState });
    const [lastName, setLastName] = useState({ ...initState });
    const [therapistType, setTherapistType] = useState({ ...initState });

    const getPackageDetails = (selectedPackage) => {
        let packageDetails = productTypes.filter((item) => item.productName == selectedPackage)[0];
        return packageDetails;
    };
    useEffect(() => {
        if (user) {
            setWorkType({ ...workType, value: user.workType });
        }
    }, [user]);
    useEffect(() => {
        if (firstName.value && lastName.value) {
            setStage1Button(true);
        } else {
            setStage1Button(false);
        }

        if (
            billingAddress.value &&
            billingCity.value &&
            billingCountry.value &&
            billingState.value &&
            billingZip.value 
        ) {
            setStage3Button(true);
        } else {
            setStage3Button(false);
        }
    }, [
        firstName.value,
        lastName.value,
        billingAddress.value,
        billingCity.value,
        billingCountry.value,
        billingState.value,
        billingZip.value,
        cardElementValid
    ]);

    useEffect(() => {
        let chosenPackagePrice = parseFloat(
            packages.filter((item) => item.productName == chosenPackage)[0]?.productPrice
        );
        let featuredTherapistPackagePrice = parseFloat(
            productTypes?.filter((item) => item.productName == 'Featured Therapist')[0]
                ?.productPrice
        );

        let monthlyPrice = chosenPackagePrice + (featured ? featuredTherapistPackagePrice : 0);
        let yearlyPrice = monthlyPrice * 12;
        console.log('monthlyPrice', monthlyPrice, chosenPackagePrice);
        setYearlyBill(yearlyPrice);
    }, [featured, chosenPackage, productTypes, slide]);

    let featuredTherapistPrice = parseFloat(
        productTypes?.filter((item) => item.productName == 'Featured Therapist')[0]?.productPrice
    );

    let featuredTherapistPackage = getPackageDetails('Featured Therapist');

    let chosenPackageDetails = getPackageDetails(chosenPackage);
    let chosenPackagePrice = parseFloat(chosenPackageDetails?.productPrice);

    const proceedToSlide2 = async () => {
        setFirstName({
            ...firstName,
            message: firstName.value || 'First name cannot be empty',
            isValid: firstName.value ? true : false
        });
        setLastName({
            ...lastName,
            message: lastName.value || 'Last name cannot be empty',
            isValid: lastName.value ? true : false
        });

        setTherapistType({
            ...therapistType,
            message: therapistType.value || 'Therapist Type cannot be empty',
            isValid: therapistType.value ? true : false
        });

        if (firstName.value && lastName.value && therapistType.value) {
            setSlide(2);
        }
    };
    let country = localStorage.getItem('country_short');
    let countryCondition =
        country != 'US' &&
        country != 'CAN' &&
        country != 'CN' &&
        country != 'CA' &&
        country != 'USA';

    const handleSelectionCanadaPost = async (item) => {
        setBillingAddress({
            ...billingAddress,
            value: item.Line1 ? item.Line1 : '',
            isValid: true,
            message: ''
        });

        setBillingCity({
            ...billingCity,
            value: item.City ? item.City : '',
            isValid: true,
            message: ''
        });

        setBillingCountry({
            ...billingCountry,
            value: item.CountryName ? item.CountryName : '',
            isValid: true,
            message: ''
        });

        setBillingState({
            ...billingState,
            value: item.Province ? item.Province : '',
            isValid: true,
            message: ''
        });

        setBillingCoordinates({
            ...billingCoordinates,
            value: item.coordinates ? item.coordinates : {},
            isValid: true,
            message: ''
        });

        setBillingZip({
            ...billingZip,
            value: item.PostalCode ? item.PostalCode : '',
            isValid: true,
            message: ''
        });
    };

    const updateBillingAddress = async (addr) => {
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);

        address = address[0];
        componentForm.formattedAddress = address.formatted_address;
        for (var i = 0; i < address.address_components.length; i++) {
            var addressType = address.address_components[i].types[0];
            if (componentForm[addressType] == '') {
                let val = address.address_components[i][componentForm[addressType]];
                componentForm[addressType] = address.address_components[i].long_name;
            }
        }
        if (address && address.geometry) {
            setBillingCoordinates({
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            });
        }

        if (componentForm.country !== '')
            setBillingCountry({
                ...billingCountry,
                value: componentForm.country,
                isValid: true,
                message: ''
            });
        else
            setBillingCountry({
                ...billingCountry,
                value: '',
                isValid: false,
                message: 'Please enter a valid country'
            });

        if (componentForm.postal_code !== '')
            setBillingZip({
                ...billingZip,
                value: componentForm.postal_code,
                isValid: true,
                message: ''
            });
        else
            setBillingZip({
                ...billingZip,
                value: '',
                isValid: false,
                message: 'Please enter a valid postal code'
            });

        if (componentForm.postal_town !== '')
            setBillingCity({
                ...billingCity,
                value: componentForm.postal_town,
                isValid: true,
                message: ''
            });
        else if (componentForm.locality !== '')
            setBillingCity({
                ...billingCity,
                value: componentForm.locality,
                isValid: true,
                message: ''
            });
        else
            setBillingCity({
                ...billingCity,
                value: '',
                isValid: false,
                message: 'Please enter a valid city'
            });

        if (componentForm.administrative_area_level_1 !== '')
            setBillingState({
                ...billingState,
                value: componentForm.administrative_area_level_1,
                isValid: true,
                message: ''
            });
        else
            setBillingState({
                ...billingState,
                value: '',
                isValid: false,
                message: 'Please enter a valid state'
            });

        if (componentForm.street_number && componentForm.route) {
            setBillingAddress({
                ...billingAddress,
                value: componentForm.street_number + ' ' + componentForm.route,
                isValid: true,
                message: ''
            });
        } else if (componentForm.route) {
            setBillingAddress({
                ...billingAddress,
                value: componentForm.route,
                isValid: true,
                message: ''
            });
        } else if (componentForm.formattedAddress) {
            setBillingAddress({
                ...billingAddress,
                value: componentForm.formattedAddress,
                isValid: true,
                message: ''
            });
        } else {
            setBillingAddress({
                ...billingAddress,
                value: '',
                isValid: false,
                message: 'Please enter a valid address'
            });
        }
    };

    const iframeStyles = {
        base: {
            color: '#000',
            fontSize: '16px',
            iconColor: '#000',
            '::placeholder': {
                color: 'gray'
            }
        },
        invalid: {
            iconColor: '#f14c48',
            color: '#f14c48'
        },
        complete: {
            iconColor: '#cbf4c9'
        }
    };

    const cardElementOpts = {
        iconStyle: 'solid',
        style: iframeStyles,
        hidePostalCode: true
    };

    const handleCardDetailsChange = (cardElement) => {
        if (cardElement.error && cardElement.error.message) {
            setCardElementError(cardElement.error.message);
            setCardElementValid(false);
        } else {
            setCardElementError('');
            setCardElementValid(true);
        }
        if (cardElement.empty) {
            setCardElementValid(false);
            setCardElementError(cardElement.error.message);
        }
        // ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };

    const submitForm = async () => {
        setFormSubmitting(true);
        // const cardElement = elements.getElement('card');
        // const paymentMethodReq = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: cardElement,
        //     billing_details: {
        //         name: firstName.value + ' ' + lastName.value
        //     }
        // });
        let billingAddressFlag = false;
        if (
            !billingAddress.isValid ||
            !billingAddress.value ||
            !billingCity.isValid ||
            !billingCity.value ||
            !billingCountry.isValid ||
            !billingCountry.value ||
            !billingState.isValid ||
            !billingState.value ||
            !billingZip.isValid ||
            !billingZip.value
        ) {
            billingAddressFlag = true;
            if (!billingAddress.value) {
                setBillingAddress({
                    ...billingAddress,
                    isValid: false,
                    message: 'Billing address cannot be empty'
                });
            }
            if (!billingCity.value) {
                setBillingCity({
                    ...billingCity,
                    isValid: false,
                    message: 'Billing city cannot be empty'
                });
            }
            if (!billingCountry.value) {
                setBillingCountry({
                    ...billingCountry,
                    isValid: false,
                    message: 'Billing country cannot be empty'
                });
            }
            if (!billingState.value) {
                setBillingState({
                    ...billingState,
                    isValid: false,
                    message: 'Billing state cannot be empty'
                });
            }
            if (!billingZip.value) {
                setBillingZip({
                    ...billingZip,
                    isValid: false,
                    message: 'Billing zip cannot be empty'
                });
            }
        }

        // if (paymentMethodReq && paymentMethodReq.paymentMethod && !billingAddressFlag) {
            if (!billingAddressFlag) {
            let obj = {
                userid: props.auth.userId,
                firstName: firstName.value,
                lastName: lastName.value,
                therapistType: therapistType.value,
                // stripeToken: paymentMethodReq.paymentMethod.id,
                // stripePaymentMethod: paymentMethodReq.paymentMethod,
                subscribedPackage: chosenPackage,
                billingCycle: billingType,
                price: billingType === 'trial' ? 0 : yearlyBill,
                featured: featured,
                workType: workType.value,
                billing: {
                    billingState: billingState.value,
                    billingAddress: billingAddress.value,
                    billingCity: billingCity.value,
                    billingCountry: billingCountry.value,
                    billingCoordinates: billingCoordinates,
                    billingZip: billingZip.value
                }
            };
            let saveWizard = await props.actions.saveTherapistSignUpWizard(
                { ...props, obj },
                props.history
            );
            if (saveWizard) {
                let profileShort = {
                    userId: props.auth.user._id,
                    userType: props.auth.user.userType
                };

                await props.actions.getProfile(profileShort, props.history);
                setFormSubmitting(false);
                setWizard(false);
                obj = {
                    userId: props.auth.userId,
                    userType: props.auth.user.userType
                };
                await props.generalActions.getHospitalServices({ ...props, obj }, history);
                if (saveWizard.url) {
                    window.location.href = saveWizard.url;
                }
            } else {
                setFormSubmitting(false);
            }
        } else {
            setFormSubmitting(false);
            message.error('Not a valid card number');
        }
    };

    return (
        <Modal
            centered
            className='wizardModal'
            title='Medasq Sign Up Wizard'
            width={1500}
            open={wizard}
            closable={false}
            onOk={() => setWizard(false)}
            onCancel={() => setWizard(false)}
            footer={null}
        >
            <div className='pageIndicator'>
                {/* <h1>Page {slide}</h1> */}
                <div className='indicator1'>
                    <div className='incdicator-icons'>
                        <div
                            className={`iconContainer ${slide == 1 ? 'active' : ''} ${
                                slide > 1 ? 'completed' : ''
                            }`}
                        >
                            {slide > 1 ? (
                                <i className='fa fa-check' />
                            ) : (
                                <div className='indicator-number'>
                                    <p>1</p>
                                </div>
                            )}
                        </div>
                        <div>
                            <p>Info</p>
                        </div>
                    </div>
                    <div className='indicator-arrow'>
                        <CgArrowLongRight />
                    </div>
                </div>

                <div className='indicator1'>
                    <div className='incdicator-icons'>
                        <div
                            className={`iconContainer ${slide == 2 ? 'active' : ''} ${
                                slide > 2 ? 'completed' : ''
                            }`}
                        >
                            {slide > 2 ? (
                                <i className='fa fa-check' />
                            ) : (
                                <div className='indicator-number'>
                                    <p>2</p>
                                </div>
                            )}
                        </div>
                        <div>
                            <p>Plan</p>
                        </div>
                    </div>
                    <div className='indicator-arrow'>
                        <CgArrowLongRight />
                    </div>
                </div>

                <div className='indicator1'>
                    <div className='incdicator-icons'>
                        <div
                            className={`iconContainer ${slide == 3 ? 'active' : ''} ${
                                slide > 3 ? 'completed' : ''
                            }`}
                        >
                            {slide > 3 ? (
                                <i className='fa fa-check' />
                            ) : (
                                <div className='indicator-number'>
                                    <p>3</p>
                                </div>
                            )}
                        </div>
                        <div>
                            <p>Billings</p>
                        </div>
                    </div>
                </div>
            </div>

            {slide == 1 ? (
                <div className='page pageOne'>
                    <div className='sides'>
                        <div
                            className='side leftSide'
                            style={{ background: `url(${person}) no-repeat` }}
                        >
                            {/* <img src={person} /> */}
                        </div>
                        <div className='side rightSide'>
                            <h3>Just a few more steps to complete Registration</h3>
                            <h4>
                                Please complete all required fields to finish setting up your
                                account and get full access to Medasq!
                            </h4>
                            <div className='innerDiv'>
                                <div className='inputRow'>
                                    <label
                                        className={`${!firstName.isValid ? 'error' : ''}`}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1
                                        }}
                                    >
                                        First name *
                                        <input
                                            name='firstName'
                                            placeholder='First name'
                                            value={firstName.value}
                                            autoComplete='new-password'
                                            autoCorrect='off'
                                            onChange={(e) => {
                                                if (e.target.value != '') {
                                                    setFirstName({
                                                        ...firstName,
                                                        value: e.target.value,
                                                        message: '',
                                                        isValid: true
                                                    });
                                                } else {
                                                    setFirstName({
                                                        ...firstName,
                                                        value: e.target.value,
                                                        message: 'First name is required',
                                                        isValid: false
                                                    });
                                                }
                                            }}
                                        />
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {firstName.message
                                                ? firstName.message
                                                : 'First name is required'}
                                        </p>
                                    </label>

                                    <label
                                        className={`${!lastName.isValid ? 'error' : ''}`}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1
                                        }}
                                    >
                                        Last name *
                                        <input
                                            name='lastName'
                                            placeholder='Last name'
                                            value={lastName.value}
                                            autoComplete='new-password'
                                            autoCorrect='off'
                                            onChange={(e) => {
                                                if (e.target.value != '') {
                                                    setLastName({
                                                        ...lastName,
                                                        value: e.target.value,
                                                        message: '',
                                                        isValid: true
                                                    });
                                                } else {
                                                    setLastName({
                                                        ...lastName,
                                                        value: e.target.value,
                                                        message: 'Last name is required',
                                                        isValid: false
                                                    });
                                                }
                                            }}
                                        />
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {lastName.message
                                                ? lastName.message
                                                : 'Last name is required'}
                                        </p>
                                    </label>
                                    <label
                                        className={`${
                                            !therapistType.isValid ? 'error' : ''
                                        } fullLabel`}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        Therapist Type *
                                        <Select
                                            name='therapistType'
                                            placeholder='Therapist Type'
                                            autoComplete='new-password'
                                            autoCorrect='off'
                                            value={therapistType.value || undefined}
                                            onChange={(value) => {
                                                setTherapistType({
                                                    ...therapistType,
                                                    value: value || '',
                                                    message: value || 'Therapist Type is required',
                                                    isValid: value ? true : false
                                                });
                                            }}
                                        >
                                            {THERAPIST_TYPES.map((therapistType) => (
                                                <Option key={therapistType} value={therapistType}>
                                                    {therapistType}
                                                </Option>
                                            ))}
                                        </Select>
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {therapistType.message
                                                ? therapistType.message
                                                : 'Therapist Type is required'}
                                        </p>
                                    </label>

                                    <div
                                        style={{
                                            rowGap: 10,
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            fontSize: 14
                                        }}
                                    >
                                        Would you like to be featured on our site?
                                        <Radio.Group
                                            style={{ width: 'fit-content' }}
                                            onChange={(e) => {
                                                setFeatured(e.target.value);
                                            }}
                                            value={featured}
                                        >
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                        <div style={{ minHeight: 20 }}>
                                            <p
                                                style={{
                                                    fontSize: 11,
                                                    backgroundColor: 'darkslateblue',
                                                    color: '#fff',
                                                    paddingLeft: 10
                                                }}
                                            >
                                                {`Opt-in for enhanced visibility and unlock opportunities to showcase your expertise to a wider audience.`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    type='primary'
                                    disabled={stage1Button ? false : true}
                                    onClick={(e) => {
                                        proceedToSlide2();
                                    }}
                                >
                                    Continue <i className='fe fe-chevron-right' />
                                </Button>
                            </div>
                        </div>
                    </div>

                    <hr />
                </div>
            ) : slide == 2 ? (
                <div className='page pageTwo'>
                    <div className='sides'>
                        <div className='side leftSide'>
                            <h4>Choose Subscription Package</h4>
                            <div className='cardRow'>
                                {packages.map((item) => {
                                    return (
                                        <div
                                            key={item._id}
                                            className={`${
                                                chosenPackage == item.productName ? 'active' : ''
                                            }   card`}
                                            onClick={(e) => {
                                                setChosenPackage(item.productName);
                                            }}
                                        >
                                            <h3>
                                                {item.productName}{' '}
                                                <span className='checkBox'></span>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i className='fe fe-check' />
                                                    {item.productName == 'Essentials'
                                                        ? 'No commision'
                                                        : 'Grow Your Business'}
                                                </li>
                                                <li>
                                                    <i className='fe fe-check' />
                                                    Your listing on Medasq
                                                </li>
                                                <li>
                                                    <i className='fe fe-check' />
                                                    Lowest industry plan
                                                </li>
                                                <li>
                                                    <i className='fe fe-check' />
                                                    {item.productName == 'Essentials'
                                                        ? 'Access to one or more services'
                                                        : 'Access to all services'}
                                                </li>
                                            </ul>
                                        </div>
                                    );
                                })}
                            </div>
                            <h4>Choose Billing cycle</h4>
                            <div className='billingCycleCards'>
                                <div className='billinCyleCard'>
                                    <div
                                        className={`${billingType == 'yearly' && 'active'} card`}
                                        onClick={(e) => setBillingType('yearly')}
                                    >
                                        <div>
                                            <ul>
                                                {featured &&
                                                    getPackageDetails('Featured Therapist') && (
                                                        <li>
                                                            <i className='fe fe-check' />
                                                            {featuredTherapistPackage.productName} $
                                                            {featuredTherapistPrice} * 12 = $
                                                            {featuredTherapistPrice * 12}
                                                        </li>
                                                    )}
                                                <li>
                                                    <i className='fe fe-check' />
                                                    {chosenPackage} ${chosenPackagePrice} * 12 = $
                                                    {chosenPackagePrice * 12}
                                                </li>
                                            </ul>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <h3 style={{ margin: 0, padding: 0 }}>
                                                {'$' + yearlyBill}/Yearly{' '}
                                            </h3>
                                            <small>Billed annually</small>
                                        </div>
                                        <span className='checkBox'></span>
                                    </div>
                                </div>
                                <div className='billinCyleCard'>
                                    <div
                                        className={`${billingType == 'trial' && 'active'} card`}
                                        onClick={(e) => setBillingType('trial')}
                                    >
                                        <div>Free Trial (30 Days)</div>

                                        <span className='checkBox'></span>
                                    </div>
                                </div>
                            </div>

                            <div className='buttonGrp'>
                                <Button
                                    onClick={(e) => {
                                        setSlide(1);
                                    }}
                                >
                                    Go Back <i className='fe fe-chevron-left' />
                                </Button>
                                <Button
                                    type='primary'
                                    onClick={(e) => {
                                        setSlide(3);
                                    }}
                                >
                                    Go Next <i className='fe fe-chevron-right' />
                                </Button>
                            </div>
                        </div>
                        <div
                            className='side rightSide'
                            style={{ background: `url(${person}) no-repeat`, height: '100%' }}
                        >
                            {/* <img src={person} /> */}
                        </div>
                    </div>
                    <hr />
                </div>
            ) : (
                slide == 3 && (
                    <div className='page pageThree'>
                        <div className='sides'>
                            <div className='side leftSide'>
                                <img src={person} />
                            </div>
                            <div className='side rightSide'>
                                <h3>Just a few more steps to complete Registration</h3>
                                <h4>
                                    Please complete all required fields to finish setting up your
                                    account and get full access to Medasq!
                                </h4>
                                <div className='innerDiv'>
                                    <div className='inputRow'>
                                        {countryCondition ? (
                                            <label className='fullLabel gM'>
                                                Choose Location:
                                                <i className='fa fa-map-marker' />
                                                <Geolocate
                                                    chooseAddress={(address) =>
                                                        updateBillingAddress(address)
                                                    }
                                                />
                                                <p>
                                                    {' '}
                                                    <i className='fe fe-alert-triangle' /> Location
                                                    Finder
                                                </p>
                                            </label>
                                        ) : (
                                            <label className='fullLabel cp'>
                                                Choose Location:
                                                <i className='fa fa-map-marker' />
                                                <Search
                                                    handleSelectionCanadaPost={
                                                        handleSelectionCanadaPost
                                                    }
                                                />
                                                <p>
                                                    {' '}
                                                    <i className='fe fe-alert-triangle' /> Location
                                                    Finder
                                                </p>
                                            </label>
                                        )}
                                        <label
                                            className={`${!billingCity.isValid ? 'error' : ''}`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1
                                            }}
                                        >
                                            Billing city *
                                            <input
                                                placeholder='Billing city'
                                                value={billingCity.value}
                                                autoComplete='new-password'
                                                autoCorrect='off'
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setBillingCity({
                                                            ...billingCity,
                                                            value: e.target.value,
                                                            isValid: true,
                                                            message: ''
                                                        });
                                                    } else {
                                                        setBillingCity({
                                                            ...billingCity,
                                                            value: e.target.value,
                                                            isValid: false,
                                                            message: 'Billing city cannot be empty'
                                                        });
                                                    }
                                                }}
                                            />
                                            <p>
                                                {' '}
                                                <i className='fe fe-alert-triangle' />{' '}
                                                {billingCity.message
                                                    ? billingCity.message
                                                    : 'Billing city is required'}
                                            </p>
                                        </label>
                                        <label
                                            className={`${!billingCountry.isValid ? 'error' : ''}`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1
                                            }}
                                        >
                                            Billing country *
                                            <input
                                                placeholder='Billing country'
                                                value={billingCountry.value}
                                                autoComplete='new-password'
                                                autoCorrect='off'
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setBillingCountry({
                                                            ...billingCountry,
                                                            value: e.target.value,
                                                            isValid: true,
                                                            message: ''
                                                        });
                                                    } else {
                                                        setBillingCountry({
                                                            ...billingCountry,
                                                            value: e.target.value,
                                                            isValid: false,
                                                            message:
                                                                'Billing country cannot be empty'
                                                        });
                                                    }
                                                }}
                                            />
                                            <p>
                                                {' '}
                                                <i className='fe fe-alert-triangle' />{' '}
                                                {billingCountry.message
                                                    ? billingCountry.message
                                                    : 'Billing country is required'}
                                            </p>
                                        </label>
                                        <label
                                            className={`${!billingState.isValid ? 'error' : ''}`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1
                                            }}
                                        >
                                            Billing state/province *
                                            <input
                                                placeholder='Billing state'
                                                value={billingState.value}
                                                autoComplete='new-password'
                                                autoCorrect='off'
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setBillingState({
                                                            ...billingState,
                                                            value: e.target.value,
                                                            isValid: true,
                                                            message: ''
                                                        });
                                                    } else {
                                                        setBillingState({
                                                            ...billingState,
                                                            value: e.target.value,
                                                            isValid: false,
                                                            message: 'Billing state cannot be empty'
                                                        });
                                                    }
                                                }}
                                            />
                                            <p>
                                                {' '}
                                                <i className='fe fe-alert-triangle' />{' '}
                                                {billingState.message
                                                    ? billingState.message
                                                    : 'Billing state is required'}
                                            </p>
                                        </label>
                                        <label
                                            className={`${!billingZip.isValid ? 'error' : ''} `}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1
                                            }}
                                        >
                                            Billing postal/zip *
                                            <input
                                                placeholder='Billing zip'
                                                value={billingZip.value}
                                                autoComplete='new-password'
                                                autoCorrect='off'
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setBillingZip({
                                                            ...billingZip,
                                                            value: e.target.value,
                                                            isValid: true,
                                                            message: ''
                                                        });
                                                    } else {
                                                        setBillingZip({
                                                            ...billingZip,
                                                            value: e.target.value,
                                                            isValid: false,
                                                            message: 'Billing zip cannot be empty'
                                                        });
                                                    }
                                                }}
                                            />
                                            <p>
                                                {' '}
                                                <i className='fe fe-alert-triangle' />{' '}
                                                {billingZip.message
                                                    ? billingZip.message
                                                    : 'Billing zip is required'}
                                            </p>
                                        </label>
                                        <label
                                            className={`${
                                                !billingAddress.isValid ? 'error' : ''
                                            } fullLabel`}
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                        >
                                            Billing address(line1) *
                                            <input
                                                placeholder='Billing address'
                                                value={billingAddress.value}
                                                autoComplete='new-password'
                                                autoCorrect='off'
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setBillingAddress({
                                                            ...billingAddress,
                                                            value: e.target.value,
                                                            isValid: true,
                                                            message: ''
                                                        });
                                                    } else {
                                                        setBillingAddress({
                                                            ...billingAddress,
                                                            value: e.target.value,
                                                            isValid: false,
                                                            message:
                                                                'Billing address cannot be empty'
                                                        });
                                                    }
                                                }}
                                            />
                                            <p>
                                                {' '}
                                                <i className='fe fe-alert-triangle' />{' '}
                                                {billingAddress.message
                                                    ? billingAddress.message
                                                    : 'Billing address is required'}
                                            </p>
                                        </label>

                                        {/* <label
                                            className={`${
                                                !cardElementValid && cardElementError ? 'error' : ''
                                            } fullLabel`}
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                        >
                                            Billing card
                                            <div className='stripeCont'>
                                                <CardElement
                                                    options={cardElementOpts}
                                                    onChange={handleCardDetailsChange}
                                                />
                                            </div>
                                            <p>
                                                <i className='fe fe-alert-triangle' />{' '}
                                                {cardElementError
                                                    ? cardElementError
                                                    : 'Billing card is required'}
                                            </p>
                                        </label> */}
                                    </div>
                                    <div className='buttonGrp'>
                                        <Button
                                            type='primary'
                                            onClick={(e) => {
                                                setSlide(2);
                                            }}
                                        >
                                            Go Back <i className='fe fe-chevron-left' />
                                        </Button>
                                        <Button
                                            style={{ width: 150 }}
                                            type='primary'
                                            loading={formSubmitting}
                                            disabled={
                                                stage3Button && !formSubmitting
                                                    ? false
                                                    : true
                                            }
                                            onClick={(e) => {
                                                submitForm();
                                                setWizard(false);
                                            }}
                                        >
                                            Finish <i className='fe fe-check' />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                    </div>
                )
            )}
            <div className='infoDiv'>
                {stepFooters[`step${slide}`]?.map((item) => (
                    <div>
                        <div>
                            <i className='fe fe-user' />
                        </div>
                        <div className='textContent'>
                            <p>{item.title}</p>
                            <p>{item.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    productTypes: state.auth.productTypes,
    packages: state.auth.productTypes.filter((item, index) => item.baseSubscriptionPackage == true)
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(SignUpWIzard);
