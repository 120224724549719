import * as Types from '../actions/types';

const init = {
    dashboard: {
        practiceAreas: 0,
        doctors: 0,
        branches: 0,
        consultantServices: 0,
        roles: 0,
        appointments: 0,
        services: 0,
        appointmentsToday: [],
        pendingApprovals: 0,
        upcomingAppointments: 0,
        cancelledAppointments: 0,
        failedAppointments: 0,
        missedAppointments: 0,
        rejectedAppointments: 0,
        completedAppointments: 0,
        noShowAppointments: 0
    },
    consultants: [],
    consultantSingle: {},
    consultantSingleDoctors: [],
    consultantSingleDoctorsCount: 0,
    createdDoctor: {},
    doctors: [],
    featuredHospitals: [],
    doctorsCount: 0,
    shortMemberList: [],
    consultantForAppointment: {
        consultant: {},
        timeSlots: [],
        availableDays: [],
        availability: {}
    },
    myDoctors: {
        timeSlots: [],
        availability: {}
    },
    searchResults: {
        consultants: [],
        consultantsCount: 0,
        consultantsSuggestions: []
    },
    consultantStatCounts: {
        posts: 0,
        likes: 0,
        reviews: 0,
        followers: 0
    },
    lineChart: null,
    pieChart: []
};

const consultantReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_CONSULTANTS: {
            return {
                ...state,
                consultants: action.payload.consultants
            };
        }
        case Types.GET_FEATURED_CONSULTANTS: {
            return {
                ...state,
                featuredHospitals: action.payload.consultants
            };
        }
        case Types.GET_SINGLE_CONSULTANT: {
            return {
                ...state,
                consultantSingle: action.payload.consultantSingle
            };
        }

   
        case Types.CLEAR_CONSULTANTS: {
            return {
                ...state,
                consultants: [],
                consultantSingle: {},
                createdDoctor: {}
            };
        }
        case Types.GET_CONSULTANT_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    consultants: action.payload.consultants,
                    consultantsCount: action.payload.consultantsCount
                }
            };
        }
        case Types.CLEAR_CONSULTANT_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    consultants: [],
                    consultantsCount: 0
                }
            };
        }
        case Types.GET_CONSULTANT_STAT_COUNTS: {
            return {
                ...state,
                consultantStatCounts: {
                    posts: action.payload.counts && action.payload.counts.posts,
                    likes: action.payload.counts && action.payload.counts.likes,
                    reviews: action.payload.counts && action.payload.counts.reviews,
                    followers: action.payload.counts && action.payload.counts.followers
                }
            };
        }
        case Types.GET_CONSULTANT_EXTRACTED_TIME_SLOTS: {
            return {
                ...state,
                consultantForAppointment: {
                    ...state.consultantForAppointment,
                    availableDays: action.payload.availableDays,
                    timeSlots: action.payload.timeSlots,
                    availability: action.payload.availability
                }
            };
        }
        case Types.GET_CONSULTANT_FOR_APPOINTMENT: {
            return {
                ...state,
                consultantForAppointment: {
                    ...state.consultantForAppointment,
                    consultant: action.payload.consultant
                }
            };
        }


        case Types.GET_LINE_CHART_DATA: {
            return {
                ...state,
                lineChart: action.payload.lineChart
            };
        }
        case Types.GET_PIE_CHART_DATA: {
            return {
                ...state,
                pieChart: action.payload.pieChart
            };
        }
        case Types.GET_CONSULTANT_DASHBOARD: {
            return {
                ...state,
                dashboard: action.payload.dashboard
            };
        }
    
        default:
            return state;
    }
};

export default consultantReducer;
