import React, { useState, useEffect, memo } from 'react';
import '@/assets/css/hospitalView.css';
import LowerSection from './LowerSection';
import { useParams } from 'react-router-dom';
import * as pharmacyActions from '@/actions/pharmacyActions';
import * as reviewActions from '@/actions/reviewActions';
import * as messagingActions from '@/actions/messagingActions';
import * as generalActions from '@/actions/generalActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { Select } from 'antd';
import * as marketingActions from '@/actions/marketingAction';
import placeholderCover from '@/assets/images/coverPlaceholder.jpeg';
import profilePlaceholderImg from '@/assets/images/profilePlaceholder.jpeg';
import MessageModal from '../../../modals/MessageModal';
import ReviewModal from '../../../modals/ReviewModal';
import * as availabilityActions from '@/actions/availabilityActions';
import { FaXTwitter } from 'react-icons/fa6';

const { Option } = Select;

const PharmacyView = (props) => {
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [rating, setRating] = useState(0);
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    let { id } = useParams();
    const {
        marketingInfo,
        pharmacy,
        pharmacySingle,
        pharmacyStatCounts,
        auth: { isAuthenticated, userType }
    } = props;

    const getPharmacy = async () => {
        console.log('getting in pharmacy');
        await props.actions.getPharmacyById({ ...props, pharmacyId: id }, props.history);
    };

    const [tab, setTab] = useState('general');
    const tabs = ['doctors', 'general', 'blogs', 'following', 'reviews'];
    const handleTab = (string) => {
        if (tabs.includes(string)) {
            setTab(string);
            console.log('tab', selectedAddress);
            props.history.push(`/pharmacy-view/${id}?branchId=${selectedAddress}&tab=${string}`);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        getPharmacy();
        getMarketingInfo();
        getPharmacyStatCounts();
        let searchQuery = queryString.parse(props.location && props.location.search);
        if (searchQuery && !searchQuery.tab) {
            setTab('general');
        } else if (searchQuery && searchQuery.tab) {
            let tab = searchQuery && searchQuery.tab;
            setTab(tab);
        }
    }, [id]);

    const getPharmacyStatCounts = async () => {
        let obj = {
            pharmacyId: id
        };
        await props.actions.getPharmacyStatCounts({ ...props, obj }, props.history);
    };

    const getMarketingInfo = async () => {
        let obj = {
            userId: id
        };
        await props.marketingActions.getMarketingInfo({ ...props, obj }, props.history);
    };

    useEffect(() => {
        let obj = {
            userId: id
        };
        props.availabilityActions.getViewAvailability({ ...props, obj }, props.history);
    }, []);

    useEffect(() => {
        if (pharmacySingle && pharmacySingle.branches && pharmacySingle.branches.length > 0) {
            if (props.location && props.location.search) {
                let search = queryString.parse(props.location.search);
                console.log(props.location.search);
                if (search.branchId) {
                    setSelectedAddress(search.branchId);
                    for (let i = 0; i < pharmacySingle.branches.length; i++) {
                        let branch = pharmacySingle.branches[i];
                        if (branch._id === search.branchId) {
                            setSelectedAddressIndex(i);
                            console.log('selected', i, branch._id, search.branchId);
                            break;
                        }
                    }
                } else {
                    setSelectedAddress(
                        pharmacySingle.branches[0] && pharmacySingle.branches[0]._id
                    );
                    setSelectedAddressIndex(0);
                }
            } else {
                setSelectedAddress(pharmacySingle.branches[0] && pharmacySingle.branches[0]._id);
                setSelectedAddressIndex(0);
            }
        }
    }, [pharmacySingle]);
    console.log('selectedAddress', selectedAddress);

    const handleCancel = () => {
        setModalOpen(false);
    };

    const addReview = async () => {
        let obj = {
            rating: rating,
            reviewer: props.auth.user._id,
            reviewerType: props.auth.user.userType,
            reviewedUser:
                props.pharmacy && pharmacySingle && pharmacySingle._id && pharmacySingle._id,
            reviewedUserType:
                props.pharmacy &&
                pharmacySingle &&
                pharmacySingle.userType &&
                pharmacySingle.userType,
            description: description,
            shortDescription: shortDescription
        };
        let postedReview = await props.reviewActions.postReview({ ...props, obj }, props.history);
        if (postedReview) {
            setModalOpen(false);
        }
    };

    const sendMessage = async () => {
        if (!message) {
            message.error('Message field cannot be empty');
        } else {
            let obj = {
                message: message,
                receiver:
                    props.pharmacy && pharmacySingle && pharmacySingle._id && pharmacySingle._id,
                receiverType:
                    props.pharmacy &&
                    pharmacySingle &&
                    pharmacySingle.userType &&
                    pharmacySingle.userType
            };
            let sendMessage = await props.messagingActions.createConversation(
                { ...props, obj },
                props.history
            );
            if (sendMessage) {
                setMessageModal(false);
                setMessage('');
            } else {
                console.log('error');
            }
        }
    };

    let topBarStyle = {};
    if (marketingInfo && marketingInfo.topBarContent) {
        if (marketingInfo.topBarContent.backgroundColor) {
            topBarStyle.backgroundColor = marketingInfo.topBarContent.backgroundColor;
        }
    }

    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };

    const makeCall = async () => {
        window.open(
            `tel:${pharmacySingle && pharmacySingle.countryCode && pharmacySingle.countryCode}${
                pharmacySingle && pharmacySingle.phoneNo && pharmacySingle.phoneNo
            }`
        );
    };
    const goToSite = async () => {
        if (pharmacySingle && pharmacySingle.website) {
            window.open(
                `https://${pharmacySingle && pharmacySingle.website && pharmacySingle.website}`
            );
        }
    };

    const lines = ['title', 'header', 'grabber', 'line1', 'line2', 'line3', 'line4', 'line5'];

    const renderLines = (content) => {
        return lines.map((line) => {
            if (content[line]) {
                return (
                    <p
                        style={{
                            ...(content?.[line].style || {}),
                            padding: '10px 0px',
                            textAlign: 'center'
                        }}
                    >
                        {content?.[line]?.text}
                    </p>
                );
            }
        });
    };

    return (
        <div className='hospital-view-background'>
            {pharmacySingle && (
                <div className='hospital-view-main'>
                    <div className='hospital-view-header' style={{ backgroundColor: '#45536e' }}>
                        <div className='hospital-view-header-container'>
                            <div className='hospital-view-name'>{pharmacySingle.pharmacyName}</div>
                            <div className='hospital-view-heder-right'>
                                <div className='hospital-view-section'>
                                    <div
                                        className='hospital-view-sec'
                                        onClick={() => handleTab('blogs')}
                                    >
                                        <p>
                                            {pharmacyStatCounts && pharmacyStatCounts.posts
                                                ? pharmacyStatCounts.posts
                                                : 0}{' '}
                                            Posts
                                        </p>
                                    </div>
                                    <div className='hospital-view-sec'>
                                        <p>
                                            {pharmacyStatCounts && pharmacyStatCounts.followers
                                                ? pharmacyStatCounts.followers
                                                : 0}{' '}
                                            Followers
                                        </p>
                                    </div>
                                    <div className='hospital-view-sec'>
                                        <p>
                                            {pharmacyStatCounts && pharmacyStatCounts.likes
                                                ? pharmacyStatCounts.likes
                                                : 0}{' '}
                                            Likes
                                        </p>
                                    </div>
                                    <div
                                        className='hospital-view-sec'
                                        onClick={() => handleTab('reviews')}
                                    >
                                        <p>
                                            {pharmacyStatCounts && pharmacyStatCounts.reviews
                                                ? pharmacyStatCounts.reviews
                                                : 0}{' '}
                                            Reviews
                                        </p>
                                    </div>
                                </div>
                                <div className='hospital-view-social'>
                                    {pharmacySingle.shareSocial && (
                                        <>
                                            <i
                                                className={
                                                    pharmacySingle.social &&
                                                    pharmacySingle.shareSocial &&
                                                    pharmacySingle.social.instagram
                                                        ? `hospital-social-insta insta link fe fe-instagram`
                                                        : 'hospital-social-insta disabledIcon fe fe-instagram'
                                                }
                                                onClick={() => {
                                                    pharmacySingle.social &&
                                                        pharmacySingle.shareSocial &&
                                                        pharmacySingle.social.instagram &&
                                                        window.open(
                                                            `https://${
                                                                pharmacySingle.social &&
                                                                pharmacySingle.social.instagram &&
                                                                pharmacySingle.social.instagram
                                                            }`
                                                        );
                                                }}
                                            />

                                            <div
                                                onClick={() => {
                                                    pharmacySingle.social &&
                                                        pharmacySingle.shareSocial &&
                                                        pharmacySingle.social.twitter &&
                                                        window.open(
                                                            `https://${
                                                                pharmacySingle.social &&
                                                                pharmacySingle.social.twitter &&
                                                                pharmacySingle.social.twitter
                                                            }`
                                                        );
                                                }}
                                            >
                                                <FaXTwitter
                                                    className={
                                                        pharmacySingle.social &&
                                                        pharmacySingle.shareSocial &&
                                                        pharmacySingle.social.twitter
                                                            ? 'hospital-social-twit twit link fe fe-twitter'
                                                            : 'hospital-social-twit disabledIcon fe fe-twitter'
                                                    }
                                                    style={{ height: 14, width: 14 }}
                                                />
                                            </div>
                                            {
                                                <i
                                                    className={
                                                        pharmacySingle.social &&
                                                        pharmacySingle.shareSocial &&
                                                        pharmacySingle.social.linkedin
                                                            ? 'hospital-social-linked linkedin link fe fe-linkedin'
                                                            : 'hospital-social-linked disabledIcon fe fe-linkedin'
                                                    }
                                                    onClick={() => {
                                                        pharmacySingle.social &&
                                                            pharmacySingle.shareSocial &&
                                                            pharmacySingle.social.linkedin &&
                                                            window.open(
                                                                `https://${
                                                                    pharmacySingle.social &&
                                                                    pharmacySingle.social
                                                                        .linkedin &&
                                                                    pharmacySingle.social.linkedin
                                                                }`
                                                            );
                                                    }}
                                                />
                                            }

                                            <i
                                                className={
                                                    pharmacySingle.social &&
                                                    pharmacySingle.shareSocial &&
                                                    pharmacySingle.social.facebook
                                                        ? 'hospital-social-fb fb link fe fe-facebook'
                                                        : 'hospital-social-fb disabledIcon fe fe-facebook'
                                                }
                                                onClick={() => {
                                                    pharmacySingle.social &&
                                                        pharmacySingle.shareSocial &&
                                                        pharmacySingle.social.facebook &&
                                                        window.open(
                                                            `https://${
                                                                pharmacySingle.social &&
                                                                pharmacySingle.social.facebook &&
                                                                pharmacySingle.social.facebook
                                                            }`
                                                        );
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hospital-upper'>
                        {marketingInfo && marketingInfo.displayTopBar ? (
                            <div className='doctor-view-cover-split'>
                                <div className='hospital-view-img-split'>
                                    {pharmacySingle.coverImage ? (
                                        <img
                                            src={
                                                pharmacySingle.coverImage
                                                    ? pharmacySingle.coverImage
                                                    : placeholderCover
                                            }
                                        />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <div
                                    className='hospital-view-img-split2'
                                    style={
                                        (marketingInfo.topBarContentType != 'image' ||
                                            marketingInfo.topBarContentType != 'Image') &&
                                        marketingInfo.topBarContent.background && {
                                            backgroundColor: marketingInfo.topBarContent.background
                                        }
                                    }
                                >
                                    {marketingInfo.topBarContentType == 'image' ||
                                    marketingInfo.topBarContentType == 'Image' ? (
                                        <img
                                            src={
                                                marketingInfo.topBarContent &&
                                                marketingInfo.topBarContent.image
                                                    ? marketingInfo.topBarContent.image
                                                    : placeholderCover
                                            }
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                ...topBarStyle,
                                                padding: 10,
                                                width: '100%',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {renderLines(marketingInfo.topBarContent)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className='hospital-view-img'>
                                <img
                                    src={
                                        pharmacySingle.coverImage
                                            ? pharmacySingle.coverImage
                                            : placeholderCover
                                    }
                                />
                            </div>
                        )}

                        <div className='hospital-profile-container'>
                            <div className='hospital-upper-left'>
                                <div className='hospital-image-container'>
                                    <div className='hospital-veiw-image'>
                                        <img
                                            src={
                                                pharmacySingle.profileImage
                                                    ? pharmacySingle.profileImage
                                                    : profilePlaceholderImg
                                            }
                                            alt='proPic'
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LowerSection
                        openMessageModal={() => setMessageModal(true)}
                        openReviewModal={() => setModalOpen(true)}
                        hospital={pharmacySingle}
                        index={selectedAddressIndex}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddress={selectedAddress}
                        selectedAddressIndex={selectedAddressIndex}
                        setSelectedAddressIndex={setSelectedAddressIndex}
                        id={id}
                        tab={tab}
                        setTab={setTab}
                        {...props}
                    />

                    {isAuthenticated && (
                        <>
                            <MessageModal
                                messageModal={messageModal}
                                setMessageModal={setMessageModal}
                                message={message}
                                setMessage={setMessage}
                                sendMessage={sendMessage}
                            />

                            <ReviewModal
                                modalOpen={modalOpen}
                                handleCancel={handleCancel}
                                rating={rating}
                                setRating={setRating}
                                shortDescription={shortDescription}
                                setShortDescription={setShortDescription}
                                description={description}
                                setDescription={setDescription}
                                addReview={addReview}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state?.auth,
    profile: state?.auth?.userProfile,
    pharmacy: state?.pharmacy,
    pharmacySingle: state?.pharmacy?.pharmacySingle,
    pharmacyStatCounts: state?.pharmacy.pharmacyStatCounts,
    marketing: state?.marketing,
    marketingInfo: state?.marketing?.marketingInfo
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(pharmacyActions, dispatch),
    reviewActions: bindActionCreators(reviewActions, dispatch),
    messagingActions: bindActionCreators(messagingActions, dispatch),
    marketingActions: bindActionCreators(marketingActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(PharmacyView));
