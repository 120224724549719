import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getDoctors = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getDoctors`, props.obj)
            .then((res) => {
                if (res) {
                    if (props.obj.featured) {
                        dispatch({
                            type: Types.GET_FEATURED_DOCTORS,
                            payload: {
                                doctors: res.data.doctors
                            }
                        });
                    } else {
                        dispatch({
                            type: Types.GET_DOCTORS,
                            payload: {
                                doctors: res.data.doctors
                            }
                        });
                    }

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (props.obj.featured) {
                    dispatch({
                        type: Types.GET_FEATURED_DOCTORS,
                        payload: {
                            doctors: []
                        }
                    });
                } else {
                    dispatch({
                        type: Types.GET_DOCTORS,
                        payload: {
                            doctors: []
                        }
                    });
                }
                if (error && error.response) {
                    return resolve(false);
                }
            });
    });
};
export const getDoctorById = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/doctors/getDoctorDetail/${props.doctorId}`)
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_SINGLE_DOCTOR,
                        payload: {
                            doctorSingle: res.data.doctorSingle
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    history.push('/notFound');
                    return resolve(false);
                }
            });
    });
};

export const getDoctorSearchResults = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getDoctorSearchResults`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_DOCTOR_SEARCH_RESULTS,
                        payload: {
                            doctors: res.data.doctors,
                            doctorsCount: res.data.doctorsCount
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_DOCTOR_SEARCH_RESULTS,
                        payload: {
                            doctors: [],
                            doctorsCount: 0
                        }
                    });
                    message.error('Doctors retrieval failed');
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getDoctorStatCounts = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getDoctorStatCounts`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_DOCTOR_STAT_COUNTS,
                        payload: {
                            counts: res.data.counts
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getExtractedTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    if (props.obj.self) {
                        dispatch({
                            type: Types.GET_SELF_TIME_SLOTS,
                            payload: {
                                timeSlots: res.data.timeSlots,
                                availableDays: res.data.availableDays,
                                availability: res.data.doctorAvailability
                            }
                        });
                    } else {
                        dispatch({
                            type: Types.GET_DOCTOR_EXTRACTED_TIME_SLOTS,
                            payload: {
                                availableDays: res.data.availableDays,
                                timeSlots: res.data.timeSlots,
                                doctorAvailability: res.data.doctorAvailability
                            }
                        });
                    }

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_DOCTOR_EXTRACTED_TIME_SLOTS,
                        payload: {
                            timeSlots: [],
                            doctorAvailability: {}
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getDoctorForAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getDoctorForAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_DOCTOR_FOR_APPOINTMENT,
                        payload: {
                            doctor: res.data.doctor
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getWeeklyStatistics = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getWeeklyStatistics`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MY_WEEKLY_STATISTICS,
                        payload: {
                            weeklyStatistics: res.data.statistics
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    dispatch({
                        type: Types.GET_MY_WEEKLY_STATISTICS,
                        payload: {
                            weeklyStatistics: []
                        }
                    });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getMyServices = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getMyServices`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MY_SERVICES,
                        payload: {
                            services: res.data.services
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    dispatch({
                        type: Types.GET_MY_SERVICES,
                        payload: {
                            services: []
                        }
                    });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};



export const getDoctorDashboardInfo = (userType, history) => async (dispatch) => {
    try {
        const response = await Axios.get(`${REACT_APP_API_URI}/api/doctors/getDoctorDashboard`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        });
        console.log('got here', userType,Types[`GET_${userType}_DASHBOARD`])
        dispatch({
            type: Types[`GET_${userType}_DASHBOARD`],
            payload: {
                dashboard: response.data.data
            }
        });
        return true;
    } catch (error) {
        if (error && error.response) {
            console.log(error.response.data);
            message.error(
                error.response.data.message ? error.response.data.message : 'Server error'
            );
            // message.error(error.response.data.message[0].messages[0].message);
            return false;
        }
    }
};