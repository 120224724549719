import * as Types from '../actions/types';

const init = {
    dashboard: {
        practiceAreas: 0,
        doctors: 0,
        members: 0,
        branches: 0,
        hospitalServices: 0,
        roles: 0,
        appointments: 0
    },
    hospitals: [],
    hospitalSingle: {},
    hospitalSingleDoctors: [],
    hospitalSingleDoctorsCount: 0,
    organizationList: [],
    createdDoctor: {},
    members: [],
    doctors: [],
    doctorsCount: 0,
    membersCount: 0,
    featuredHospitals: [],
    shortMemberList: [],
    hospitalForAppointment: {
        hospital: {},
        timeSlots: [],
        availableDays: [],
        availability: {}
    },
    myDoctors: {
        timeSlots: [],
        availability: {}
    },
    searchResults: {
        hospitals: [],
        hospitalsCount: 0,
        hospitalsSuggestions: []
    },
    hospitalStatCounts: {
        posts: 0,
        likes: 0,
        reviews: 0,
        followers: 0
    },
    lineChart: null,
    pieChart: []
};

const hospitalReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_HOSPITALS: {
            return {
                ...state,
                hospitals: action.payload.hospitals
            };
        }
        case Types.GET_FEATURED_HOSPITALS: {
            return {
                ...state,
                featuredHospitals: action.payload.hospitals
            };
        }
        case Types.GET_SINGLE_HOSPITAL: {
            return {
                ...state,
                hospitalSingle: action.payload.hospitalSingle
            };
        }
        case Types.GET_HOSPITAL_DOCTORS: {
            return {
                ...state,
                doctors: action.payload.doctors,
                doctorsCount: action.payload.doctorsCount
            };
        }
        case Types.GET_HOSPITAL_SINGLE_DOCTORS: {
            console.log(action.payload);
            return {
                ...state,
                hospitalSingleDoctors: action.payload.doctors,
                hospitalSingleDoctorsCount: action.payload.doctorsCount
            };
        }
        case Types.GET_HOSPITAL_MEMBERS: {
            return {
                ...state,
                members: action.payload.members,
                membersCount: action.payload.membersCount
            };
        }
        case Types.GET_HOSPITAL_SINGLE_MEMBERS: {
            return {
                ...state,
                hospitalSingleMembers: action.payload.members,
                hospitalSingleMembersCount: action.payload.membersCount
            };
        }
        case Types.CREATE_DOCTOR: {
            return {
                ...state,
                createdDoctors: action.payload.createdDoctors
            };
        }
        case Types.CLEAR_HOSPITALS: {
            return {
                ...state,
                hospitals: [],
                hospitalSingle: {},
                createdDoctor: {}
            };
        }
        case Types.GET_HOSPITAL_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    hospitals: action.payload.hospitals,
                    hospitalsCount: action.payload.hospitalsCount
                }
            };
        }
        case Types.CLEAR_HOSPITAL_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    hospitals: [],
                    hospitalsCount: 0
                }
            };
        }
        case Types.GET_HOSPITAL_STAT_COUNTS: {
            return {
                ...state,
                hospitalStatCounts: {
                    posts: action.payload.counts && action.payload.counts.posts,
                    likes: action.payload.counts && action.payload.counts.likes,
                    reviews: action.payload.counts && action.payload.counts.reviews,
                    followers: action.payload.counts && action.payload.counts.followers
                }
            };
        }
        case Types.GET_HOSPITAL_EXTRACTED_TIME_SLOTS: {
            return {
                ...state,
                hospitalForAppointment: {
                    ...state.hospitalForAppointment,
                    availableDays: action.payload.availableDays,
                    timeSlots: action.payload.timeSlots,
                    availability: action.payload.hospitalAvailability
                }
            };
        }
        case Types.GET_HOSPITAL_FOR_APPOINTMENT: {
            return {
                ...state,
                hospitalForAppointment: {
                    ...state.hospitalForAppointment,
                    hospital: action.payload.hospital
                }
            };
        }

        case Types.CLEAR_APPOINTMENT_MEMBER: {
            return {
                ...state,
                hospitalForAppointment: {
                    timeSlots: [],
                    availability: {},
                    hospital: {}
                }
            };
        }

        case Types.GET_MY_DOCTOR_TIME_SLOTS: {
            return {
                ...state,
                myDoctors: {
                    timeSlots: action.payload.timeSlots,
                    availability: action.payload.doctorAvailability
                }
            };
        }
        case Types.GET_SHORT_MEMBER_LIST: {
            return {
                ...state,
                shortMemberList: action.payload.members
            };
        }

        case Types.GET_LINE_CHART_DATA: {
            return {
                ...state,
                lineChart: action.payload.lineChart
            };
        }
        case Types.GET_PIE_CHART_DATA: {
            return {
                ...state,
                pieChart: action.payload.pieChart
            };
        }

        case Types.GET_HOSPITAL_DASHBOARD: {
            return {
                ...state,
                dashboard: action.payload.dashboard
            };
        }
        case Types.GET_ORGANIZATION_MATCH: {
            return {
                ...state,
                organizationList: action.payload.organizationList
            }
        }
        default:
            return state;
    }
};

export default hospitalReducer;
