import React, { useState, useEffect } from 'react';
import '../../assets/css/auth.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import Logo from '@/assets/images/logo.png';
import { Modal, Form, Input, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import userTypes from '../../assets/json/UserTypes';

const ForgotPass = (props) => {
    const { userTypeProp, open, closeForgotPass, setUserTypeProp } = props;
    const [form] = Form.useForm();

    const [userType, setUserType] = useState(userTypeProp ? userTypeProp : 'PATIENT');

    const submitForm = async (e) => {
        e.preventDefault();
        const email = form.getFieldValue('email');

        form.validateFields().then(async (values) => {
            let obj = {
                email: email,
                userType: userType
            };
            props.actions.forgotPassword({ ...props, obj }, props.history);
        });
    };
    const changeUserType = (e) => {
        setUserType(e.target.value);
        if (setUserTypeProp) {
            setUserTypeProp(e.target.value);
        }
    };
    return (
        <Modal open={open} centered destroyOnClose onCancel={closeForgotPass} footer={null}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <img src={Logo} alt='logo' style={{ width: '8rem' }} />
                </div>
            </div>
            <div className='userType'>
                {userTypes.map((item) => (
                    <button
                        key={item.value}
                        value={item.value}
                        onClick={changeUserType}
                        disabled={item.disabled}
                        className={`${userType === item.value && 'active'}`}
                    >
                        {item.label}
                    </button>
                ))}
            </div>
            <h3 style={{ marginTop: 20 }}>Forgot Password</h3>

            <h5 style={{ marginTop: 5, fontSize: 14, color: 'gray' }}>
                Enter your email address below and we'll send you a link to reset your password.
            </h5>
            <div className='form'>
                <Form form={form} initialValues={{ remember: true }}>
                    <Form.Item
                        name='email'
                        rules={[
                            { required: true, message: 'Please provide your email.' },
                            {
                                type: 'email',
                                message: 'Please provide a valid email address.'
                            }
                        ]}
                        style={{ fontSize: 12 }}
                    >
                        <Input
                            prefix={<MailOutlined className='site-form-item-icon' />}
                            placeholder='Email Address'
                        />
                    </Form.Item>
                </Form>
                <Button style={{ width: '100%' }} type='primary' onClick={submitForm}>
                    Send Request
                </Button>
            </div>

            <div
                className='btm-links-login'
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <p style={{ fontSize: 12, cursor: 'initial' }}>
                    <span
                        style={{ fontSize: 12, cursor: 'pointer', fontWeight: 'bold', color: 'var(--primary)' }}
                        onClick={() => {
                            props.closeForgotPass();
                            props.signInModal();
                        }}
                    >
                        Sign in&nbsp;
                    </span>
                    to your account
                </p>
                <p style={{ cursor: 'initial', fontSize: 12 }}>
                    Don't have an account?
                    <span
                        style={{ fontSize: 12, cursor: 'pointer', fontWeight: 'bold', color: 'var(--primary)' }}
                        onClick={() => {
                            props.closeForgotPass();
                            props.signUpModal();
                        }}
                    >
                        &nbsp;Sign Up
                    </span>
                </p>
            </div>
        </Modal>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);
