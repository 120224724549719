import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Input, Button, Form, message, Checkbox, Alert } from 'antd';
import '../../assets/css/auth.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Logo from '@/assets/images/logo.png';
import googleClient from '../../assets/json/GoogleAuth.json';
import { facebookKeys } from '../../assets/Keys';
import GoogleIcon from '../../assets/img/svgs/GoogleIcon';
import MicrosoftLogin from '../MiniComponents/MicrosoftLogin';
import { MailOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from '@ant-design/icons';
import PhoneIcon from '../../assets/img/svgs/PhoneIcon';
import FacebookIcon from '../../assets/img/svgs/FacebookIcon';
import MicrosoftIcon from '../../assets/img/svgs/MicrosoftIcon';
// 6686bce7-4838-4fb8-8269-0c2e3005281d
import userTypes from '../../assets/json/UserTypes';
function extractEmailAddress(str) {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const matches = str.match(emailRegex);

    if (matches && matches.length > 0) {
        return matches[0];
    } else {
        return null;
    }
}
const SignIn = (props) => {
    const { client_id: googleClientId } = googleClient && googleClient.web;
    const {
        history,
        userTypeProp,
        emailVerifiedMsg,
        setEmailVerifiedMsg,
        signUpModal,
        phoneSignInModal,
        setUserTypeProp,
        closeLogin,
        forgotPassModal,
        open
    } = props;
    const userEmailVerificationRef = React.useRef(null);
    const [form] = Form.useForm();
    const [checkMessage, setCheckMessage] = useState('');
    const [hideMsg, setHideMsg] = useState(true);
    const [checkBox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);

    const [userType, setUserType] = useState(userTypeProp ? userTypeProp : 'PATIENT');

    const fetchCredsFromLocalStorage = () => {
        let creds = JSON.parse(
            localStorage.getItem(
                userType === 'PATIENT'
                    ? 'clientCreds'
                    : userType === 'DOCTOR'
                    ? 'doctorCreds'
                    : userType === 'HOSPITAL' && 'lawfirmCreds'
            )
        );
        if (creds?.email) {
            form.setFieldValue('email', creds.email);
        } else {
            form.setFieldValue('email', '');
        }
        if (creds?.password) {
            form.setFieldValue('password', creds.password);
        } else {
            form.setFieldValue('password', '');
        }
    };

    const setCredsInLocalStorage = () => {
        console.log(form);
        const { email: emailValue, password: passwordValue } = form.getFieldsValue([
            'email',
            'password'
        ]);
        let creds =
            userType === 'PATIENT'
                ? 'clientCreds'
                : userType === 'DOCTOR'
                ? 'doctorCreds'
                : userType === 'HOSPITAL' && 'lawfirmCreds';
        if (checkBox) {
            localStorage.setItem(
                creds,
                JSON.stringify({
                    email: emailValue,
                    password: passwordValue
                })
            );
            let saveCreds = JSON.parse(localStorage.getItem('saveCreds'));
            let toBeSaved = { ...saveCreds };
            if (userType === 'PATIENT') {
                toBeSaved.client = true;
            }
            if (userType === 'DOCTOR') {
                toBeSaved.doctor = true;
            }
            if (userType === 'HOSPITAL') {
                toBeSaved.lawfirm = true;
            }
            localStorage.setItem('saveCreds', JSON.stringify(toBeSaved));
        } else {
            localStorage.removeItem(creds);
            let saveCreds = JSON.parse(localStorage.getItem('saveCreds'));
            let toBeSaved = { ...saveCreds };
            if (userType === 'PATIENT') {
                toBeSaved.client = false;
            }
            if (userType === 'DOCTOR') {
                toBeSaved.doctor = false;
            }
            if (userType === 'HOSPITAL') {
                toBeSaved.lawfirm = false;
            }
            localStorage.setItem('saveCreds', JSON.stringify(toBeSaved));
        }
    };

    useEffect(() => {
        if (setUserTypeProp) {
            setUserTypeProp(userType);
        }
        let saveCreds = JSON.parse(localStorage.getItem('saveCreds'));
        const emailParam = new URLSearchParams(window.location.search).get('email');
        if (!emailParam) {
            if (userType === 'PATIENT') {
                if (saveCreds?.client) {
                    setCheckbox(true);
                    fetchCredsFromLocalStorage();
                } else {
                    fetchCredsFromLocalStorage();
                    setCheckbox(false);
                }
            }
            if (userType === 'DOCTOR') {
                if (saveCreds?.doctor) {
                    setCheckbox(true);
                    fetchCredsFromLocalStorage();
                } else {
                    fetchCredsFromLocalStorage();
                    setCheckbox(false);
                }
            }
            if (userType === 'HOSPITAL') {
                if (saveCreds?.lawfirm) {
                    setCheckbox(true);
                    fetchCredsFromLocalStorage();
                } else {
                    fetchCredsFromLocalStorage();
                    setCheckbox(false);
                }
            }
        } else {
            form.setFieldValue('email', emailParam);
        }
    }, [userType]);

    const handleCheckbox = (e) => {
        setCheckbox(e.target.checked);
        setCheckMessage('');
    };

    const changeUserType = (e) => {
        setUserType(e.target.value);
        if (setUserTypeProp) {
            setUserTypeProp(e.target.value);
        }
    };

    const responseFacebook = (response) => {
        console.log(response);
        const data = {
            accessToken: response.accessToken,
            userID: response.userID,
            userType
        };
        props.actions.facebookLogin(data, history);
    };

    const responseGoogle = (response) => {
        console.log(response);
        if (!response.error) {
            const data = { idToken: response.tokenId, userType };
            props.actions.googleLogin(data, history);
        }
    };
    const responseMicrosoft = (response) => {
        console.log(response);
        if (response) {
            const data = {
                name: response.account.name,
                userType,
                email: response.account.userName
            };
            props.actions.microsoftLogin(data, history);
        }
    };
    const { errors } = props.auth;

    const submitForm = async (values) => {
        setLoading(true);
        await props.actions.clearErrors();
        form.validateFields()
            .then(async (values) => {
                let user = {
                    email: values.email,
                    password: values.password,
                    userType: userType
                };
                let response = await props.actions.login({ ...props, user }, history);
                setLoading(false);
                if (response) {
                    setCredsInLocalStorage();
                    closeLogin();
                }
                setTimeout(() => {
                    form.resetFields();
                }, 200);
            })
            .catch((data) => {
                if (data) {
                    userEmailVerificationRef.current = data;
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const sendVerificationEmail = async () => {
        setLoading(true);
        await props.actions.clearErrors();
        try {
            const extractedEmail = emailVerifiedMsg && extractEmailAddress(emailVerifiedMsg);

            if (!userEmailVerificationRef?.current?.email && !extractedEmail) {
                return;
            }
            let obj = {
                email: userEmailVerificationRef?.current?.email || extractedEmail,
                userType: userEmailVerificationRef?.current?.userType || userType
            };
            await props.actions.resendVerificationEmail({ ...props, obj }, history);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            open={open}
            centered
            destroyOnClose
            onCancel={closeLogin}
            footer={null}
            className='auth-modal'
        >
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <img src={Logo} alt='logo' style={{ width: '8rem' }} />
                    </div>
                </div>
                <div className='userType'>
                    {userTypes.map((item) => (
                        <button
                            key={item.value}
                            value={item.value}
                            disabled={item.disabled}
                            onClick={changeUserType}
                            className={`${userType === item.value && 'active'}`}
                        >
                            {item.label}
                        </button>
                    ))}
                </div>
                <h3 style={{ textAlign: 'left', marginBottom: 15 }}>Sign into your account</h3>
                <div className='form'>
                    <Form form={form} initialValues={{ remember: true }}>
                        <Form.Item
                            name='email'
                            rules={[
                                { required: true, message: 'Please provide your email.' },
                                {
                                    type: 'email',
                                    message: 'Please provide a valid email address.'
                                }
                            ]}
                            style={{ fontSize: 12 }}
                        >
                            <Input
                                prefix={<MailOutlined className='site-form-item-icon' />}
                                placeholder='Email Address'
                            />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            rules={[
                                { required: true, message: 'Please provide your password!' },
                                { min: 6, message: 'Please provide a strong password!' }
                            ]}
                        >
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                prefix={<LockOutlined className='site-form-item-icon' />}
                                type='password'
                                placeholder='Password'
                            />
                        </Form.Item>
                    </Form>
                    {emailVerifiedMsg && (
                        <Alert
                            message={
                                <>
                                    {emailVerifiedMsg}{' '}
                                    {emailVerifiedMsg.includes(
                                        'verification email has been sent'
                                    ) && (
                                        <span
                                            style={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                color: 'var(--primary)'
                                            }}
                                            to='#'
                                            onClick={sendVerificationEmail}
                                        >
                                            &nbsp;Resend Email Verification
                                        </span>
                                    )}
                                </>
                            }
                            type='info'
                            style={{ fontSize: 12, marginBottom: 10 }}
                        />
                    )}
                    {errors?.message && (
                        <Alert
                            message={
                                <>
                                    {errors?.message}
                                    {errors.message.includes(
                                        'verification email has been sent'
                                    ) && (
                                        <span
                                            style={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                color: 'var(--primary)'
                                            }}
                                            to='#'
                                            onClick={sendVerificationEmail}
                                        >
                                            &nbsp;Resend Email Verification
                                        </span>
                                    )}
                                </>
                            }
                            type='error'
                            style={{ fontSize: 12, marginBottom: 10 }}
                        />
                    )}

                    <h3 style={{ textAlign: 'left', marginBottom: 15 }}>Or Sign in with</h3>
                    <div
                        style={{
                            display: 'flex',
                            gap: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 10
                        }}
                    >
                        <Button
                            type='text'
                            onClick={() => {
                                closeLogin();
                                phoneSignInModal();
                            }}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <PhoneIcon />
                        </Button>

                        <FacebookLogin
                            // appId="1453893171475138"
                            appId={facebookKeys.appId}
                            autoLoad={false}
                            fields='name,email,picture'
                            callback={(response) => responseFacebook(response)}
                            redirectUri={'https://Medasq-frontend-inevex.herokuapp.com/'}
                            render={(renderProps) => (
                                <Button
                                    type='text'
                                    className='social_btn'
                                    onClick={renderProps.onClick}
                                >
                                    <FacebookIcon />
                                </Button>
                            )}
                        />

                        <GoogleLogin
                            clientId={googleClientId}
                            onSuccess={(response) => responseGoogle(response)}
                            onFailure={(response) => responseGoogle(response)}
                            className='googleBtn'
                            // accessType="offline"
                            // responseType="code"
                            // approvalPrompt="force"
                            // prompt='consent'
                            isSignedIn={false}
                            render={(renderProps) => (
                                <Button type='text' onClick={renderProps.onClick}>
                                    <GoogleIcon />
                                </Button>
                            )}
                        />

                        <MicrosoftLogin
                            clientId={'6686bce7-4838-4fb8-8269-0c2e3005281d'}
                            authCallback={(response) => responseMicrosoft(response)}
                            prompt={'consent'}
                            graphScopes={['profile']}
                            redirectUri={'https://Medasq-frontend-inevex.herokuapp.com'}
                            children={
                                <Button type='text'>
                                    <MicrosoftIcon />
                                </Button>
                            }
                        />
                    </div>

                    <Checkbox
                        style={{ marginBottom: 10, fontSize: 12 }}
                        checked={checkBox}
                        onChange={handleCheckbox}
                    >
                        Save Credentials
                    </Checkbox>
                    <div>
                        <Button
                            type='primary'
                            style={{ width: '100%' }}
                            loading={loading}
                            onClick={submitForm}
                        >
                            Sign In
                        </Button>
                    </div>
                    {checkMessage && !hideMsg ? (
                        <div
                            style={{
                                color: 'red',
                                fontSize: '6.rem',
                                textAlign: 'center',
                                paddingTop: '1rem'
                            }}
                        >
                            Click the agrrement for authentication
                        </div>
                    ) : null}
                </div>

                <div
                    className='btm-links-login'
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <p
                        style={{
                            fontSize: 12,
                            cursor: 'initial'
                        }}
                    >
                        Forgot Password?
                        <span
                            style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                color: 'var(--primary)'
                            }}
                            to='#'
                            onClick={() => {
                                setEmailVerifiedMsg('');
                                forgotPassModal();
                            }}
                        >
                            &nbsp;Reset
                        </span>
                    </p>

                    <p
                        style={{
                            fontSize: 12,
                            cursor: 'initial'
                        }}
                    >
                        Don't have an account?
                        <Link
                            to='#'
                            style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                color: 'var(--primary)'
                            }}
                            onClick={() => {
                                closeLogin();
                                setEmailVerifiedMsg('');
                                signUpModal();
                            }}
                        >
                            &nbsp;Sign Up
                        </Link>
                    </p>
                </div>
            </div>
        </Modal>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
