import * as Types from '../actions/types';
const init = {
    user: {
        hospitalBranches: []
    },
    userId: null,
    userType: null,
    isAuthenticated: false,
    errors: {},
    profileError: '',
    profileCompletionError: '',
    countryInfo: {},
    userProfile: {},
    packages: [],
    searchedUsers: [],
    favourites: {
        blogs: [],
        blogsCount: 0,
        doctors: [],
        doctorsCount: 0,
        consultants: [],
        consultantsCount: 0,
        nurses: [],
        nursesCount: 0,
        pharmacies: [],
        pharmaciesCount: 0,
        hospitals: [],
        hospitalsCount: 0
    },
    notifications: [],
    notificationsCount: 0,
    addedToFavourites: [],
    savedProfile: {},
    productTypes: [],
    stripe: {
        isStripeVerified: false,
        stripeBalance: null
    },
    authModals: {
        signInModal: false,
        signUpModal: false,
        signInWithPhoneModal: false,
        signUpWithPhoneModal: false,
        forgotPassModal: false,
        resetPassModal: false,
        regProp: false,
        emailVerifiedMsg: '',
        modalUserType: 'PATIENT'
    },
    invoices: [],
    upcomingInvoice: {}
};

const authReducer = (state = init, action) => {
    switch (action.type) {
        case Types.SET_USER: {
            return {
                ...state,
                user: action.payload.user,
                userId: action.payload.user._id,
                userType: action.payload.user.userType,
                isAuthenticated: Object.keys(action.payload.user).length !== 0
            };
        }

        case Types.PROFILE_COMPLETION_ERROR: {
            return {
                ...state,
                profileError: action.payload.profileError
            };
        }
        case Types.CLEAR_PROFILE_COMPLETION_ERROR: {
            return {
                ...state,
                profileError: ''
            };
        }
        case Types.SET_COUNTRY_INFO: {
            return {
                ...state,
                countryInfo: action.payload.countryInfo
            };
        }
        case Types.USER_ERRORS: {
            return {
                ...state,
                errors: action.payload.error,
                authModals: {
                    ...state.authModals,
                    emailVerifiedMsg: ""
                }
            };
        }
        case Types.CLEAR_ERRORS: {
            return {
                ...state,
                errors: {}
            };
        }
        case Types.CREATE_USER: {
            return {
                ...state,
                createdUser: action.payload.createdUser
            };
        }
        case Types.FORGOT_PASSWORD: {
            return {
                ...state
            };
        }
        case Types.SEND_OTP: {
            return {
                ...state,
                data: action.payload.data,
                message: action.payload.message
            };
        }

        case Types.VERIFY_OTP: {
            return {
                ...state,
                data: action.payload.data,
                message: action.payload.message
            };
        }

        case Types.SAVE_PROFILE: {
            return {
                ...state,
                savedProfile: action.payload.savedProfile
            };
        }
        case Types.GET_PACKAGES: {
            return {
                ...state,
                packages: action.payload.packages
            };
        }

        case Types.GET_PROFILE: {
            return {
                ...state,
                user: { ...state.user, ...action.payload.retrievedProfile },
                userProfile: action.payload.retrievedProfile
            };
        }
        case Types.GET_FAVOURITES: {
            let obj = {};
            if (action.payload.favouriteType == 'Blogs') {
                obj.blogs = action.payload.blogs;
                obj.blogsCount = action.payload.blogsCount;
            }
            if (action.payload.favouriteType == 'Hospitals') {
                obj.hospitals = action.payload.hospitals;
                obj.hospitalsCount = action.payload.hospitalsCount;
            }
            if (action.payload.favouriteType == 'Doctors') {
                obj.doctors = action.payload.doctors;
                obj.doctorsCount = action.payload.doctorsCount;
            }
            return {
                ...state,
                favourites: {
                    ...state.favourites,
                    ...obj
                }
            };
        }
        case Types.GET_PRODUCT_TYPES: {
            return {
                ...state,
                productTypes: action.payload.productTypes
            };
        }
        case Types.ADD_TO_FAVOURITES: {
            return {
                ...state,
                addedToFavourites: action.payload.addedToFavourites
            };
        }
        case Types.GET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.payload.notifications,
                notificationsCount: action.payload.notificationsCount
            };
        }
        case Types.CHOOSE_FROM_USERS: {
            return {
                ...state,
                searchedUsers: action.payload.searchedUsers
            };
        }
        case Types.SET_SIGN_IN_MODAL: {
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    signInModal: action.payload.signInModal,
                    signUpModal: false,
                    signUpWithPhoneModal: false,
                    signInWithPhoneModal: false,
                    forgotPassModal: false,
                    resetPassModal: false
                }
            };
        }
        case Types.SET_SIGN_UP_MODAL: {
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    signInModal: false,
                    signUpModal: action.payload.signUpModal,
                    modalUserType: action.payload.modalUserType,
                    signUpWithPhoneModal: false,
                    forgotPassModal: false,
                    resetPassModal: false
                }
            };
        }
        case Types.SET_SIGN_IN_WITH_PHONE_MODAL: {
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    signInModal: false,
                    signUpModal: false,
                    signInWithPhoneModal: action.payload.signInWithPhoneModal,
                    signUpWithPhoneModal: false,
                    forgotPassModal: false,
                    resetPassModal: false
                }
            };
        }
        case Types.SET_SIGN_UP_WITH_PHONE_MODAL: {
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    signInModal: false,
                    signUpModal: false,
                    signInWithPhoneModal: false,
                    signUpWithPhoneModal: action.payload.signUpWithPhoneModal,
                    forgotPassModal: false,
                    resetPassModal: false
                }
            };
        }
        case Types.SET_FORGOT_PASS_MODAL: {
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    signInModal: false,
                    signUpModal: false,
                    signInWithPhoneModal: false,
                    signUpWithPhoneModal: false,
                    forgotPassModal: action.payload.forgotPassModal,
                    resetPassModal: false
                }
            };
        }
        case Types.SET_RESET_PASS_MODAL: {
            console.log('reset pass modal', action.payload.resetPassModal)
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    signInModal: false,
                    signUpModal: false,
                    signInWithPhoneModal: false,
                    signUpWithPhoneModal: false,
                    forgotPassModal: false,
                    resetPassModal: action.payload.resetPassModal
                }
            }
        }
        case Types.SET_MODAL_USERTYPE: {
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    modalUserType: action.payload.modalUserType
                }
            };
        }
        case Types.SET_REG_PROP: {
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    regProp: action.payload.regProp
                }
            };
        }
        case Types.SET_EMAIL_VERIFIED_MSG: {
            return {
                ...state,
                authModals: {
                    ...state.authModals,
                    emailVerifiedMsg: action.payload.emailVerifiedMsg
                }
            };
        }
        case Types.CLEAR_AUTH_MODALS_STATE: {
            return {
                ...state,
                authModals: {
                    signInModal: false,
                    signUpModal: false,
                    signInWithPhoneModal: false,
                    signUpWithPhoneModal: false,
                    forgotPassModal: false,
                    resetPassModal: false,
                    regProp: false,
                    emailVerifiedMsg: '',
                    modalUserType: "PATIENT"
                }
            };
        }
        case Types.IS_STRIPE_VERIFIED: {
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    isStripeVerified: action.payload.isStripeVerified
                },
                user: {
                    ...state.auth,
                    isStripeVerified: action.payload.isStripeVerified
                },
                userProfile: {
                    ...state.userProfile,
                    isStripeVerified: action.payload.isStripeVerified
                }
            };
        }

        case Types.GET_STRIPE_DASHBOARD: {
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    isStripeVerified: action.payload.isStripeVerified,
                    stripeBalance: action.payload.stripeBalance
                }
            };
        }
        case Types.LOGOUT: {
            return init;
        }

        case Types.GET_STRIPE_INVOICES: {
            return {
                ...state,
                invoices: action.payload.invoices,
                upcomingInvoice: action.payload.upcomingInvoice
            };
        }

        case Types.VERIFY_EMAIL_OR_PHONE_OTP: {
            return {
                ...state,
                user: {
                    ...state.user,
                    emailVerified: action.payload.emailVerified,
                    phoneVerified: action.payload.phoneVerified
                }
            };
        }

        default:
            return state;
    }
};

export default authReducer;
