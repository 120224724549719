import * as Types from '../actions/types';

const init = {
    dashboard: {
        practiceAreas: 0,
        doctors: 0,
        branches: 0,
        consultantServices: 0,
        roles: 0,
        appointments: 0,
        services: 0,
        appointmentsToday: [],
        pendingApprovals: 0,
        upcomingAppointments: 0,
        cancelledAppointments: 0,
        failedAppointments: 0,
        missedAppointments: 0,
        rejectedAppointments: 0,
        completedAppointments: 0,
        noShowAppointments: 0
    },
    caregivers: [],
    caregiverSingle: {},
    caregiverSingleDoctors: [],
    caregiverSingleDoctorsCount: 0,
    createdDoctor: {},
    doctors: [],
    featuredHospitals: [],
    doctorsCount: 0,
    shortMemberList: [],
    caregiverForAppointment: {
        caregiver: {},
        timeSlots: [],
        availableDays: [],
        availability: {}
    },
    myDoctors: {
        timeSlots: [],
        availability: {}
    },
    searchResults: {
        caregivers: [],
        caregiversCount: 0,
        caregiversSuggestions: []
    },
    caregiverStatCounts: {
        posts: 0,
        likes: 0,
        reviews: 0,
        followers: 0
    },
    lineChart: null,
    pieChart: []
};

const caregiverReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_CAREGIVERS: {
            return {
                ...state,
                caregivers: action.payload.caregivers
            };
        }
        case Types.GET_FEATURED_CAREGIVERS: {
            return {
                ...state,
                featuredHospitals: action.payload.caregivers
            };
        }
        case Types.GET_SINGLE_CAREGIVER: {
            return {
                ...state,
                caregiverSingle: action.payload.caregiverSingle
            };
        }

   
        case Types.CLEAR_CAREGIVERS: {
            return {
                ...state,
                caregivers: [],
                caregiverSingle: {},
                createdDoctor: {}
            };
        }
        case Types.GET_CAREGIVER_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    caregivers: action.payload.caregivers,
                    caregiversCount: action.payload.caregiversCount
                }
            };
        }
        case Types.CLEAR_CAREGIVER_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    caregivers: [],
                    caregiversCount: 0
                }
            };
        }
        case Types.GET_CAREGIVER_STAT_COUNTS: {
            return {
                ...state,
                caregiverStatCounts: {
                    posts: action.payload.counts && action.payload.counts.posts,
                    likes: action.payload.counts && action.payload.counts.likes,
                    reviews: action.payload.counts && action.payload.counts.reviews,
                    followers: action.payload.counts && action.payload.counts.followers
                }
            };
        }
        case Types.GET_CAREGIVER_EXTRACTED_TIME_SLOTS: {
            return {
                ...state,
                caregiverForAppointment: {
                    ...state.caregiverForAppointment,
                    availableDays: action.payload.availableDays,
                    timeSlots: action.payload.timeSlots,
                    availability: action.payload.availability
                }
            };
        }
        case Types.GET_CAREGIVER_FOR_APPOINTMENT: {
            return {
                ...state,
                caregiverForAppointment: {
                    ...state.caregiverForAppointment,
                    caregiver: action.payload.caregiver
                }
            };
        }


        case Types.GET_LINE_CHART_DATA: {
            return {
                ...state,
                lineChart: action.payload.lineChart
            };
        }
        case Types.GET_PIE_CHART_DATA: {
            return {
                ...state,
                pieChart: action.payload.pieChart
            };
        }

        case Types.GET_CAREGIVER_DASHBOARD: {
            return {
                ...state,
                dashboard: action.payload.dashboard
            };
        }
        default:
            return state;
    }
};

export default caregiverReducer;
