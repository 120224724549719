import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Select, Popover, Row, Col, Button } from 'antd';

const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
const sortby = ['Highest Rated', 'Newest Rated', 'Newest', 'Oldest'];

const sortOrder = ['Ascending', 'Descending'];

const AppointmentHeader = (props) => {
    const {
        tabList,
        setActiveTab,
        activeTab,
        setPageNumber,
        handleModal,
        history
    } = props;

    const [popover, setPopover] = useState(true);

    return (
        <Row gutter={[10, 10]} style={{ marginBottom: 20 }}>
            <Col xs={24} flex={1}>
                <Popover
                    overlayClassName={'favouritePopover'}
                    placement='topRight'
                    content={
                        <div
                            style={{
                                width: 300,
                                color: 'white',
                                fontSize: 11,
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '6px'
                            }}
                        >
                            Click the dropdown to select between different Management types:
                            Document Types
                            <button
                                style={{
                                    borderRadius: 20,
                                    backgroundColor: 'var(--primary)',
                                    color: 'white',
                                    padding: '3px 15px',
                                    margin: '5px 10px 5px auto',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setPopover(false)}
                            >
                                Got it
                            </button>
                        </div>
                    }
                    open={popover}
                >
                    <Select
                        value={activeTab}
                        placeholder='Tables'
                        onChange={(item) => {
                            setActiveTab(item);
                            history.push(`/pharmacy/management?tab=${encodeURIComponent(item)}`);
                            setPageNumber(1);
                        }}
                        style={{ width: '100%' }}
                    >
                        {tabList.map((item, index) => (
                            <Option key={index} value={item}>
                                {item}
                            </Option>
                        ))}
                    </Select>
                </Popover>
            </Col>

            <Col xs={24} flex={1}>
                <Select placeholder='Sort By' showSearch style={{ width: '100%' }}>
                    {sortby.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Col xs={24} flex={1}>
                <Select placeholder='Sort Order' showSearch style={{ width: '100%' }}>
                    {sortOrder.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} lg={4}>
                <Button type='primary' onClick={handleModal} style={{ width: '100%' }}>
                    <PlusOutlined /> Add {activeTab.slice(0, -1)}
                </Button>
            </Col>
        </Row>
    );
};

export default AppointmentHeader;
