import CardRow from './CardRow';
import React, { useRef, useState, useEffect } from 'react';
import './FirstStage.css';
import './Card.css';
import { getLocationShorts } from '../../../../Utilities/LocationShorts';
import { useDispatch, useSelector } from 'react-redux';
import * as appointmentActions from '@/actions/appointmentActions';
import { Button, Divider, Select } from 'antd';
import { useLocation } from 'react-router-dom';

const memberTypes = [
    {
        title: 'Doctors',
        value: 'DOCTOR'
    },
    {
        title: 'Hospitals',
        value: 'HOSPITAL'
    },
    {
        title: 'Consultants',
        value: 'CONSULTANT'
    },
    {
        title: 'Caregivers',
        value: 'CAREGIVER'
    },
    {
        title: 'Therapists',
        value: 'THERAPIST'
    },
    {
        title: 'Nurses',
        value: 'NURSE'
    }
];

const FirstStage = (props) => {
    const memberRow = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const appointments = useSelector((state) => state.appointments);
    const { history, setPointer } = props;
    const { hospitalsAndDoctors, members } = appointments;

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const { doctors, hospitals } = hospitalsAndDoctors;

    const [tab, setTab] = useState('DOCTOR');
    const [selectedMemberType, setSelectedMemberType] = useState('DOCTOR');

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let tab = urlParams.get('tab');
        if (tab) {
            setSelectedMemberType(tab);
        } else {
            setSelectedMemberType('DOCTOR');
        }
    }, [location.search]);

    useEffect(() => {
        fetchPastAppointmentMembers();
    }, [selectedMemberType, page, limit]);

    const fetchPastAppointmentMembers = async () => {
        let location = await getLocationShorts();
        let obj = {
            page: page,
            perPage: limit,
            userId: auth.userId,
            memberType: selectedMemberType,
            ...location
        };

        dispatch(
            appointmentActions.getPastAppointmentMembers({
                ...props,
                obj
            })
        );
    };

    const getSearchPageLink = (memberType) => {
        console.log(memberType.toLowerCase());
        return `/${memberType.toLowerCase()}-results`;
    };
    return (
        <div className='firstStage'>
            <Select
                style={{
                    width: '100%',
                    maxWidth: 300
                }}
                placeholder='Select Service Provider'
                value={selectedMemberType}
                onChange={(val) => {
                    setSelectedMemberType(val);
                    history.push(`/patient/appointments/new?tab=${val}`);
                }}
            >
                {memberTypes.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                        {item.title}
                    </Select.Option>
                ))}
            </Select>

            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>
                        Choose{' '}
                        {`${
                            selectedMemberType.slice(0, 1) +
                            selectedMemberType.slice(1).toLowerCase()
                        }`}{' '}
                        from past appointments
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            gap: 10,
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            type='primary'
                            onClick={() =>
                                props.history.push(
                                    `/${selectedMemberType.substr(
                                        0,
                                        selectedMemberType.length - 1
                                    )}-results`
                                )
                            }
                        >
                            Search Now!
                        </Button>
                        <div className='arrowBtnRow' style={{ display: 'flex', columnGap: 10 }}>
                            <div className='arrowBtns'>
                                <div
                                    onClick={() => {
                                        if (memberRow && memberRow.current) {
                                            memberRow.current.scrollLeft += -300;
                                        }
                                    }}
                                >
                                    <i className='fe fe-arrow-left' />
                                </div>
                                <div
                                    onClick={() => {
                                        if (memberRow && memberRow.current) {
                                            memberRow.current.scrollLeft += 300;
                                        }
                                    }}
                                >
                                    <i className='fe fe-arrow-right' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CardRow
                    rowRef={memberRow}
                    data={members}
                    searchPageLink={getSearchPageLink(selectedMemberType)}
                    type={selectedMemberType}
                    {...props}
                />
            </div>
        </div>
    );
};

export default FirstStage;
