export const PharmacyBlogCategories = [
    'Career Advice for Pharmacy Students, Residents and New Pharmacists',
    'Clinical Guideline Updates For Pharmacists',
    'Clinical Pharmacy News and New Drug Updates',
    'Drug Interaction Case Studies and Education For Pharmacists',
    'Educational Case Studies For Pharmacists',
    'Cardiovascular Case Studies',
    'Dermatology, ENT, Ophthalmology Case Studies',
    'Endocrine Case Studies',
    'Gastrointestinal Case Studies',
    'Hematology and Immunology Case Studies',
    'Infectious Disease and Oncology Case Studies',
    'Neurology, Pain, and Musculoskeletal Case Studies',
    'Pharmacogenomics and Pharmacokinetics Case Studies',
    'Psychiatry and Addiction Medicine Case Studies',
    'Renal, Urology, and Electrolytes Case Studies',
    'Respiratory Case Studies',
    'Women’s and Men’s Health Case Studies',
    'Free Clinical Practice Quizzes and Videos For Pharmacists',
    'Long Term Care Consultant Pharmacist Education and Information',
    'Medication and Disease State Clinical Pearls For Pharmacists',
    'Cardiovascular Medication and Disease State Clinical Pearls',
    'Dermatology, ENT, Ophthalmology Medication and Disease State Clinical Pearls',
    'Endocrine Medication and Disease State Clinical Pearls',
    'Gastrointestinal Medication and Disease State Clinical Pearls',
    'Hematology and Immunology Medication and Disease State Clinical Pearls',
    'Infectious Disease and Oncology Medication and Disease State Clinical Pearls',
    'Neurology, Pain, and Musculoskeletal Medication and Disease State Clinical Pearls',
    'Pediatrics Medication and Disease State Clinical Pearls',
    'Pharmacogenomics and Pharmacokinetics Medication and Disease State Clinical Pearls',
    'Psychiatry and Addiction Medication and Disease State Clinical Pearls',
    'Renal, Urology, and Electrolytes Medication and Disease State Clinical Pearls',
    'Respiratory Medication and Disease State Clinical Pearls',
    'Women’s and Men’s Health Medication and Disease State Clinical Pearls',
    'Medication Errors',
    'Personal Stories From The Front Lines of Clinical Pharmacy',
    'Pharmacist Board Certification Information And Help',
    'BCACP Exam',
    'BCGP Exam',
    'BCMTMS Exam',
    'BCPS Exam',
    'MPJE',
    'NAPLEX',
    'Pharmacist Legislative Advocacy',
    'Pharmacy Technicians',
    'Polypharmacy Cases And The Prescribing Cascade',
    'Writing Medication Recommendations For Pharmacists'
];
