import { Divider, Tag } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime, Duration } from 'luxon';

const CurrentSubscription = () => {
    const { upcomingInvoice, userProfile, productTypes, invoices, userType } = useSelector(
        (state) => state.auth
    );
    const { featured, currentSubscribedPackage, currentSubscribedBillingCycle } = userProfile;

    const lastInvoiceDate =
        invoices.length > 0 && invoices[invoices.length - 1]?.createdAt
            ? DateTime.fromISO(invoices[invoices.length - 1].createdAt).toFormat('MMMM d, yyyy')
            : invoices.length > 0 && invoices[invoices.length - 1]?.created
            ? DateTime.fromMillis(invoices[invoices.length - 1].created * 1000).toFormat(
                  'MMMM d, yyyy'
              )
            : 'N/A';

    let upcomingInvoiceDate = upcomingInvoice.next_payment_attempt
        ? DateTime.fromMillis(upcomingInvoice.next_payment_attempt * 1000).toFormat('MMMM d, yyyy')
        : DateTime.fromFormat(lastInvoiceDate, 'MMMM d, yyyy')
              .plus({
                  year: 1
              })
              .toFormat('MMMM d, yyyy') || 'N/A';

    const FeaturedPrice = parseFloat(
        productTypes.find(
            (product) =>
                product.productName ===
                `Featured ${userType.slice(0, 1)}${userType.slice(1).toLowerCase()}`
        )?.productPrice
    );
    const BasePackagePrice = parseFloat(
        productTypes.find((product) => product.productName === currentSubscribedPackage)
            ?.productPrice
    );

    const billingCycle = currentSubscribedBillingCycle === 'Monthly' ? 1 : 12;

    const totalFeaturedPrice = (featured ? parseFloat(FeaturedPrice) : 0) * 12;
    const totalBasePackagePrice = parseFloat(BasePackagePrice) * billingCycle;
    const totalPrice = (featured ? totalFeaturedPrice : 0) + totalBasePackagePrice;
    const perMonthPrice = featured
        ? parseFloat(FeaturedPrice) + parseFloat(BasePackagePrice)
        : parseFloat(BasePackagePrice);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: 450,
                padding: 20,
                backgroundColor: 'white',
                borderRadius: 4
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginBottom: 10
                }}
            >
                <div style={{ marginBottom: 10 }}>
                    <h3 style={{ fontSize: 20, color: 'rgba(0,0,0,0.7)', fontWeight: 500 }}>
                        {currentSubscribedPackage}
                    </h3>
                    <small>Current plan</small>
                </div>
                <div>
                    {featured && (
                        <Tag
                            style={{
                                color: '#fff',
                                backgroundColor: 'var(--primary)'
                            }}
                        >
                            Featured
                        </Tag>
                    )}
                </div>
            </div>

            <Divider style={{ margin: '10px 0px' }} />

            <div className='datas'>
                <div className='dataRow'>
                    <div>Total per year</div>
                    <div>${totalPrice}</div>
                </div>
                <div className='dataRow'>
                    <div>
                        Base Subscription Package (Yearly): ${parseInt(BasePackagePrice)} *{' '}
                        {billingCycle == 'Monthly' ? 1 : '12'}
                    </div>
                    <div>${totalBasePackagePrice}</div>
                </div>
                <div className='dataRow'>
                    <div>
                        Per month: ${BasePackagePrice} {featured && '+ ' + FeaturedPrice}
                    </div>
                    <div>${perMonthPrice}</div>
                </div>

                <div className='dataRow'>
                    <div>Payment Frequency:</div>
                    <div>{currentSubscribedBillingCycle}</div>
                </div>

                <div className='dataRow'>
                    <div>Last Payment</div>
                    <div>{lastInvoiceDate}</div>
                </div>
                <div className='dataRow'>
                    <div>Next Payment:</div>
                    <div>{upcomingInvoiceDate || 'N/A'}</div>
                </div>

                <div className='dataRow'>
                    <div>Subscription Expires:</div>
                    <div>{upcomingInvoiceDate || 'N/A'}</div>
                </div>
            </div>
        </div>
    );
};

export default CurrentSubscription;
