import React from 'react';
import {
    UserOutlined,
    FileOutlined,
    HeartOutlined,
    BellOutlined,
    StarOutlined,
    MessageOutlined,
    EditOutlined,
    EnvironmentOutlined,
    IdcardOutlined,
    SafetyOutlined,
    DashboardOutlined,
    CreditCardOutlined,
    UsergroupAddOutlined,
    SettingOutlined,
    AreaChartOutlined,
    ShoppingCartOutlined,
    PieChartOutlined
} from '@ant-design/icons';

import Profile from '@components/NewProfile/PharmacyProfile/Profile';
import Dashboard from '@components/NewProfile/PharmacyProfile/Dashboard';
import Document from '@components/NewProfile/Shared/Documents';
import Verifications from '@components/NewProfile/PharmacyProfile/Verifications';
import Favourites from '@components/NewProfile/Shared/Favourites';
import Reviews from '@components/NewProfile/Shared/Reviews';
import Notifications from '@components/NewProfile/Shared/Notifications/Notifications';
import Blogs from '@components/NewProfile/Shared/Blogs';
import CreateBlog from '@components/NewProfile/Shared/Blogs/Create';
import EditBlog from '@components/NewProfile/Shared/Blogs/Edit';
import SingleBlog from '@components/Blogs/SingleView';
import Prospects from '@components/NewProfile/Shared/Prospects';
import Messaging from '@components/NewProfile/Shared/Messaging/Messaging';
import Marketing from '@components/NewProfile/Shared/Marketing';
import Management from '@components/NewProfile/PharmacyProfile/Management';
import Credentials from '@components/NewProfile/Shared/Credentials';
import StripeDashboard from '@components/NewProfile/PharmacyProfile/Dashboard/Stripe';
import Locations from '@components/NewProfile/PharmacyProfile/Locations';
import Subscription from '../../components/NewProfile/Shared/Subscription';
import DataVisualization from '../../components/NewProfile/PharmacyProfile/DataVisualization';

export default [
    {
        name: 'Dashboard',
        path: '/pharmacy/dashboard',
        component: Dashboard,
        icon: <DashboardOutlined />
    },
    {
        name: 'My Account',
        path: '/pharmacy/profile',
        component: Profile,
        icon: <UserOutlined />,
        exact: true
    },
    {
        name: 'Locations',
        path: '/pharmacy/locations',
        component: Locations,
        icon: <EnvironmentOutlined />,
        exact: true
    },
    {
        name: 'Credentials',
        path: '/pharmacy/credentials',
        component: Credentials,
        icon: <IdcardOutlined />,
        exact: true
    },
    {
        name: 'Verifications',
        path: '/pharmacy/verifications',
        component: Verifications,
        icon: <SafetyOutlined />,
        exact: true
    },
    {
        name: 'Documents',
        path: '/pharmacy/documents',
        icon: <FileOutlined />,
        component: Document,
        exact: true
    },

    // {
    //     name: 'Stripe',
    //     path: '/pharmacy/stripe-dashboard',
    //     component: StripeDashboard,
    //     icon: <CreditCardOutlined />,
    //     exact: true
    // },
    {
        name: 'Subscriptions',
        path: '/pharmacy/subscriptions',
        component: Subscription,
        icon: <ShoppingCartOutlined />,
        exact: true
    },

    {
        name: 'Prospects',
        path: '/pharmacy/prospects',
        component: Prospects,
        icon: <UsergroupAddOutlined />,
        exact: true
    },
    {
        name: 'Notifications',
        path: '/pharmacy/notifications',
        component: Notifications,
        icon: <BellOutlined />,
        exact: true
    },

    {
        name: 'Reviews',
        path: '/pharmacy/reviews',
        component: Reviews,
        icon: <StarOutlined />,
        exact: true
    },
    {
        name: 'Management',
        path: '/pharmacy/management',
        component: Management,
        icon: <SettingOutlined />,
        exact: true
    },

    {
        name: 'Data Visualization',
        path: '/pharmacy/data-visualization',
        component: DataVisualization,
        icon: <AreaChartOutlined />,
        exact: true
    },
    // {
    //     name: 'Subscriptions',
    //     path: '/pharmacy/subscriptions',
    //     component: Subscription,
    //     icon: <ShoppingCartOutlined />,
    //     exact: true
    // },

    {
        name: 'Messaging',
        path: '/pharmacy/chats',
        component: Messaging,
        icon: <MessageOutlined />,
        exact: true
    },
    {
        name: 'Blogs',
        path: '/pharmacy/blogs',
        icon: <EditOutlined />,
        component: Blogs,
        exact: true,
        children: [
            {
                path: '/pharmacy/blogs/createblog',
                component: CreateBlog,
                exact: true
            },
            {
                path: '/pharmacy/blogs/edit/:id',
                component: EditBlog,
                exact: true
            },
            {
                path: '/pharmacy/blogs/:postId',
                component: SingleBlog,
                exact: false
            }
        ]
    },
    {
        name: 'Marketing',
        path: '/pharmacy/marketing',
        component: Marketing,
        icon: <PieChartOutlined />,
        exact: true
    },
    {
        name: 'Favourites',
        path: '/pharmacy/favourites',
        icon: <HeartOutlined />,
        component: Favourites,
        exact: true
    }
];
