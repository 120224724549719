import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import * as appointmentActions from '@/actions/appointmentActions';
import moment from 'moment-timezone';
import { Alert, Card, Empty, List, Tag, Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

const Failure = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { appointmentId } = useParams();
    const { singleAppointment } = useSelector((state) => state.appointments);

    useEffect(() => {
        (async () => {
            await dispatch(
                appointmentActions.getSingleAppointment({ obj: { appointmentId: appointmentId } })
            );
        })();
    }, [location.pathname]);
    if (!singleAppointment) return <Empty>Appointment Not Found!</Empty>;
    if (singleAppointment.payment.status === 'PAID')
        return <Redirect to={location.pathname.replace('failure', 'success')} />;
    return (
        <Card bordered={false}>
            <Typography>
                <Title level={2}>Payment Failed!</Title>
                <Paragraph>
                    Unfortunately, your payment could not be processed at this time.
                </Paragraph>
            </Typography>
            <List
                style={{ marginBottom: 20 }}
                bordered
                dataSource={[
                    { label: 'Appointment ID', value: appointmentId },
                    {
                        label: 'Date',
                        value: moment(singleAppointment.appointmentDate).format('DD/MM/YYYY')
                    },
                    {
                        label: 'Time',
                        value: moment
                            .tz(
                                singleAppointment.appointmentTime,
                                'HH:mm',
                                singleAppointment.appointmentTimeZone
                            )
                            .tz(moment.tz.guess())
                            .format('HH:mm')
                    },
                    { label: 'Service', value: singleAppointment.service.name },
                    { label: 'Payment ID', value: singleAppointment.payment._id },
                    { label: 'Payment Status', value: singleAppointment.payment.status },
                    { label: 'Payment Amount ($)', value: singleAppointment.payment.amount },
                    { label: 'Payment Date', value:  moment(singleAppointment.payment.updatedAt).format('DD/MM/YYYY') },
                    { label: 'Transaction ID', value: singleAppointment.payment.transactionId }

                    // Add more details as needed
                ]}
                renderItem={(item) => (
                    <List.Item style={{ justifyContent: 'flex-start', gap: 20 }}>
                        <Text strong>{item.label}: </Text>
                        {item.value}
                    </List.Item>
                )}
            />
            <Paragraph>
                Please wait for the service provider to accept your appointment. You will be
                notified once it is confirmed.
            </Paragraph>
        </Card>
    );
};

export default Failure;
