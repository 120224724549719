import React, { Component } from 'react';
import Axios from 'axios';
import './Geolocate.css'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
var countries = require('country-data').countries;

export class Geolocate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchZipcode: '',
            settingLocation: '',
            address: ''
        };
    }

    geoLocate() {
        navigator.geolocation.getCurrentPosition(function (position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;

            localStorage.setItem('lat', lat);
            localStorage.setItem('lng', lng);
            Axios.get(
                'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
                    lat +
                    ',' +
                    lng +
                    '&key=AIzaSyBgxSzayC4ePVoGH_DKLBSRzPtszez1G2w'
            ).then(function (response) {
                if (response) {
                    for (var i = 0; i < response.data.results.length; i++) {
                        if (response.data.results[i].types[0] == 'country') {
                            var countryshort =
                                response.data.results[i].address_components[0].short_name;
                            var countrylong =
                                response.data.results[i].address_components[0].long_name;

                            localStorage.setItem('country_short', countryshort);
                            localStorage.setItem('country_long', countrylong);
                            localStorage.setItem('Currentcountry', countrylong);
                            localStorage.setItem('currency', countries[countryshort].currencies[0]);
                            localStorage.setItem(
                                'calling_code',
                                countries[countryshort].countryCallingCodes[0]
                            );
                            localStorage.removeItem('mileDistance', 0);
                        }
                        if (response.data.results[i].types[0] == 'locality') {
                            var locality =
                                response.data.results[i].address_components[0].short_name;
                            var localityState =
                                response.data.results[i].address_components[2].long_name;
                            var localityCountry =
                                response.data.results[i].address_components[3].long_name;

                            localStorage.setItem('locality', locality);
                            localStorage.setItem('localityState', localityState);
                            localStorage.setItem('localityCountry', localityCountry);
                        }
                    }
                } else {
                }
            });
        });

        this.setState({
            settingLocation: true,
            searchZipcode:
                localStorage.getItem('locality') + ', ' + localStorage.getItem('localityCountry')
        });
    }

    handleChange = (address) => {
        this.setState({ address });
        if (this.props.setAddressValue) {
            this.props.setAddressValue(address);
        }
    };

    handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => console.log(results))
            .then((latLng) => console.log('Success', latLng))
            .catch((error) => console.error('Error', error));
    };

    render() {
        const {
            chooseAddress,
            propClass,
            changeAddress,
            icon,
            placeholder,
            addressValue,
            setAddressValue
        } = this.props;
        return (
            <PlacesAutocomplete
                value={
                    this.props.addressValue || this.props.addressValue == ''
                        ? this.props.addressValue
                        : this.state.address
                }
                onChange={this.handleChange}
                onSelect={(val) => {
                    this.setState({ address: val });
                    if (setAddressValue) {
                        setAddressValue(val);
                    }
                    chooseAddress(val);
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className='locationCont'>
                        <input
                            id='location'
                            value={
                                this.props.addressValue || this.props.addressValue == ''
                                    ? this.props.addressValue
                                    : this.state.address
                            }
                            onChange={(e) => {
                                if (setAddressValue) {
                                    setAddressValue(e.target.value);
                                } else {
                                    changeAddress(e.target.value);
                                }
                            }}
                            {...getInputProps({
                                placeholder: placeholder ? placeholder : 'Search your location ...',
                                className: `ant-input location-search-input ${
                                    propClass ? propClass : ''
                                }`
                            })}
                        />
                        {icon && icon}
                        {suggestions.length > 0 && (
                            <div
                                className='autocomplete-dropdown-container'
                                style={{ display: 'block' }}
                            >
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#ffffff', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                            // onClick={()=>{ this.setState({ address:suggestion.description })}}
                                            // onClick={()=>chooseAddress(suggestion.description)}
                                            // onSelect={(e)=>this.handleSelect(e).bind(this)}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default Geolocate;
