import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as messagingActions from '@/actions/messagingActions';
import '../card.css';
import { Button, Tag, Tooltip } from 'antd';
import * as prospectActions from '@/actions/prospectActions';
import malePlaceholder from '@/assets/img/malePlaceholder.png';
import femalePlaceholder from '@/assets/img/femalePlaceholder.jpg';
import {
    CalendarOutlined,
    MailOutlined,
    PhoneOutlined,
    QrcodeOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { FaXTwitter } from 'react-icons/fa6';
import { FaLinkedinIn, FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import MessageModal from '../../common/MessageModal';
import { setRegister } from '../../Auth/AuthHelper';
import ButtonGroup from 'antd/es/button/button-group';
import CredentialModal from '../Common/CredentialModal';
import { IoChatbubbleOutline } from 'react-icons/io5';
import QrCodeModal from '../Common/QrCodeModal';
import { getPhoneNumber } from '../Common/Functions';
import { message as toast } from 'antd';

const CaregiverCard = (props) => {
    const { caregiver } = props;
    const [qrModal, setQrModal] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const auth = useSelector((state) => state.auth);
    const [alreadyAddedToProspect, setAlreadyAddedToProspect] = useState(false);
    const [credentialModal, setCredentialModal] = useState(false);
    let genderPlaceholderImg = malePlaceholder;

    if (caregiver && caregiver.gender && caregiver.gender == 'Female') {
        genderPlaceholderImg = femalePlaceholder;
    }

    const { formatted: formattedPhoneNo, phone } = getPhoneNumber(caregiver);

    useEffect(() => {
        if (auth?.isAuthenticated && auth?.userType == 'PATIENT') {
            checkIfProspectAdded();
        }
    }, []);

    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };
    const makeCall = async () => {
        window.open(`tel:${phone}`);
    };
    const goToSite = async () => {
        if (caregiver && caregiver.website) {
            window.open(`https://${caregiver && caregiver.website && caregiver.website}`);
        }
    };
    const visitSocials = (social) => {
        addToProspects();
        caregiver.social?.[social] && window.open(`https://${caregiver.social[social]}`);
    };

    const sendMessage = async () => {
        if(!auth.isAuthenticated){
            dispatch(setRegister(true));
            return;
        }
        let obj = {
            message: message,
            receiver: caregiver._id,
            receiverType: 'CAREGIVER'
        };
        let sendMessage = await dispatch(
            messagingActions.createConversation({ ...props, obj }, history)
        );
        if (sendMessage) {
            setMessageModal(false);
            setMessage('');
        } else {
            console.log('error');
        }
    };

    const links = {
        share: <ShareAltOutlined style={{ fontSize: 15 }} />,
        linkedin: <FaLinkedinIn style={{ fontSize: 16 }} />,
        facebook: <FaFacebookSquare style={{ fontSize: 16 }} />,
        twitter: <FaXTwitter style={{ fontSize: 15 }} />,
        instagram: <FaInstagram style={{ fontSize: 16 }} />
    };

    const requestAppointment = () => {
        addToProspects();
        if (!auth.isAuthenticated) {
            dispatch(setRegister(true));
            return;
        }
        if (auth.userType !== 'PATIENT') {
            toast.error('Only patients can book appointments');
            return;
        }

        if (auth.isAuthenticated && auth.userType == 'PATIENT') {
            let url = `/patient/appointments/new?step=2&member=${caregiver._id}&memberType=CAREGIVER`;
            if (caregiver?.branch) {
                const branchId = caregiver.branch._id || '';
                url += `&branch=${branchId}`;
            }
            history.push(url);
        }
    };

    const checkIfProspectAdded = async () => {
        if (auth?.userType == 'PATIENT') {
            let obj = {
                user: caregiver && caregiver._id,
                userType: 'CAREGIVER',
                prospect: auth.userId,
                prospectType: auth.userType,
                prospectTypeName: 'PATIENT'
            };
            let addToProspect = await dispatch(
                prospectActions.checkIfProspectAdded({ ...props, obj }, history)
            );

            if (addToProspect) {
                setAlreadyAddedToProspect(true);
            }
        }
    };
    const addToProspects = async () => {
        let obj = {
            user: caregiver?._id,
            userType: 'CAREGIVER',
            prospect: auth?.userId,
            prospectType: auth?.userType,
            prospectTypeName: 'PATIENT',
            caregiver: caregiver?._id
        };
        if (!alreadyAddedToProspect && auth?.userType == 'PATIENT') {
            await dispatch(prospectActions.addToProspects({ ...props, obj }, history));
        }
    };

    const handleShare = async () => {
        let urlString = window.location.origin + `/caregiver-view/${caregiver._id}`;

        if (navigator.share) {
            try {
                await navigator.share({
                    title: caregiver.firstName + ' ' + caregiver.lastName,
                    text: 'Check out this content!',
                    url: urlString
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content', error);
            }
        } else {
            console.log('Web Share API is not supported in your browser.');
        }
    };

    const experience =
        caregiver?.experiences?.filter((exp) => exp.current === true)?.[0] ||
        caregiver?.experiences?.[0];

    return (
        <div className={'user-card'} style={{ '--card-color': 'var(--caregiver)' }}>
            <div className='main'>
                <div className='socials'>
                    {caregiver.shareSocial &&
                        Object.entries(links).map(([socialMedia, icon]) => (
                            <Button
                                key={socialMedia} // Add a unique key for each button
                                type='text'
                                style={{
                                    padding: '2px 5px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                disabled={
                                    socialMedia === 'share'
                                        ? false
                                        : !caregiver.social || !caregiver.social[socialMedia]
                                }
                                onClick={() =>
                                    socialMedia === 'share'
                                        ? handleShare()
                                        : visitSocials(socialMedia)
                                }
                            >
                                {icon}
                            </Button>
                        ))}
                </div>
                <div className='avatar-container'>
                    <div className='avatar'>
                        <img src={caregiver?.profileImage || genderPlaceholderImg} />
                    </div>
                    <div className='qr'>
                        <QrcodeOutlined />
                    </div>
                </div>

                <div className='info'>
                    <div
                        onClick={() => {
                            addToProspects();
                            let urlString = `/caregiver-view/${caregiver._id}`;
                            if (caregiver?.branch?._id) {
                                urlString += `?branchId=${caregiver.branch._id}`;
                            }
                            history.push(urlString);
                        }}
                        className='name'
                        style={{ color: 'var(--caregiver)' }}
                    >
                        {caregiver.firstName && caregiver.firstName}{' '}
                        {caregiver.lastName && caregiver.lastName}
                    </div>
                    {(caregiver.nurseType || caregiver.bnmcRegistrationType) && (
                        <div className='speciality' style={{ textAlign: 'center' }}>
                            {caregiver.nurseType} <br />{' '}
                            <small>{caregiver.bnmcRegistrationType}</small>
                        </div>
                    )}
                    <div className='extra' onClick={() => setCredentialModal(true)}>
                        <Tooltip title='View Credentials'>
                            {caregiver.educations?.slice(0, 2).map((education, index) => (
                                <>
                                    {education.degree}
                                    {index !== caregiver?.educations?.length - 1 && ', '}
                                </>
                            ))}{' '}
                            {experience && (
                                <>
                                    <br /> {experience.position + ', ' + experience.organization}
                                </>
                            )}
                            {!caregiver.experiences?.length &&
                                !caregiver.educations?.length &&
                                'View credentials'}
                        </Tooltip>
                    </div>
                </div>

                <div className='hospital'>{caregiver?.hospitalName || 'Independant'}</div>
            </div>

            <div className='about'>
                <div className='title'>About</div>
                <div className='desc'>{caregiver?.profileSummary}</div>
            </div>

            <div className='actions'>
                <ButtonGroup>
                    <Tooltip
                        title={`Send a email to ${caregiver.firstName && caregiver.firstName} ${
                            caregiver.lastName && caregiver.lastName
                        } at ${caregiver.email && caregiver.email}`}
                    >
                        <Button
                            onClick={() =>
                                sendMail(
                                    caregiver.email && caregiver.email,
                                    'Contact Caregiver',
                                    'Hi there!'
                                )
                            }
                            disabled={!caregiver && caregiver.email}
                        >
                            <MailOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip title={formattedPhoneNo}>
                        <Button onClick={() => makeCall()} disabled={!phone}>
                            <PhoneOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={`${
                            caregiver.allowChat
                                ? `Click to message ${caregiver.firstName && caregiver.firstName} ${
                                      caregiver.lastName && caregiver.lastName
                                  }`
                                : 'Messaging unavailable'
                        }`}
                    >
                        <Button
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            disabled={!(caregiver && caregiver.allowChat)}
                            onClick={() => {
                                if(!auth.isAuthenticated){
                                    console.log('got here')
                                    dispatch(setRegister(true));
                                    return;
                                }
                                if (setMessageModal && caregiver.allowChat) {
                                    addToProspects();
                                    setMessageModal(true);
                                }
                            }}
                        >
                            <IoChatbubbleOutline />
                        </Button>
                    </Tooltip>
                </ButtonGroup>

                <Tooltip
                    title={`Request an appointment with ${
                        caregiver.firstName && caregiver.firstName
                    } ${caregiver.lastName && caregiver.lastName}`}
                >
                    <Button onClick={requestAppointment}>
                        Appointment <CalendarOutlined />
                    </Button>
                </Tooltip>
            </div>

            <MessageModal
                open={messageModal}
                setOpen={setMessageModal}
                message={message}
                setMessage={setMessage}
                sendMessage={sendMessage}
            />

            <CredentialModal
                open={credentialModal}
                onCancel={() => setCredentialModal(false)}
                user={caregiver}
                userType={'CAREGIVER'}
            />
            <QrCodeModal
                open={qrModal}
                onCancel={() => setQrModal(false)}
                user={caregiver}
                userType={'CAREGIVER'}
            />
        </div>
    );
};

export default CaregiverCard;
