import React, { useState, useEffect } from 'react';
import NewAppHeader from './NewAppHeader';
import { message } from 'antd';
import Part2 from './DetailPage/DetailPage';
import FinalStage from './FinalStage/FinalStage';
import Part1 from './FirstStage/FirstStage';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import * as HospitalActions from '@/actions/hospitalActions';
import * as DoctorActions from '@/actions/doctorActions';
import * as generalActions from '@/actions/generalActions';
import * as appointmentActions from '@/actions/appointmentActions';
import * as documentActions from '@/actions/documentActions';
import * as availabilityActions from '@/actions/availabilityActions';
import moment from 'moment';
import 'moment-timezone';
import PaymentStage from './PaymentStage/PaymentStage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
let stripePK =
    'pk_test_51Jat9xGdKelfS2valqmKF2YldfpvfxDlazZUvA6joCR5PAABvEwf3WzpwpmjPO6rUnjY2Bhzz342eHNIRJc13EQ600VeqkMeCR';
let stripeSecret =
    'sk_test_51Jat9xGdKelfS2vaK2gVB7K6x7tmMF8bZOUiJUo4rzVcLccIPuncCToSg93bwqGKDPKYBnJCd9LYteR2wTzEoROY009irGcsmQ';
const stripePromise = loadStripe(stripePK);

const NewAppointment = (props) => {
    const [cardElementError, setCardElementError] = useState('');
    const [cardElementValid, setCardElementValid] = useState(true);
    const [pointer, setPointer] = useState(0);
    const [direction, setDirection] = useState(true);
    const dispatch = useDispatch();
    const { history, location } = props;
    const dateFormat = 'DD/MM/YYYY';
    const [stripeToken, setStripeToken] = useState('');
    const [consultationMode, setConsultationMode] = useState('Online');
    const [paymentMode, setPaymentMode] = useState('Online');
    const [chosenService, setChosenService] = useState('');
    const [chosenServiceObj, setChosenServiceObj] = useState(null);
    const [appointmentDate, setAppointmentDate] = useState(moment(new Date()).format(dateFormat));
    const [notes, setNotes] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [loading, setLoading] = useState(false);

    const auth = useSelector((state) => state.auth);
    const hospital = useSelector((state) => state.hospital);
    const appointmentReducer = useSelector((state) => state.appointments);

    const { memberForAppointment: apptMember } = appointmentReducer;
    const { hospitalForAppointment: apptHospital } = hospital;

    let timeSlots, avail, user;
    let tempTimezone = moment.tz.guess();

    useEffect(() => {
        let searchQuery = new URLSearchParams(location.search);
        const member = searchQuery.get('member');
        const memberType = searchQuery.get('memberType');
        if (!member || !memberType) return;
        let obj = {
            userId: member,
            userType: memberType
        };
        dispatch(generalActions.getHospitalServices({ ...props, obj }, history));
    }, [location.search]);

    if (apptHospital && apptHospital.hospital && Object.keys(apptHospital.hospital).length != 0) {
        user = apptHospital.hospital && apptHospital.hospital;
        timeSlots = apptHospital.timeSlots && apptHospital.timeSlots;
        avail = apptHospital.availability && apptHospital.availability;
    } else if (apptMember && apptMember.member && Object.keys(apptMember.member).length != 0) {
        user = apptMember.member && apptMember.member;
        timeSlots = apptMember.timeSlots && apptMember.timeSlots;
        avail = apptMember.availability && apptMember.availability;
    }

    const submitForm = async () => {
        setLoading(true);
        let query = getQuery(location.search);
        console.log(user);
        let uploadedDocs = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let formObj = new FormData();
            formObj.append('userId', auth.userId);
            formObj.append('userType', auth.userType);
            formObj.append('file', file);
            let uploadedDocument = await dispatch(
                documentActions.uploadDocument({ ...props, obj: formObj }, history)
            );

            if (uploadedDocument) {
                let obj = {
                    userId: auth.userId,
                    userType: auth.userType,
                    emails: [user.email],
                    docId: uploadedDocument.docId
                };
                uploadedDocs.push(uploadedDocument.docId);
                let shareDoc = await dispatch(
                    documentActions.shareDocument({ ...props, obj }, history)
                );
                if (shareDoc) {
                    message.success('Successfully shared documents with emails');
                }
            }
        }

        let obj = {
            time: timeSlots[selectedSlot],
            date: appointmentDate && moment(appointmentDate, dateFormat).format(dateFormat),
            consultationMode: consultationMode,
            paymentMode: paymentMode,
            member: query.get('member'),
            memberType: query.get('memberType'),
            hospital: query.get('hospital')
                ? query.get('hospital')
                : apptMember
                ? apptMember.member && apptMember.member.hospital && apptMember.member.hospital
                : '',
            userEmail: auth && auth.user && auth.user.email,
            requestTo: query.get('hospital') ? 'HOSPITAL' : query.get('memberType'),
            branch: query.get('branch'),
            files: uploadedDocs,
            notes: notes.map((item) => item.value),
            timezone: tempTimezone,
            stripeToken: stripeToken,
            amount: chosenServiceObj?.rate,
            service: chosenServiceObj?._id
        };
        let createApp = await dispatch(
            appointmentActions.requestAppointmentByPatient({ ...props, obj }, history)
        );

        if (createApp) {
            //  await getAppointments()
            setLoading(false);
            history.push('/patient/appointments');
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const step = urlParams.get('step');
        if (location && location.search && step >= '2') {
            refreshDetails();
        }

        const tab = urlParams.get('tab');
        if (step == '0' || !step) {
            setPointer(0);
            history.push(`/patient/appointments/new?tab=${tab || 'DOCTOR'}`);
        }

        return () => {
            dispatch({ type: 'CLEAR_APPOINTMENT_MEMBER' });
        };
    }, [location && location.search]);

    const getQuery = (search) => {
        return new URLSearchParams(search);
    };
    useEffect(() => {
        let searchQuery = new URLSearchParams(location.search);

        if (searchQuery.get('date')) {
            setAppointmentDate(searchQuery.get('date'));
        }

        if (searchQuery.get('hospital') || searchQuery.get('member')) {
            setPointer(1);
            let obj = {
                memberId: searchQuery.get('member'),
                branchId: searchQuery.get('branch'),
                date: appointmentDate,
                timezone: tempTimezone
            };
            if (searchQuery.get('hospital')) {
                obj.hospitalId = searchQuery.get('hospital');
                dispatch(HospitalActions.getExtractedTimeSlots({ ...props, obj }, history));
            }
            if (searchQuery.get('member') && searchQuery.get('memberType')) {
                obj.memberId = searchQuery.get('member');
                obj.userType = searchQuery.get('memberType');
                dispatch(availabilityActions.getExtractedTimeSlots({ ...props, obj }, history));
            }
        }
    }, [appointmentDate]);

    const refreshDetails = async () => {
        let searchQuery = new URLSearchParams(location.search);
        console.log('search querying');
        if (searchQuery.get('hospital') || searchQuery.get('member')) {
            setPointer(1);

            let obj = {
                branchId: searchQuery.get('branch'),
                date: appointmentDate,
                timezone: tempTimezone
            };
            if (searchQuery.get('hospital')) {
                obj.hospitalId = searchQuery.get('hospital');
                dispatch(HospitalActions.getHospitalForAppointment({ ...props, obj }, history));
                dispatch(HospitalActions.getExtractedTimeSlots({ ...props, obj }, history));
            } else if (searchQuery.get('member')) {
                obj.memberId = searchQuery.get('member');
                obj.memberType = searchQuery.get('memberType');
                obj.userType = searchQuery.get('memberType');
                dispatch(appointmentActions.getMemberForAppointment({ ...props, obj }, history));
                dispatch(availabilityActions.getExtractedTimeSlots({ ...props, obj }, history));
            }
        }
    };

    const variants = {
        enter: (direction) => {
            return {
                x: direction ? '100vw' : '-100vw',
                opacity: 0,
                transition: { ease: 'easeIn', duration: 0.4 }
            };
        },
        active: {
            x: 0,
            opacity: 1,
            transition: { delay: 0.2, ease: 'easeIn', duration: 0.4 }
        },
        exit: (direction) => {
            return {
                x: direction ? '-100vw' : '100vw',
                opacity: 0,
                transition: { delay: 0.2, ease: 'easeIn', duration: 0.4 }
            };
        }
    };

    const trySetPointer = (val) => {
        if (val > pointer) {
            setDirection(true);
        } else {
            setDirection(false);
        }

        setPointer(val);
    };

    return (
        <motion.div key={'asdasd'} className='new-app-main' custom={direction}>
            <Elements stripe={stripePromise}>
                <NewAppHeader pointer={pointer} setPointer={trySetPointer} history={history} />

                <AnimatePresence exitBeforeEnter custom={direction}>
                    {pointer === 0 && (
                        <motion.div
                            key={'1'}
                            style={{ marginTop: 20 }}
                            // key={pointer}
                            custom={direction}
                            variants={variants}
                            initial='enter'
                            animate='active'
                            exit='exit'
                        >
                            <Part1
                                {...props}
                                key={pointer}
                                pointer={pointer}
                                setPointer={trySetPointer}
                                consultationMode={consultationMode}
                                setConsultationMode={setConsultationMode}
                                chosenService={chosenService}
                                setChosenService={setChosenService}
                                paymentMode={paymentMode}
                                setPaymentMode={setPaymentMode}
                                appointmentDate={appointmentDate}
                                setAppointmentDate={setAppointmentDate}
                                refreshDetails={refreshDetails}
                                selectedSlot={selectedSlot}
                                setSelectedSlot={setSelectedSlot}
                                auth={auth}
                                hospital={hospital}
                                submitForm={submitForm}
                                notes={notes}
                                setNotes={setNotes}
                                files={files}
                                setFiles={setFiles}
                            />
                        </motion.div>
                    )}

                    {pointer === 1 && (
                        <motion.div
                            key={'2'}
                            style={{ marginTop: 20 }}
                            // key={pointer}
                            custom={direction}
                            variants={variants}
                            initial='enter'
                            animate='active'
                            exit='exit'
                        >
                            <Part2
                                {...props}
                                key={pointer}
                                pointer={pointer}
                                setPointer={trySetPointer}
                                consultationMode={consultationMode}
                                setConsultationMode={setConsultationMode}
                                chosenService={chosenService}
                                setChosenService={setChosenService}
                                paymentMode={paymentMode}
                                setPaymentMode={setPaymentMode}
                                appointmentDate={appointmentDate}
                                setAppointmentDate={setAppointmentDate}
                                selectedSlot={selectedSlot}
                                setSelectedSlot={setSelectedSlot}
                                auth={auth}
                                hospital={hospital}
                                submitForm={submitForm}
                                notes={notes}
                                setNotes={setNotes}
                                files={files}
                                setFiles={setFiles}
                                chosenServiceObj={chosenServiceObj}
                                setChosenServiceObj={setChosenServiceObj}
                                history={history}
                            />
                        </motion.div>
                    )}

                    {/* {pointer === 2 && (
                        <motion.div
                            style={{ marginTop: 20 }}
                            key={'3'}
                            custom={direction}
                            variants={variants}
                            initial='enter'
                            animate='active'
                            exit='exit'
                        >
                            <PaymentStage
                                {...props}
                                key={pointer}
                                pointer={pointer}
                                setPointer={trySetPointer}
                                consultationMode={consultationMode}
                                setConsultationMode={setConsultationMode}
                                chosenService={chosenService}
                                setChosenService={setChosenService}
                                paymentMode={paymentMode}
                                setPaymentMode={setPaymentMode}
                                appointmentDate={appointmentDate}
                                setAppointmentDate={setAppointmentDate}
                                selectedSlot={selectedSlot}
                                setSelectedSlot={setSelectedSlot}
                                auth={auth}
                                submitForm={submitForm}
                                hospital={hospital}
                                notes={notes}
                                setNotes={setNotes}
                                files={files}
                                setFiles={setFiles}
                                chosenServiceObj={chosenServiceObj}
                                setChosenServiceObj={setChosenServiceObj}
                                stripeToken={stripeToken}
                                setStripeToken={setStripeToken}
                                cardElementError={cardElementError}
                                setCardElementError={setCardElementError}
                                cardElementValid={cardElementValid}
                                setCardElementValid={setCardElementValid}
                            />
                        </motion.div>
                    )} */}
                    {pointer === 2 && (
                        <motion.div
                            style={{ marginTop: 20 }}
                            key={'4'}
                            custom={direction}
                            variants={variants}
                            initial='enter'
                            animate='active'
                            exit='exit'
                        >
                            <FinalStage
                                {...props}
                                loading={loading}
                                setLoading={setLoading}
                                key={pointer}
                                pointer={pointer}
                                setPointer={trySetPointer}
                                consultationMode={consultationMode}
                                setConsultationMode={setConsultationMode}
                                chosenService={chosenService}
                                setChosenService={setChosenService}
                                paymentMode={paymentMode}
                                setPaymentMode={setPaymentMode}
                                appointmentDate={appointmentDate}
                                setAppointmentDate={setAppointmentDate}
                                selectedSlot={selectedSlot}
                                setSelectedSlot={setSelectedSlot}
                                auth={auth}
                                submitForm={submitForm}
                                hospital={hospital}
                                notes={notes}
                                setNotes={setNotes}
                                files={files}
                                setFiles={setFiles}
                                chosenServiceObj={chosenServiceObj}
                                setChosenServiceObj={setChosenServiceObj}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </Elements>
        </motion.div>
    );
};

export default NewAppointment;
