import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { Resizable } from 'react-resizable';
import ReactDragListView from 'react-drag-listview';

const ResizableTitle = (props) => {
    const { onResize, width, minWidth, style, ...restProps } = props;
    if (!width) {
        return <th style={{ ...style }} {...restProps} />;
    }
    return (
        <Resizable
            width={width || 100}
            height={0}
            handle={
                <span
                    className='react-resizable-handle'
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            minConstraints={[minWidth, 0]}
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
            style={{ ...style }}
        >
            <th
                {...restProps}
                title={props?.title?.props?.children || restProps.title}
                style={{ ...style }}
            />
        </Resizable>
    );
};

const DataTable = ({ data, onTableValuesChange, pagination, loading, columns, tableName }) => {
    const [tableColumns, setTableColumns] = useState([]);
    const [tableWidth, setTableWidth] = useState(0);

    useEffect(() => {
        const columnsWithWidth = columns.map((column, index) => ({
            ...column,
            width: tableColumns[index]?.width || column.width, // Set a default width value if not provided
            minWidth: (column?.onHeaderCell && column?.onHeaderCell()?.style?.minWidth) || 100,
            style: (column?.onHeaderCell && column?.onHeaderCell()?.style) || {
                whiteSpace: 'pre-wrap'
            }
        }));
        setTableColumns(columnsWithWidth);
    }, [columns]);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const updatedColumns = [...tableColumns];
            const item = updatedColumns.splice(fromIndex, 1)[0];
            updatedColumns.splice(toIndex, 0, item);
            setTableColumns(updatedColumns);
        },
        nodeSelector: 'th',
        handleSelector: '.dragHandler',
        ignoreSelector: 'react-resizable-handle'
    };

    const handleResize =
        (index) =>
        (e, { size }) => {
            setTableColumns((prevColumns) => {
                const updatedColumns = [...prevColumns];
                updatedColumns[index] = {
                    ...updatedColumns[index],
                    width: size.width
                };

                const columnWidths = updatedColumns.reduce((widths, column) => {
                    if (column?.title?.props?.children) {
                        widths[column.title.props.children] = column.width || 0;
                    } else {
                        widths[column.title] = column.width || 0;
                    }
                    return widths;
                }, {});

                const updatedTableWidth = Object.values(columnWidths).reduce(
                    (sum, width) => sum + width,
                    0
                );
                setTableWidth(updatedTableWidth);
                const storedColumnWidths = JSON.parse(localStorage.getItem('columnWidths'));

                localStorage.setItem(
                    'columnWidths',
                    JSON.stringify({
                        ...storedColumnWidths,
                        [tableName]: { ...columnWidths, tableWidth: updatedTableWidth }
                    })
                );
                return updatedColumns;
            });
        };

    useEffect(() => {
        const storedColumnWidths = JSON.parse(localStorage.getItem('columnWidths'))?.[tableName];
        if (storedColumnWidths) {
            setTableColumns((prevColumns) => {
                const updatedColumns = [...prevColumns];
                return updatedColumns.map((column) => {
                    if (!column?.title?.props?.children) return null;

                    return {
                        ...column,
                        width: storedColumnWidths[column.title.props.children] || column.width
                    };
                });
            });

            setTableWidth(storedColumnWidths.tableWidth || 0);
        }
    }, [tableName]);

    const onChange = (pagination) => {
        onTableValuesChange &&
            onTableValuesChange({
                current: pagination.current ?? 1,
                pageSize: pagination.pageSize ?? 10
            });
    };
    console.log('upcomingInvoice', data);
    return (
        <ReactDragListView.DragColumn {...dragProps}>
            <Table
                loading={loading}
                bordered
                data-testid='products-table'
                pagination={
                    pagination
                        ? {
                              showSizeChanger: true,
                              pageSizeOptions: ['5', '10', '15', '20'],
                              ...pagination
                          }
                        : false
                }
                // columns={columns}
                // scroll={{ x: 1000 }}
                dataSource={data}
                onChange={onChange}
                rowKey={(record) => record.id}
                columns={tableColumns.map((col, index) => ({
                    ...col,
                    onHeaderCell: (column) => ({
                        ...column,
                        width: column.width,
                        onResize: handleResize(index)
                    })
                }))}
                scroll={{ x: tableWidth || 1000 }}
                components={{
                    header: {
                        cell: ResizableTitle
                    }
                }}
            />
        </ReactDragListView.DragColumn>
    );
};

export default DataTable;
