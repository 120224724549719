import React, { useState, memo, useEffect } from 'react';
import './lower.css';
import List from './Tablist';
import ListSidebar from './Sidebar';
import { GoGlobe } from 'react-icons/go';
import { FaThumbsUp } from 'react-icons/fa';
import { BiLike } from 'react-icons/bi';
import { IoQrCodeSharp } from 'react-icons/io5';
import { BsFillCalendarFill, BsEnvelope, BsStar, BsPhone } from 'react-icons/bs';
import * as hospitalActions from '@/actions/hospitalActions';
import * as userActions from '@/actions/userActions';
import { connect, useSelector } from 'react-redux';
import { Alert, message, Popover } from 'antd';
import { bindActionCreators } from 'redux';
import { EmailShareButton } from 'react-share';
import { BiShareAlt } from 'react-icons/bi';
import QrCodeModal from '../../../Cards/Common/QrCodeModal';
import { useDispatch } from 'react-redux';
import { setRegister } from '@/components/Auth/AuthHelper';
import { IoChatbubbleOutline } from 'react-icons/io5';

const Lower = (props) => {
    const dispatch = useDispatch();
    const { userType, isAuthenticated } = useSelector((state) => state.auth);
    const { tab, setTab, id, selectedAddressIndex, hospital, auth, selectedAddress, history } =
        props;
    const tabs = ['doctors', 'general', 'blogs', 'following', 'reviews'];
    const [qrModal, setQrModal] = useState(false);
    const handleTab = (string) => {
        if (tabs.includes(string)) {
            setTab(string);
            props.history.push(`/hospital-view/${id}?branchId=${selectedAddress}&tab=${string}`);
        }
    };
    const [liked, setLiked] = useState(false);
    const { hospitalSingle } = hospital;
    useEffect(() => {
        if (hospitalSingle) {
            let like = false;
            if (hospitalSingle.likes && auth && auth.userId) {
                let filtered = hospitalSingle.likes.filter((item) => item.user == auth.userId);
                console.log(filtered);
                if (filtered.length > 0) {
                    setLiked(true);
                } else {
                    setLiked(false);
                }
            }
        }
    }, [hospitalSingle, hospitalSingle && hospital.likes]);

    const likeOrUnlikeUserType = async () => {
        let obj = {
            userId: hospitalSingle._id,
            userType: 'HOSPITAL',
            like: liked ? false : true
        };
        let likedLaw = await props.generalActions.likeOrUnlikeUserType(
            { ...props, obj },
            props.history
        );
        if (likedLaw) {
            await props.actions.getHospitalById({ ...props, hospitalId: id }, props.history);
            let obj = {
                hospitalId: id
            };
            await props.actions.getHospitalStatCounts({ ...props, obj }, props.history);
        }
    };
    let selectedBranch =
        hospitalSingle && hospitalSingle.branches && hospitalSingle.branches[selectedAddressIndex];

    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };
    const goToSite = async () => {
        if (selectedBranch && selectedBranch.website) {
            window.open(
                `https://${selectedBranch && selectedBranch.website && selectedBranch.website}`
            );
        }
    };

    const makeCall = async () => {
        window.open(
            `tel:${selectedBranch && selectedBranch.countryCode && selectedBranch.countryCode}${
                selectedBranch && selectedBranch.phoneNo && selectedBranch.phoneNo
            }`
        );
    };
    return (
        <>
            <div className='hospital-view-lower'>
                <div className='hospital-view-lower-top'>
                    <div className='hospital-view-lower-top-btns'>
                        <div
                            className={`${tab == 'general' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('general')}
                        >
                            <p>General</p>
                        </div>
                        <div
                            className={`${tab == 'blogs' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('blogs')}
                        >
                            <p>Posts</p>
                        </div>
                        <div
                            className={`${tab == 'doctors' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('doctors')}
                        >
                            <p>Doctors</p>
                        </div>

                        <div
                            className={`${tab == 'reviews' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('reviews')}
                        >
                            <p>Reviews</p>
                        </div>
                    </div>
                </div>
            </div>
            {history.location?.state?.prevPath === 'profile' && (
                <div style={{ padding: 20 }}>
                    <Alert
                        onClick={() => history.goBack()}
                        style={{ cursor: 'pointer' }}
                        message={
                            <div>
                                This is a preview of your public profile. Click here to return to
                                your profile page.
                            </div>
                        }
                        type='info'
                    />
                </div>
            )}
            <div className='hospital-view-action'>
                <div className='hospital-view-action-container'>
                    <div className='hospital-view-action-btn'>
                        {/* {(userType === 'PATIENT' || !isAuthenticated) && ( */}
                            <div
                                className='hospital-view-book'
                                onClick={() => {
                                    if (!isAuthenticated) {
                                        localStorage.setItem(
                                            'appointmentLink',
                                            `/patient/appointments/new?step=2&hospital=${id}&branch=${
                                                selectedBranch && selectedBranch._id
                                            }`
                                        );
                                        dispatch(setRegister(true));
                                        return;
                                    }
                                    if (userType !== 'PATIENT') {
                                        message.error('Only patients can book appointments');
                                        return;
                                    }
                                    props.history.push(
                                        `/patient/appointments/new?step=2&hospital=${id}&branch=${
                                            selectedBranch && selectedBranch._id
                                        }`
                                    );
                                }}
                            >
                                <BsFillCalendarFill />
                                <p>Book Appointment</p>
                            </div>
                        {/* )} */}

                        {hospital.hospitalSingle &&
                            hospital.hospitalSingle &&
                            hospital.hospitalSingle.allowChat && (
                                <div
                                    className='hospital-view-message'
                                    onClick={() => {
                                        console.log('hello')

                                        if (!isAuthenticated) {
                                            console.log('got here')
                                            dispatch(setRegister(true));
                                            return;
                                        }
                                        props.openMessageModal();
                                    }}
                                >
                                    <IoChatbubbleOutline />
                                    <p>Message</p>
                                </div>
                            )}

                        {hospital &&
                            hospital.hospitalSingle &&
                            auth &&
                            auth.userId &&
                            auth.userType != 'HOSPITAL' &&
                            props.hospital.hospitalSingle._id != auth.userId && (
                                <div
                                    className='hospital-view-message'
                                    onClick={() => {
                                        let obj = {
                                            favouriteItem:
                                                props.hospital.hospitalSingle._id &&
                                                props.hospital.hospitalSingle._id,
                                            favouriteType: 'HOSPITAL'
                                        };
                                        props.userActions.addToFavourites(
                                            { obj, ...props },
                                            props.history
                                        );
                                    }}
                                >
                                    <BsStar />

                                    <p>Add to Favourites</p>
                                </div>
                            )}
                    </div>
                    <div className='hospital-view-btns'>
                        {/* <div className={"hospital-views-click"}>
              <IoQrCodeSharp fontSize="1.5rem" />
            </div> */}
                        <Popover
                            content={
                                selectedBranch &&
                                selectedBranch.phoneNo &&
                                selectedBranch.countryCode
                                    ? `(${selectedBranch.countryCode}) - ${selectedBranch.phoneNo} `
                                    : 'Not Specified'
                            }
                            onClick={() => makeCall()}
                        >
                            <div
                                className={
                                    selectedBranch &&
                                    selectedBranch.phoneNo &&
                                    selectedBranch.countryCode
                                        ? 'hospital-views-click active'
                                        : 'hospital-views-click'
                                }
                                style={{ color: 'white' }}
                            >
                                <BsPhone fontSize='1.5rem' />
                            </div>
                        </Popover>
                        <Popover
                            content={
                                selectedBranch && selectedBranch.email
                                    ? selectedBranch.email
                                    : 'Not Specified'
                            }
                            onClick={() =>
                                sendMail(
                                    selectedBranch && selectedBranch.email
                                        ? selectedBranch.email
                                        : '',
                                    'Contact Hospital',
                                    'Hi there!'
                                )
                            }
                        >
                            <div
                                className={
                                    selectedBranch && selectedBranch.email
                                        ? 'hospital-views-click active'
                                        : 'hospital-views-click'
                                }
                                style={{ color: 'white' }}
                            >
                                <BsEnvelope fontSize='1.5rem' />
                            </div>
                        </Popover>
                        <Popover
                            content={
                                selectedBranch && selectedBranch.website
                                    ? selectedBranch.website
                                    : 'No website available'
                            }
                            onClick={() => goToSite()}
                        >
                            <div
                                className={
                                    selectedBranch && selectedBranch.website
                                        ? 'hospital-views-click active'
                                        : 'hospital-views-click'
                                }
                            >
                                <GoGlobe
                                    fontSize='1.5rem'
                                    className={
                                        selectedBranch && selectedBranch.website ? 'email' : ''
                                    }
                                />
                            </div>
                        </Popover>
                        <Popover content={'View QR Code'}>
                            <div
                                className='hospital-views-click'
                                style={{ color: 'var(--gray)' }}
                                onClick={() => setQrModal(true)}
                            >
                                <IoQrCodeSharp fontSize='1.5rem' />
                            </div>
                        </Popover>

                        <Popover content={`${liked ? 'Unlike' : 'Like'}`}>
                            <div
                                className={'hospital-views-click active'}
                                onClick={() => likeOrUnlikeUserType()}
                            >
                                {liked ? (
                                    <FaThumbsUp fontSize='1.5rem' className='email' />
                                ) : (
                                    <BiLike fontSize='1.5rem' className='email' />
                                )}
                            </div>
                        </Popover>

                        <EmailShareButton
                            style={{ outline: 'none' }}
                            // className="hospital-views-click active"
                            url={`https://Medasq-frontend-inevex.herokuapp.com/hospital-view/${hospitalSingle._id}`}
                        >
                            <Popover content='Share'>
                                <div
                                    className='hospital-views-click active'
                                    style={{ color: 'white' }}
                                >
                                    <BiShareAlt fontSize='1.5rem' className='email' />
                                </div>
                            </Popover>
                        </EmailShareButton>
                    </div>
                </div>
            </div>
            <div className='hospital-view-lower-grid'>
                <List hospital={props.hospital} {...props} tab={tab} />
                {tab == 'general' && <ListSidebar {...props} index={props.index} />}
            </div>

            <QrCodeModal
                open={qrModal}
                onCancel={() => setQrModal(false)}
                user={hospitalSingle}
                userType={'HOSPITAL'}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    hospital: state.hospital,
    hospitalSingle: state.hospital && state.hospital.hospitalSingle && state.hospital.hospitalSingle
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(hospitalActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Lower));
