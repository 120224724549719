import React from 'react';
import { Tag, Button } from 'antd';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

const Columns = () => {
    const { userProfile } = useSelector((state) => state.auth);
    const stripeInvoiceColumns = [
        {
            title: 'Invoice No.',
            dataIndex: 'number',
            key: 'number',
            width: 250
        },
        {
            title: 'Date',
            dataIndex: 'created',
            key: 'created',
            width: 140,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.created) return -1; // Handle empty values
                if (!b.created) return 1; // Handle empty values
                return a.created.localeCompare(b.created);
            },
            render: (created) => (
                <span>{DateTime.fromMillis(created * 1000).toFormat('MMM d, yyyy')}</span>
            ),
            onHeaderCell: () => {
                return {
                    style: { minWidth: 140 }
                };
            }
        },
        {
            title: 'Package',
            key: 'subscribedPackage',
            width: 150,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a?.metadata?.subscribedPackage) return -1; // Handle empty values
                if (!b?.metadata?.subscribedPackage) return 1; // Handle empty values
                return a?.metadata?.subscribedPackage.localeCompare(b?.metadata?.subscribedPackage);
            },
            render: (_, record) => (
                <span>
                    {record?.metadata?.subscribedPackage || userProfile?.currentSubscribedPackage}
                </span>
            )
        },
        {
            title: 'Billing Cycle',
            key: 'billingCycle',
            width: 150,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a?.metadata?.chargedAnnually) return -1; // Handle empty values
                if (!b?.metadata?.chargedAnnually) return 1; // Handle empty values
                return a?.metadata?.chargedAnnually.localeCompare(b?.metadata?.chargedAnnually);
            },
            render: (_, record) => (
                <span>{record?.metadata?.chargedAnnually == 'false' ? 'Monthly' : 'Yearly'}</span>
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount_due',
            key: 'amount_due',
            width: 200,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.amount_due) return -1; // Handle empty values
                if (!b.amount_due) return 1; // Handle empty values
                return a.amount_due.localeCompare(b.amount_due);
            },
            render: (amount_due) => <span>${parseFloat(amount_due / 100).toFixed(2)}</span>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 140,
            key: 'status',
            render: (text) => <Tag style={{ textTransform: 'uppercase' }}>{text}</Tag>
        },
        {
            title: 'Invoice',
            dataIndex: 'invoice_pdf',
            key: 'invoice_pdf',
            render: (invoice_pdf) => (
                <Button type='primary'>
                    <a
                        style={{ textDecoration: 'none' }}
                        href={invoice_pdf ? invoice_pdf : 'javascript:(void)'}
                        target='_blank'
                    >
                        View
                    </a>
                </Button>
            )
        }
    ];

    //sslCommerzInvoiceColumns
    // createdAt: '2024-07-24T14:12:19.038Z';
    // currency: 'BDT';
    // package: 'Essentials';
    // status: 'PAID';
    // transactionId: '66a10bc25cdcd1d0a671928a';
    // updatedAt: '2024-07-24T14:12:19.038Z';
    // user: '669927ba356059c0fdd96a20';
    // userType: 'Doctor';
    // __v: 0;
    // _id: '66a10bc35cdcd1d0a671928d';

    const sslCommerzInvoiceColumns = [
        {
            title: 'Invoice No.',
            dataIndex: '_id',
            key: 'number',
            width: 250
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 140,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.createdAt) return -1; // Handle empty values
                if (!b.createdAt) return 1; // Handle empty values
                return a.createdAt.localeCompare(b.createdAt);
            },
            render: (createdAt) => (
                <span>{DateTime.fromISO(createdAt).toFormat('MMM d, yyyy')}</span>
            ),
            onHeaderCell: () => {
                return {
                    style: { minWidth: 140 }
                };
            }
        },
        {
            title: 'Package',
            key: 'package',
            width: 150,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a?.package) return -1; // Handle empty values
                if (!b?.package) return 1; // Handle empty values
                return a?.package.localeCompare(b?.package);
            },
            render: (_, record) => (
                <span>{record?.package || userProfile?.currentSubscribedPackage}</span>
            )
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 200,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.amount) return -1; // Handle empty values
                if (!b.amount) return 1; // Handle empty values
                return a.amount.localeCompare(b.amount);
            },
            render: (amount) => <span>${parseFloat(amount).toFixed(2)}</span>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 140,
            key: 'status',
            render: (text) => <Tag style={{ textTransform: 'uppercase' }}>{text}</Tag>
        },
        {
            title: 'Invoice',
            dataIndex: 'invoice_pdf',
            key: 'invoice_pdf',
            render: (invoice_pdf) => invoice_pdf ? (
                <Button type='primary'>
                    <a
                        style={{ textDecoration: 'none' }}
                        href={invoice_pdf ? invoice_pdf : 'javascript:(void)'}
                        target='_blank'
                    >
                        View
                    </a>
                </Button>
            ) : <span>Not Available</span>
        }
    ];

    return {
        stripeInvoiceColumns,
        sslCommerzInvoiceColumns
    };
};

export default Columns;
