import React from 'react';
import {
    UserOutlined,
    FileOutlined,
    CalendarOutlined,
    HeartOutlined,
    BellOutlined,
    StarOutlined,
    MessageOutlined,
    EditOutlined,
    IdcardOutlined,
    SafetyOutlined,
    CreditCardOutlined, UsergroupAddOutlined, SettingOutlined, AreaChartOutlined, ShoppingCartOutlined, PieChartOutlined,
    DashboardOutlined
} from '@ant-design/icons';

import Profile from '@components/NewProfile/ConsultantProfile/Profile';
import Document from '@components/NewProfile/Shared/Documents';
import Verifications from '@components/NewProfile/ConsultantProfile/Verifications';
import Favourites from '@components/NewProfile/Shared/Favourites';
import Reviews from '@components/NewProfile/Shared/Reviews';
import Notifications from '@components/NewProfile/Shared/Notifications/Notifications';
import Blogs from '@components/NewProfile/Shared/Blogs';
import CreateBlog from '@components/NewProfile/Shared/Blogs/Create';
import EditBlog from '@components/NewProfile/Shared/Blogs/Edit';
import SingleBlog from '@components/Blogs/SingleView';
import Prospects from '@components/NewProfile/Shared/Prospects';
import Messaging from '@components/NewProfile/Shared/Messaging/Messaging';
import Appointments from '@components/NewProfile/Shared/Appointments';
import Marketing from '@components/NewProfile/Shared/Marketing';
import Management from '@components/NewProfile/HospitalProfile/Management';
import Credentials from '@components/NewProfile/Shared/Credentials';
import VideoContainer from '@components/NewProfile/Shared/Meeting/VideoContainer';
import StripeDashboard from '@components/NewProfile/HospitalProfile/Dashboard/Stripe';
import MeetingEnded from '@components/NewProfile/Shared/Meeting/MeetingEnded';
import Subscription from '../../components/NewProfile/Shared/Subscription';
import DataVisualization from '../../components/NewProfile/HospitalProfile/DataVisualization';
import Dashboard from '@components/NewProfile/Shared/Dashboard'
import Success from '../../components/NewProfile/Shared/Appointments/Payment/Success';
import Failure from '../../components/NewProfile/Shared/Appointments/Payment/Failure';


export default [
    {
        name: 'Dashboard',
        path: '/consultant/dashboard',
        component: Dashboard,
        icon: <DashboardOutlined />,
    },
    {
        name: 'My Account',
        path: '/consultant/profile',
        component: Profile,
        icon: <UserOutlined />,
        exact: true
    },

    {
        name: 'Credentials',
        path: '/consultant/credentials',
        component: Credentials,
        icon: <IdcardOutlined />,
        exact: true
    },
    {
        name: 'Verifications',
        path: '/consultant/verifications',
        component: Verifications,
        icon: <SafetyOutlined />,
        exact: true
    },
    {
        name: 'Documents',
        path: '/consultant/documents',
        icon: <FileOutlined />,
        component: Document,
        exact: true
    },

  {
        name: 'Appointments',
        path: '/consultant/appointments',
        icon: <CalendarOutlined />,
        component: Appointments,
        exact: true,
        children: [
            {
                path: '/consultant/appointments/:appointmentId/success',
                component: Success,
                exact: true
            },
            {
                path: '/consultant/appointments/:appointmentId/failure',
                component: Failure,
                exact: true
            },
            {
                path: '/consultant/appointments/meeting/:appointmentId/:sessionId/:token',
                component: VideoContainer,
                exact: true
            },
            {
                path: '/patient/appointments/meeting/:appointmentId/meeting-ended',
                component: MeetingEnded,
                exact: true
            }
        ]
    },
  {
        name: 'Stripe',
        path: '/consultant/stripe-dashboard',
        component: StripeDashboard,
        icon: <CreditCardOutlined />,
        exact: true
    },
    {
        name: 'Prospects',
        path: '/consultant/prospects',
        component: Prospects,
        icon: <UsergroupAddOutlined />,
        exact: true
    },
    {
        name: 'Notifications',
        path: '/consultant/notifications',
        component: Notifications,
        icon: <BellOutlined />,
        exact: true
    },

    {
        name: 'Reviews',
        path: '/consultant/reviews',
        component: Reviews,
        icon: <StarOutlined />,
        exact: true
    },
    {
        name: 'Management',
        path: '/consultant/management',
        component: Management,
        icon: <SettingOutlined />,
        exact: true
    },

    {
        name: 'Data Visualization',
        path: '/consultant/data-visualization',
        component: DataVisualization,
        icon: <AreaChartOutlined />,
        exact: true
    },
    {
        name: 'Subscriptions',
        path: '/consultant/subscriptions',
        component: Subscription,
        icon: <ShoppingCartOutlined />,
        exact: true
    },

    {
        name: 'Messaging',
        path: '/consultant/chats',
        component: Messaging,
        icon: <MessageOutlined />,
        exact: true
    },
    {
        name: 'Blogs',
        path: '/consultant/blogs',
        icon: <EditOutlined />,
        component: Blogs,
        exact: true,
        children: [
            {
                path: '/consultant/blogs/createblog',
                component: CreateBlog,
                exact: true
            },
            {
                path: '/consultant/blogs/edit/:id',
                component: EditBlog,
                exact: true
            },
            {
                path: '/consultant/blogs/:postId',
                component: SingleBlog,
                exact: false
            }
        ]
    },
    {
        name: 'Marketing',
        path: '/consultant/marketing',
        component: Marketing,
        icon: <PieChartOutlined />,
        exact: true
    },
    {
        name: 'Favourites',
        path: '/consultant/favourites',
        icon: <HeartOutlined />,
        component: Favourites,
        exact: true
    }
];