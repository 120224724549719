import React, { useState, useRef, useEffect } from 'react';
import './doctorModals.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as hospitalActions from '@/actions/hospitalActions';
import * as branchActions from '@/actions/branchActions';
import { Button, message, Select, Space, Tag } from 'antd';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';
import { Modal } from 'antd';
import Credentials from '../../Shared/Credentials';
import { ArrowLeftOutlined } from '@ant-design/icons';
import VerifyEmailOrPhoneModal from '../../Shared/VerifyEmailOrPhoneModal';

const CreateMember = (props) => {
    const {
        branch,
        open,
        pharmacy,
        profile,
        getLocations,
        setCreatePharmacistModal,
        branchActions,
        actions
    } = props;
    const [step, setStep] = useState(1);
    const [emailMessage, setEmailMessage] = useState(null);
    const [phoneNoMessage, setphoneNoMessage] = useState(null);
    const [countryCodeMessage, setCountryCodeMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fnameMessage, setfnameMessage] = useState(null);
    const [lnameMessage, setlnameMessage] = useState(null);
    const [pharmacistRegistrationNumberMessage, setBnmcRegistrationNumberMessage] = useState(null);
    const [verifyModal, setVerifyModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [countryCode, setCountryCode] = useState({
        value: localStorage.getItem('calling_code')
            ? localStorage.getItem('calling_code').replace('+', '')
            : '',
        message: '',
        isValid: true
    });

    const [memberInputs, setMemberInputs] = useState({
        username: '',
        firstName: '',
        countryCode: '',
        lastName: '',
        email: '',
        phoneNo: '',
        pharmacyId: '',
        branch: '',
        pharmacistRegistrationNumber: ''
    });

    useEffect(() => {
        if (props.profile) {
            setMemberInputs({ ...memberInputs, pharmacyId: props.profile.id });
        }
    }, [props.profile]);

    const handleInputChange = (e) => {
        if (e.target.name === 'phoneNo') {
            if (
                (e.target.value.length <= 10 && e.target.value.match(/^[0-9]+$/)) ||
                e.target.value.length == 0
            ) {
                if (countryCode.value) {
                    setMemberInputs({
                        ...memberInputs,
                        phoneNo: e.target.value
                    });
                    setPassMessage('');
                }
            }
        } else {
            if (e.target.name == 'email') {
                if (e.target.value.length > 0) {
                    if (!validator.isEmail(e.target.value)) {
                        setEmailMessage('Not a valid Email address');
                    } else {
                        setEmailMessage('');
                    }
                } else if (memberInputs.phoneNo == '') {
                    if (e.target.value == 0) {
                        setEmailMessage('Email is required if phone number is not provided');
                    } else {
                        setEmailMessage('');
                    }
                }
            }

            if (e.target.name == 'firstName') {
                if (e.target.value) {
                    setfnameMessage('');
                } else {
                    setfnameMessage('First name is required');
                }
            }
            if (e.target.name == 'lastName') {
                if (e.target.value) {
                    setlnameMessage('');
                } else {
                    setlnameMessage('Last name is required');
                }
            }

            if (e.target.name == 'pharmacistRegistrationNumber') {
                if (e.target.value) {
                    setBnmcRegistrationNumberMessage('');
                } else {
                    setBnmcRegistrationNumberMessage('BNMC Registration number is required');
                }
            }

            setMemberInputs({
                ...memberInputs,
                [e.target.name]: e.target.value
            });
        }
    };

    const onSumbitInputs = async () => {
        let errors = false;
        console.log('clicked here');
        if (branch?.pharmacist) {
            if (!memberInputs.firstName) {
                setfnameMessage('First Name is required');
                errors = true;
            } else {
                setfnameMessage(null);
            }
            if (!memberInputs.lastName) {
                setlnameMessage('Last Name is required');
                errors = true;
            } else {
                setlnameMessage(null);
            }

            if (!memberInputs.email) {
                if (!memberInputs.phoneNo) {
                    setEmailMessage('Email is required');
                    errors = true;
                }
            } else if (memberInputs.email && !validator.isEmail(memberInputs.email)) {
                setEmailMessage('Email is not valid');
                errors = true;
            } else {
                setEmailMessage(null);
                setphoneNoMessage(null);
                setErrorMessage(null);
            }
            if (!memberInputs.phoneNo) {
                if (!memberInputs.email) {
                    setphoneNoMessage('Phone No is required');
                    errors = true;
                }
            } else {
                setEmailMessage(null);
                setphoneNoMessage(null);
                setErrorMessage(null);
            }

            if (memberInputs.phoneNo && !countryCode.value) {
                setCountryCodeMessage('Country code is required when phone no is provided');
                errors = true;
                return;
            } else {
                setCountryCodeMessage(null);
            }

            if (
                (memberInputs.phoneNo || memberInputs.email) &&
                memberInputs.firstName &&
                memberInputs.lastName &&
                !errors
            ) {
                if (memberInputs.email === '') {
                    if (memberInputs.phoneNo.length !== 10) {
                        setphoneNoMessage('Phone No is not valid');
                        return;
                    } else {
                        setphoneNoMessage(null);
                    }
                }

                setErrorMessage(null);
                let obj = {};
                obj = {
                    branchId: branch._id,
                    userId: props.auth.user._id,
                    userType: 'PHARMACY',
                    minFieldsCompleted: true,
                    pharmacist: {
                        ...branch.pharmacist,
                        email: memberInputs.email,
                        firstName: memberInputs.firstName,
                        lastName: memberInputs.lastName,
                        pharmacistRegistrationNumber: memberInputs.pharmacistRegistrationNumber
                    }
                };

                if (memberInputs.phoneNo && countryCode.value) {
                    obj.phoneNo = memberInputs.phoneNo;
                    obj.countryCode = countryCode.value;
                }

                let newPharmacist = await actions.saveBranches({ ...obj }, props.history);
                if (newPharmacist) {
                    message.success('Pharmacist added');

                    getLocations();
                    closeModal();
                }
            } else {
                message.error('Fill all the required fields!');
            }
        }
    };

    const closeModal = () => {
        setCreatePharmacistModal(false, null);
        setEmailMessage(null);
        setphoneNoMessage(null);
        setfnameMessage(null);
        setlnameMessage(null);

        setMemberInputs({
            username: '',
            firstName: '',
            countryCode: '',
            lastName: '',
            title: '',
            email: '',
            phoneNo: '',
            pharmacyId: '',
            pharmacistRegistrationNumber: '',
            branch: ''
        });
    };

    useEffect(() => {
        if (open && branch?.pharmacist) {
            const pharmacist = branch.pharmacist;
            setMemberInputs({
                firstName: pharmacist.firstName || '',
                countryCode: pharmacist.countryCode || '',
                lastName: pharmacist.lastName || '',
                email: pharmacist.email || '',
                phoneNo: pharmacist.phoneNo || '',
                pharmacyId: branch.pharmacy,
                pharmacistRegistrationNumber: pharmacist.pharmacistRegistrationNumber || '',
                branch: branch._id
            });
        }
    }, [open]);

    if (!open) return null;

    let isEmailVerified = branch?.pharmacist?.isEmailVerified;
    let isPhoneVerified = branch?.pharmacist?.isPhoneVerified;

    console.log('pharmacist', branch.pharmacist)
    return (
        <Modal
            centered
            className='customModal '
            title={`${
                branch?.pharmacist?.firstName ? 'Edit' : 'Add'
            } Pharmacist`}
            open={open}
            onOk={() => {
                closeModal();
            }}
            onCancel={() => {
                closeModal();
            }}
            width={1000}
            maskClosable={false}
            footer={null}
        >
            <>
                {step === 1 ? (
                    <>
                        <p style={{ textAlign: 'center', fontSize: 14, marginBottom: 20 }}>
                            *Either Phone number or Email field is required.
                        </p>

                        <div className='post-modal-options-grid2'>
                            <div className='inputHolder'>
                                <p>
                                    First Name<span style={{ color: 'var(--primary)' }}>*</span>:
                                </p>

                                <div className='pmodal-name-cat-time'>
                                    <input
                                        value={memberInputs.firstName}
                                        placeholder='First name'
                                        name='firstName'
                                        onChange={handleInputChange}
                                        autoComplete='hackx'
                                    />
                                </div>
                                {fnameMessage ? (
                                    <p style={{ color: 'red', fontSize: 11 }}>{fnameMessage}</p>
                                ) : null}
                            </div>
                            <div className='inputHolder'>
                                <p>
                                    Last Name<span style={{ color: 'var(--primary)' }}>*</span>:
                                </p>
                                <div className='pmodal-name-cat-time'>
                                    <input
                                        value={memberInputs.lastName}
                                        placeholder='Last name'
                                        name='lastName'
                                        onChange={handleInputChange}
                                        autoComplete='hackx'
                                    />
                                </div>
                                {lnameMessage ? (
                                    <p style={{ color: 'red', fontSize: 11 }}>{lnameMessage}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className='post-modal-options-grid2'>
                            <div className='inputHolder'>
                                <p>Pharmacist Registration Number:</p>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '1rem'
                                    }}
                                >
                                    <div className='pmodal-name-cat-time2'>
                                        <input
                                            // disabled={disable.email}
                                            placeholder='Pharmacist Registration Number'
                                            autoComplete='hackx'
                                            name='pharmacistRegistrationNumber'
                                            value={memberInputs.pharmacistRegistrationNumber}
                                            onChange={handleInputChange}
                                            style={{ width: '100%', backgroundColor: 'white' }}
                                        />
                                    </div>
                                </div>
                                {pharmacistRegistrationNumberMessage ? (
                                    <p style={{ color: 'red', fontSize: 11 }}>
                                        {pharmacistRegistrationNumberMessage}
                                    </p>
                                ) : null}
                            </div>
                        </div>

                        <div className='post-modal-options-grid2'>
                            <div className='inputHolder'>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: 20,
                                        marginBottom: 5
                                    }}
                                >
                                    <p>Email:</p>{' '}
                                    {isEmailVerified ? (
                                        <Tag>Verified</Tag>
                                    ) : (
                                        <Button
                                            style={{ padding: '3px 10px', fontSize: 12 }}
                                            type='text'
                                            onClick={() => setVerifyModal('email')}
                                        >
                                            Verify
                                        </Button>
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '1rem'
                                    }}
                                >
                                    <div className='pmodal-name-cat-time2'>
                                        <input
                                            // disabled={disable.email}
                                            placeholder='Email'
                                            autoComplete='hackx'
                                            name='email'
                                            value={memberInputs.email}
                                            onChange={handleInputChange}
                                            style={{ width: '100%', backgroundColor: 'white' }}
                                        />
                                    </div>
                                </div>
                                {emailMessage ? (
                                    <p style={{ color: 'red', fontSize: 11 }}>{emailMessage}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className='post-modal-options-grid2'>
                            <div className='inputHolder'>
                                <p>Country Code:</p>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '1rem'
                                    }}
                                >
                                    <div className='pmodal-name-cat-time3'>
                                        <PhoneInput
                                            inputStyle={{
                                                width: '100% !important',
                                                padding: '0px 0px !important'
                                            }}
                                            style={{ width: '6rem' }}
                                            // country={"us"}
                                            value={countryCode.value}
                                            placeholder='Country Code'
                                            onChange={(code) =>
                                                setCountryCode({ ...countryCode, value: code })
                                            }
                                        />
                                    </div>
                                </div>
                                {countryCodeMessage ? (
                                    <p style={{ color: 'red', fontSize: 11 }}>
                                        {countryCodeMessage}
                                    </p>
                                ) : null}
                            </div>

                            <div className='inputHolder'>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: 20,
                                        marginBottom: 5
                                    }}
                                >
                                    <p>Phone no.:</p>
                                    {isPhoneVerified ? (
                                        <Tag>Verified</Tag>
                                    ) : (
                                        <Button
                                            style={{ padding: '3px 10px', fontSize: 12 }}
                                            type='text'
                                            onClick={() => setVerifyModal('sms')}
                                        >
                                            Verify
                                        </Button>
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '1rem'
                                    }}
                                >
                                    <div className='pmodal-name-cat-time'>
                                        <input
                                            autoComplete='hackx'
                                            className='doctor-phoneNo-input'
                                            placeholder='Phone no.'
                                            name='phoneNo'
                                            value={memberInputs.phoneNo}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                {phoneNoMessage ? (
                                    <p style={{ color: 'red', fontSize: 11 }}>{phoneNoMessage}</p>
                                ) : null}
                            </div>
                        </div>

                        <Space style={{ marginBottom: 20, marginLeft: 'auto' }}>
                            <Button type='default' onClick={() => closeModal()}>
                                <p>Cancel</p>
                            </Button>
                            <Button onClick={() => setStep(2)} type='default'>
                                <p>Credentials</p>
                            </Button>
                            <Button type='primary' onClick={onSumbitInputs}>
                                {branch?.pharmacist?.firstName ? 'Update' : 'Add'}
                            </Button>
                        </Space>
                    </>
                ) : (
                    <>
                        <Space>
                            <Button
                                type='text'
                                style={{ fontSize: 16, width: 'fit-content' }}
                                onClick={() => setStep(1)}
                            >
                                <ArrowLeftOutlined />
                            </Button>
                            <h1 style={{ fontSize: 16, width: 'fit-content', marginBottom: 0 }}>
                                Credentials
                            </h1>
                        </Space>

                        <Credentials showHeader={false} branch={branch} />
                    </>
                )}
            </>
            {verifyModal && (
                <VerifyEmailOrPhoneModal
                    branchId={branch._id}
                    email={memberInputs.email}
                    open={verifyModal}
                    onCancel={() => setVerifyModal(false)}
                    phoneNo={memberInputs.phoneNo}
                    countryCode={countryCode.value}
                    channelProp={verifyModal}
                    getLocations={getLocations}
                />
            )}
        </Modal>
    );
};
function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        profile: state.auth.userProfile,
        general: state.general,
        hospitalServices: state.general && state.general.hospitalServices
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch),
        hospitalActions: bindActionCreators(hospitalActions, dispatch),
        branchActions: bindActionCreators(branchActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateMember);
