import React, { useState, useEffect } from 'react';
import * as paymentActions from '@/actions/paymentActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button, Tag } from 'antd';
import Table from '@/components/NewProfile/Shared/Table';
import PageHeader from '@/components/NewProfile/Shared/PageHeader';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import PaymentHeader from './PaymentHeader';
import ConfirmModal from '@/components/modals/ConfirmModal';

const Payments = (props) => {
    const dispatch = useDispatch();
    const { userType } = useSelector((state) => state.auth);
    const { myPayments, myPaymentsCount } = useSelector((state) => state.payments);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const [modalOpen, setModalOpen] = useState(false);
    const modalClose = () => {
        setModalOpen(false);
    };
    const handleDelete = () => {};

    const getPayments = async () => {
        let obj = {
            page,
            limit
        };
        await dispatch(paymentActions.getMyPayments({ ...props, obj }));
    };

    useEffect(() => {
        getPayments();
    }, [limit, page]);

    let columns = [
        {
            title: 'Date',
            width: 150,
            dataIndex: 'createdAt',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.date) return -1; // Handle empty values
                if (!b.date) return 1; // Handle empty values
                return a.date.localeCompare(b.date);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div></div>;
                return (
                    <div>
                        {new Date(date).toLocaleDateString('en-us', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        })}
                    </div>
                );
            }
        },
        {
            title: 'Time',
            width: 100,
            dataIndex: 'createdAt',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.date) return -1; // Handle empty values
                if (!b.date) return 1; // Handle empty values
                return a.date.localeCompare(b.date);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div></div>;
                return (
                    <div>
                        {new Date(date).toLocaleTimeString('en-us', {
                            hour: 'numeric',
                            minute: 'numeric'
                        })}
                    </div>
                );
            }
        }
    ];
    if (userType != "HOSPITAL") {
        columns = [
            ...columns,
            {
                title: 'Hospital',
                width: 300,
                sorter: (a, b) => {
                    if (isEmptyObject(a) || isEmptyObject(b)) {
                        return 0; // Return 0 for empty objects to indicate no sorting
                    }
                    if (!a.hospital?.hospitalName) return -1; // Handle empty values
                    if (!b.hospital?.hospitalName) return 1; // Handle empty values
                    return a.hospital?.hospitalName.localeCompare(b.hospital?.hospitalName);
                },
                onHeaderCell: () => {
                    return {
                        style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                    };
                },
                render: (_, record) => {
                    if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                    return <div>{record?.hospital?.hospitalName || 'N/A'}</div>;
                }
            }
        ];
    }

    if (userType != "DOCTOR") {
        columns = [
            ...columns,
            {
                title: 'Doctor',
                width: 300,
                dataIndex: 'doctorName',
                sorter: (a, b) => {
                    if (isEmptyObject(a) || isEmptyObject(b)) {
                        return 0; // Return 0 for empty objects to indicate no sorting
                    }
                    if (!a.member?.firstName) return -1; // Handle empty values
                    if (!b.member?.firstName) return 1; // Handle empty values
                    return a.member?.firstName.localeCompare(b.member?.firstName);
                },
                onHeaderCell: () => {
                    return {
                        style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                    };
                },
                render: (_, record) => {
                    if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                    let fullName = record?.member?.firstName + ' ' + record?.member?.lastName;
                    return <div>{fullName}</div>;
                }
            }
        ];
    }

    if (userType != "PATIENT") {
        columns = [
            ...columns,
            {
                title: 'Patient',
                width: 300,
                sorter: (a, b) => {
                    if (isEmptyObject(a) || isEmptyObject(b)) {
                        return 0; // Return 0 for empty objects to indicate no sorting
                    }
                    if (!a.user?.firstName) return -1; // Handle empty values
                    if (!b.user?.firstName) return 1; // Handle empty values
                    return a.user?.firstName.localeCompare(b.user?.firstName);
                },
                onHeaderCell: () => {
                    return {
                        style: { whiteSpace: 'pre-wrap', minWidth: 300 }
                    };
                },
                render: (user, record) => {
                    if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                    let fullName = record?.user?.firstName + ' ' + record?.user?.lastName;
                    return <div>{fullName}</div>;
                }
            }
        ];
    }

    columns = [
        ...columns,
        {
            title: 'Appointment Mode',
            width: 200,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a?.appointment?.consultationMode) return -1; // Handle empty values
                if (!b?.appointment?.consultationMode) return 1; // Handle empty values
                return a?.appointment?.consultationMode.localeCompare(
                    b?.appointment?.consultationMode
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div></div>;
                return <Tag>{record?.appointment?.consultationMode}</Tag>;
            }
        },
        {
            title: 'Appointment Date',
            width: 100,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a?.appointment?.appointmentDate) return -1; // Handle empty values
                if (!b?.appointment?.appointmentDate) return 1; // Handle empty values
                return a?.appointment?.appointmentDate.localeCompare(
                    b?.appointment?.appointmentDate
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                if (!record?.appointment?.appointmentDate) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(record?.appointment?.appointmentDate).toLocaleDateString(
                            'en-us',
                            {
                                hour: 'numeric',
                                minute: 'numeric'
                            }
                        )}
                    </div>
                );
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 100,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.amount) return -1; // Handle empty values
                if (!b.amount) return 1; // Handle empty values
                return a.amount - b.amount;
            },
            render: (amount, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>{amount}</div>;
            }
        },
        {
            title: 'Payment Mode',
            width: 200,
            dataIndex: 'paymentMode',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.mode) return -1; // Handle empty values
                if (!b.mode) return 1; // Handle empty values
                return a.mode.localeCompare(b.mode);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            },
            render: (mode, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <Tag>{mode}</Tag>;
            }
        },
        {
            title: 'Payment Status',
            width: 120,
            dataIndex: 'status',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0;
                }
                if (!a.status) return -1;
                if (!b.status) return 1;
                return a.status.localeCompare(b.status);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            },
            render: (status, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <Tag>{status}</Tag>;
            }
        },
        {
            title: 'Action',
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                            columnGap: 10
                        }}
                    >
                        <Button type='default'> Edit </Button>
                        <Button type='default' onClick={() => setModalOpen(true)}>
                            Delete{' '}
                        </Button>
                    </div>
                );
            }
        }
    ];

    const numberOfBlankRows = limit - myPayments.length;
    let dataSource = [...myPayments, ...Array.from({ length: numberOfBlankRows }).map(() => ({}))];
    return (
        <div>
            <PageHeader heading='Payments' />

            <PaymentHeader />

            <Table
                columns={columns}
                data={dataSource}
                pagination={{
                    showQuickJumper: true,
                    current: page,
                    total: myPaymentsCount,
                    onChange: (val) => setPage(val),
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    showSizeChanger: true,
                    responsive: true,
                    onShowSizeChange: (size, newSize) => setLimit(newSize)
                }}
            />

            {modalOpen && (
                <ConfirmModal
                    onClose={modalClose}
                    acceptMethod={handleDelete}
                    headerText='Cancel Payment'
                    bodyText='Are you sure you want to cancel this payment?'
                />
            )}
        </div>
    );
};

export default Payments;
