import React, { useState, useEffect } from 'react';
import './Card.css';
import Building from '@/assets/images/buildings.jpg';
import {
    CalendarOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    GlobalOutlined,
    MailOutlined,
    PhoneOutlined,
    QrcodeOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { FaXTwitter } from 'react-icons/fa6';
import { Button, Divider, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import * as prospectActions from '@/actions/prospectActions';
import { useDispatch, useSelector } from 'react-redux';
import * as messagingActions from '@/actions/messagingActions';
import { FaLinkedinIn, FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa';
import { message as toast } from 'antd';
import MessageModal from '../../common/MessageModal';
import { setRegister } from '../../Auth/AuthHelper';
import { useHistory } from 'react-router-dom';
import ButtonGroup from 'antd/es/button/button-group';
import QrCodeModal from '../Common/QrCodeModal';
import { IoChatbubbleOutline } from 'react-icons/io5';

const onPremise = [
    'Emergency Department',
    'Radiology Department',
    'Laboratory',
    'Pharmacy',
    'Intensive Care Unit',
    'Operation Theatre',
    'Ambulance Service',
    'Blood Bank',
    'Cafeteria',
    'Prayer Room',
    'Waiting Area',
    'Parking'
];

const HospitalCard = (props) => {
    const history = useHistory();
    const [qrModal, setQrModal] = useState(false);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [alreadyAddedToProspect, setAlreadyAddedToProspect] = useState(false);

    const { hospital } = props;
    const { branch, practiceAreas } = hospital;
    const links = {
        share: <ShareAltOutlined style={{ fontSize: 15 }} />,
        linkedin: <FaLinkedinIn style={{ fontSize: 15 }} />,
        facebook: <FaFacebookSquare style={{ fontSize: 15 }} />,
        twitter: <FaXTwitter style={{ fontSize: 15 }} />,
        instagram: <FaInstagram style={{ fontSize: 15 }} />
    };

    useEffect(() => {
        if (auth?.isAuthenticated && auth?.userType == 'PATIENT') {
            checkIfProspectAdded();
        }
    }, []);

    const checkIfProspectAdded = async () => {
        if (auth?.userType == 'PATIENT') {
            let obj = {
                user: hospital && hospital._id,
                userType: 'HOSPITAL',
                prospect: auth.userId,
                prospectType: auth.userType,
                prospectTypeName: 'PATIENT'
            };
            let addToProspect = await dispatch(
                prospectActions.checkIfProspectAdded({ ...props, obj }, history)
            );

            if (addToProspect) {
                setAlreadyAddedToProspect(true);
            }
        }
    };

    const addToProspects = async () => {
        let obj = {
            user: hospital?._id,
            userType: 'HOSPITAL',
            prospect: auth?.userId,
            prospectType: auth?.userType,
            prospectTypeName: 'PATIENT',
            hospital: hospital?._id
        };
        if (!alreadyAddedToProspect && auth?.userType == 'PATIENT') {
            await dispatch(prospectActions.addToProspects({ ...props, obj }, history));
        }
    };
    const sendMessage = async () => {
        if(!auth.isAuthenticated){
            dispatch(setRegister(true));
            return;
        }
        let obj = {
            message: message,
            receiver: hospital._id,
            receiverType: 'HOSPITAL'
        };
        let sendMessage = await dispatch(
            messagingActions.createConversation({ ...props, obj }, history)
        );
        if (sendMessage) {
            setMessageModal(false);
            setMessage('');
        } else {
            console.log('error');
        }
    };

    const requestAppointment = () => {
        if (!auth.isAuthenticated) {
            dispatch(setRegister(true));
            return;
        }
        if (auth.userType !== 'PATIENT') {
            toast.error('Only patients can book appointments');
            return;
        }
        if (auth.isAuthenticated && auth.userType == 'PATIENT') {
            addToProspects();
            history.push(
                `/patient/appointments/new?step=2&hospital=${hospital._id}&branch=${
                    branch && branch._id
                }`
            );
        }
    };

    const goToSite = async () => {
        if (branch && branch.website) {
            window.open(`https://${branch && branch.website && branch.website}`);
        }
    };

    const visitSocials = (social) => {
        addToProspects();
        hospital.social?.[social] && window.open(`https://${hospital.social[social]}`);
    };

    const goToProfile = () => {
        addToProspects();
        history.push(
            `/hospital-view/${hospital._id}${
                hospital.branch && hospital.branch._id && `?branchId=${hospital.branch._id}`
            }`
        );
    };

    const handleShare = async () => {
        let urlString = window.location.origin + `/hospital-view/${hospital._id}`;
        if (hospital?.branch?._id) {
            urlString += `?branchId=${hospital.branch._id}`;
        }
        if (navigator.share) {
            try {
                await navigator.share({
                    title: hospital.hospitalName,
                    text: 'Check out this content!',
                    url: urlString
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content', error);
            }
        } else {
            console.log('Web Share API is not supported in your browser.');
        }
    };

    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };
    const makeCall = async () => {
        const phone = `${branch.countryCode}${branch.phoneNo}`;
        window.open(`tel:${phone}`);
    };
    return (
        <div className='hospital-card'>
            <div className='hospital-card-image' onClick={goToProfile}>
                <img
                    className='agency-building-image'
                    src={hospital?.coverImage || Building}
                    alt='building'
                />
                <div className='hospital-card-overlay'></div>
                <Tooltip
                    title={hospital.reviewCount === 0 ? `No Reviews` : `View Reviews`}
                    onClick={() => {
                        addToProspects();
                        history.push(
                            `/hospital-view/${hospital._id}${
                                hospital.branch &&
                                hospital.branch._id &&
                                `?branchId=${hospital.branch._id}&tab=reviews`
                            }`
                        );
                    }}
                >
                    <div className='ratings'>
                        {hospital.reviewCount === 0
                            ? 'No Reviews'
                            : `${hospital?.averageRating} ***** (${hospital?.reviewCount})`}{' '}
                    </div>
                </Tooltip>

                <div className='hospital-card-details'>
                    <p>
                        <strong>{hospital.hospitalName}</strong>
                    </p>
                    <div className='hospital-card-address'>
                        <p>{branch && branch.location && branch.location.branchAddress}</p>
                        <p>
                            {branch && branch.location && (
                                <>
                                    {branch.location.branchCity} , {branch.location.branchState},{' '}
                                    {branch.location.branchZip}, {branch.location.branchCountry}
                                </>
                            )}{' '}
                        </p>
                    </div>
                </div>
            </div>
            <div className='hospital-card-body'>
                <div className='about-container'>
                    <div className='about'>
                        {' '}
                        {branch?.profileSummary?.length > 100
                            ? branch.profileSummary.substring(0, 85) + '...'
                            : branch.profileSummary}
                        {branch?.profileSummary?.length > 100 && (
                            <span
                                className='read-more'
                                onClick={goToProfile}
                                style={{ cursor: 'pointer', color: 'blue' }}
                            >
                                Read more...
                            </span>
                        )}
                    </div>

                    <Button
                        onClick={() => setShowMore((prev) => !prev)}
                        type='text'
                        style={{ padding: 0, position: 'absolute', right: 20, height: 20 }}
                    >
                        {showMore ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    </Button>
                </div>

                <Divider type='horizontal' style={{ margin: '5px 0px' }} />
                {showMore && (
                    <div className='specializations-group'>
                        <div className='specializations'>
                            <div className='title'>Specializations</div>
                            <div className='list'>
                                {practiceAreas.map((specialization, index) => (
                                    <Link
                                        key={index}
                                        to={`hospital-results?practiceAreas=${encodeURIComponent(
                                            specialization
                                        )}`}
                                    >
                                        {specialization}
                                        {index !== practiceAreas.length - 1 && ' | '}
                                    </Link>
                                ))}
                            </div>
                        </div>

                        <div className='specializations'>
                            <div className='title'>On Premise</div>
                            <div className='list'>
                                {onPremise.map((onPremiseItem, index) => (
                                    <a key={index}>
                                        {onPremiseItem}
                                        {index !== onPremiseItem.length - 1 && ' | '}
                                    </a>
                                ))}
                            </div>
                        </div>

                        <div className='socials'>
                            <div className='list'>
                                <Tooltip title={'View QR Code'}>
                                    <Button
                                        style={{
                                            fontSize: 20,
                                            padding: '2px 5px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                        type='text'
                                        onClick={() => setQrModal(true)}
                                    >
                                        <QrcodeOutlined style={{ fontSize: 15 }} />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={'Share'}>
                                    <Button // Add a unique key for each button
                                        type='text'
                                        style={{
                                            padding: '2px 5px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                        disabled={true}
                                        onClick={() => {}}
                                    >
                                        {links['share']}
                                    </Button>
                                </Tooltip>
                                {hospital.shareSocial &&
                                    Object.entries(links)
                                        .slice(1)
                                        .map(([socialMedia, icon]) => (
                                            <Button
                                                key={socialMedia} // Add a unique key for each button
                                                type='text'
                                                style={{
                                                    padding: '2px 5px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                disabled={
                                                    socialMedia === 'share'
                                                        ? false
                                                        : !hospital.social ||
                                                          !hospital.social[socialMedia]
                                                }
                                                onClick={() =>
                                                    socialMedia === 'share'
                                                        ? handleShare()
                                                        : visitSocials(socialMedia)
                                                }
                                            >
                                                {icon}
                                            </Button>
                                        ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className='footer'>
                    <div style={{ fontSize: 12, textAlign: 'left', width: '100%' }}>
                        <strong>Hours</strong>:{' '}
                        <span style={{ color: '#ff4500', fontWeight: 500 }}>Closed</span> . Opens 9
                        AM
                    </div>
                    <div className='actions'>
                        <ButtonGroup>
                            <Button onClick={() => goToSite()} disabled={!branch.website}>
                                <GlobalOutlined />
                            </Button>
                            <Tooltip
                                title={`${
                                    hospital.allowChat
                                        ? `Click to message ${
                                              hospital.firstName && hospital.firstName
                                          } ${hospital.lastName && hospital.lastName}`
                                        : 'Messaging unavailable'
                                }`}
                            >
                                <Button
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    disabled={!(hospital && hospital.allowChat)}
                                    onClick={() => {
                                        if(!auth.isAuthenticated){
                                            console.log('got here')
                                            dispatch(setRegister(true));
                                            return;
                                        }
                                        if (setMessageModal && hospital.allowChat) {
                                            addToProspects();
                                            setMessageModal(true);
                                        }
                                    }}
                                >
                                    <IoChatbubbleOutline />
                                </Button>
                            </Tooltip>
                            <Tooltip title={`${branch && branch.email && branch.email}`}>
                                <Button
                                    onClick={() =>
                                        sendMail(
                                            branch.email && branch.email,
                                            'Contact Hospital',
                                            'Hi there!'
                                        )
                                    }
                                    disabled={!branch && branch.email}
                                >
                                    <MailOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip
                                title={
                                    branch &&
                                    branch.phoneNo &&
                                    branch.countryCode && (
                                        <>
                                            ({branch.countryCode}) - {branch.phoneNo}
                                        </>
                                    )
                                }
                            >
                                <Button
                                    onClick={() => makeCall()}
                                    disabled={!(branch && branch.countryCode && branch.phoneNo)}
                                >
                                    <PhoneOutlined />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                        <Tooltip
                            title={`Request an appointment with ${
                                hospital.hospitalName && hospital.hospitalName
                            }`}
                        >
                            <Button onClick={requestAppointment}>
                                Appointment <CalendarOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <MessageModal
                open={messageModal}
                setOpen={setMessageModal}
                message={message}
                setMessage={setMessage}
                sendMessage={sendMessage}
            />
            <QrCodeModal
                open={qrModal}
                onCancel={() => setQrModal(false)}
                user={hospital}
                userType={'HOSPITAL'}
            />
        </div>
    );
};

export default HospitalCard;
