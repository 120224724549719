import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;
if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getMyAppointments = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/getMyAppointments`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MY_APPOINTMENTS,
                        payload: {
                            appointments: res.data.appointments,
                            appointmentsCount: res.data.appointmentsCount
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    dispatch({
                        type: Types.GET_MY_APPOINTMENTS,
                        payload: {
                            appointments: [],
                            appointmentsCount: 0
                        }
                    });
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const requestAppointmentByDoctor = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/requestAppointmentByDoctor`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const requestAppointmentByMember = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/requestAppointmentByMember`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const requestAppointmentByPatient = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/requestAppointmentByPatient`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const updateAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/updateAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const cancelAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/cancelAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const approveAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/approveAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};


export const getPastAppointmentMembers = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/getPastAppointmentMembers`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_APPOINTMENT_MEMBERS,
                        payload: {
                            members: res.data.members,
                            count: res.data.count
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getPastAppointmentHospitals = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getPastAppointmentHospitals`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_APPOINTMENT_HOSPITALS,
                        payload: {
                            hospitals: res.data.hospitals,
                            lawfirmsCount: res.data.count
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getPastAppointmentDoctors = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getPastAppointmentDoctors`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_APPOINTMENT_DOCTORS,
                        payload: {
                            doctors: res.data.doctors,
                            doctorsCount: res.data.count
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getVideoToken = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/getVideoToken`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_VIDEO_TOKEN,
                        payload: {
                            token: res.data.token
                        }
                    });
                    return resolve(res.data.token);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_VIDEO_TOKEN,
                        payload: {
                            token: ''
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getSingleAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/getSingleAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_SINGLE_APPOINTMENT,
                        payload: {
                            singleAppointment: res.data.appointment
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_SINGLE_APPOINTMENT,
                        payload: {
                            singleAppointment: null
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};
// getAvailableServices

export const getAvailableServices = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/getAvailableServices`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_AVAILABLE_SERVICES,
                        payload: {
                            availableServices: res.data.services
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_AVAILABLE_SERVICES,
                        payload: {
                            availableServices: null
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const payForAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/payForAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const completeAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/completeAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const appointmentCompleted = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/appointmentCompleted`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const noShowAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/noShowAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getMemberForAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/appointments/getMemberForAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MEMBER_FOR_APPOINTMENT,
                        payload: {
                            member: res.data.member
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

