import { Button, Empty } from 'antd';
import React from 'react';
import useNotifications from '../../../../hooks/useNotifications';
import { DateTime } from 'luxon';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { List } from 'antd';
const Notifications = () => {
    const { userType } = useSelector((state) => state.auth);
    const userRoute = `/${userType.toLowerCase()}`;
    const { notifications } = useNotifications();
    console.log('userRoute', userRoute);
    return (
        <div>
            <div
                className='sectionTitle'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.4rem 1rem'
                }}
            >
                <h4 style={{ fontSize: 14, marginBottom: 0, fontWeight: 400 }}>Notifications</h4>
                <Link to={`${userRoute}/notifications`}>
                    <Button type='text' size='small'>
                        View All
                    </Button>
                </Link>
            </div>

            <div
                className='sectionContainer'
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
            >
                <List
                    dataSource={notifications}
                    locale={{ emptyText: <Empty description={'No Notifications available'} /> }}
                    renderItem={(notification) => (
                        <List.Item
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px',
                                padding: '5px 15px',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                borderBottom: '1px solid #f0f0f0' // Adds a border between items, remove if not needed
                            }}
                        >
                            <div style={{width: '100%', textAlign: 'left'}}>{parse(notification.description)}</div>
                            <small style={{ color: 'gray', textAlign: 'left', width: '100%' }}>
                                {DateTime.fromISO(notification.createdDate).toLocaleString(
                                    DateTime.DATETIME_FULL
                                )}
                            </small>
                        </List.Item>
                    )}
                />
           
            </div>
        </div>
    );
};

export default Notifications;
