import React, { Component, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import { Select, message, Spin, Tooltip, Button } from 'antd';
import { FiEdit2 } from 'react-icons/fi';
import validator from 'validator';
import SignUpWIzard from './SignUpWIzard';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Switch, Modal } from 'antd';
import Geolocate from '../../MiniComponents/Geolocate';
import { geocodeByAddress } from 'react-places-autocomplete';
import { AiOutlinePlus, AiOutlineUpload } from 'react-icons/ai';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ChangePhoneOrEmail from '../../modals/ChangePhoneOrEmail';
import malePlaceholder from '@/assets/img/malePlaceholder.png';
import femalePlaceholder from '@/assets/img/femalePlaceholder.jpg';
import ConfirmModal from '../../modals/ConfirmModal';
import * as stripeActions from '@/actions/stripeActions';
import ChangePassword from '../../modals/ChangePassword';
import {
    CoverImage,
    ProfileImage,
    NavPrompt,
    CountryCodeDropdown,
    Input
} from '../Shared/ProfileComponents';
import { SocialSection, AccountSettings } from '../Shared/ProfileSections';
import ProfileSection from '../DoctorProfile/ProfileSection';
import { PharmacyTypes } from '../../../assets/json/PharmacyTypes';

// let stripePK =
//     'pk_test_51Jat9xGdKelfS2valqmKF2YldfpvfxDlazZUvA6joCR5PAABvEwf3WzpwpmjPO6rUnjY2Bhzz342eHNIRJc13EQ600VeqkMeCR';

let stripePK =
    'pk_test_51OshNaP1wHcK7uYtaTkzbHiW99wgFGC1Tuxh6DyknlFbhKCk891aLsuL6ch5FdrlAe6fDPWOqPxT1TggnFgmR4nM007RIFoAkg';

const stripePromise = loadStripe(stripePK);

const { REACT_APP_API_URI } = process.env;
const { Option } = Select;
class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstLoad: true,
            locality: localStorage.getItem('locality') ? localStorage.getItem('locality') : '',
            localityCountry: localStorage.getItem('localityCountry')
                ? localStorage.getItem('localityCountry')
                : '',
            formDirty: false,
            firstName: { value: '', message: '', isValid: true },
            lastName: { value: '', message: '', isValid: true },
            phoneNo: { value: '', message: '', isValid: true },
            countryCode: { value: '', message: '', isValid: true },
            email: { value: '', message: '', isValid: true },
            password: { value: '', message: '', isValid: true },
            gender: { value: '', message: '', isValid: true },
            language: { value: '', message: '', isValid: true },
            currency: { value: '', message: '', isValid: true },
            dob: { value: '', message: '', isValid: true },
            allowChat: false,
            shareSocial: false,
            social: { facebook: '', instagram: '', twitter: '', linkedin: '' },
            toolTip: '',

            wizard: false,
            coverImage: null,
            tempCoverImage: null,
            profileImage: null,
            tempProfileImage: null,
            formDPDirty: false,
            isSignUpWizardCompleted: false,
            chatInputs: [],
            address: { value: '', isValid: true, message: '' },
            addressLineOne: { value: '', isValid: true, message: '' },
            billingAddress: { value: '', isValid: true, message: '' },
            billingCity: { value: '', isValid: true, message: '' },
            billingState: { value: '', isValid: true, message: '' },
            billingZip: { value: '', isValid: true, message: '' },
            billingCountry: { value: '', isValid: true, message: '' },
            billingEmail: { value: '', isValid: true, message: '' },
            billingPhone: { value: '', isValid: true, message: '' },
            billingCountryCode: { value: '', isValid: true, message: '' },
            billingCoordinates: [],
            pharmacyName: { value: '', isValid: true, message: '' },
            contactEmail: { value: '', message: '', isValid: true },
            contactPhone: { value: '', message: '', isValid: true },
            contactCountryCode: { value: '', message: '', isValid: true },
            profileSummary: { value: '', isValid: true, message: '' },
            profileCompletion: {},
            pharmacyRegistrationNumber: { value: '', isValid: true, message: '' },
            pharmacyType: { value: '', isValid: true, message: '' },
            websiteName: { value: '', isValid: true, message: '' },
            disablePharmacyName: true,
            disableRegNo: true,
            disablePhone: true,
            disableEmail: true,
            disablePass: true,
            hourlyRate: 0,
            appointmentBlock: { value: 60, message: '', isValid: true },
            oldPassword: { value: '', message: '', isValid: true },
            newPassword: { value: '', message: '', isValid: true },
            confirmPassword: { value: '', message: '', isValid: true },
            passwordModal: false,
            showConfirmPassword: false,
            showCurrentPassword: false,
            showNewPassword: false,
            genderPlaceholderImg: malePlaceholder,
            deactivateAccountConfirmModal: false,
            deactivateAccountFlag: false,
            formImageDirty: false,
            formDPDirty: false,
            syncGoogleCalendar: false,
            syncOutlookCalendar: false,
            stripeLoading: false
        };
        this.curr = React.createRef();
        this.deleteAddressInfo = this.deleteAddressInfo.bind(this);
        this.changeAddressInfo = this.changeAddressInfo.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.updateBillingAddress = this.updateBillingAddress.bind(this);
        this.setWizard = this.setWizard.bind(this);
        this.handleDisable = this.handleDisable.bind(this);
        this.saveCoverImage = this.saveCoverImage.bind(this);
        this.clearCoverImage = this.clearCoverImage.bind(this);
        this.deleteCoverImage = this.deleteCoverImage.bind(this);
        this.deleteProfileImage = this.deleteProfileImage.bind(this);
        this.saveProfileImage = this.saveProfileImage.bind(this);
        this.handleAddInput = this.handleAddInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRemoveInput = this.handleRemoveInput.bind(this);
        this.closePasswordModal = this.closePasswordModal.bind(this);
        this.handleSelectionCanadaPost = this.handleSelectionCanadaPost.bind(this);
        this.syncGoogleCalendar = this.syncGoogleCalendar.bind(this);
        this.syncOutlookCalendar = this.syncOutlookCalendar.bind(this);
        this.deactivateAccount = this.deactivateAccount.bind(this);
        this.connectStripe = this.connectStripe.bind(this);
        this.handleEnable = this.handleEnable.bind(this);
    }

    handleInputChange = (e, index) => {
        let state = this.state;
        const values = [...state.chatInputs];
        values[index][e.target.name] = e.target.value;
        state['chatInputs'] = values;
        state.formDirty = true;
        this.setState(state);
    };

    handleAddInput = () => {
        let state = this.state;
        if (state.chatInputs.length < 3) {
            const values = [...state.chatInputs, { short: '', message: '' }];
            state['chatInputs'] = values;
        }
        state.formDirty = true;
        this.setState(state);
    };
    handleRemoveInput = async (index) => {
        let state = this.state;

        const values = [...state.chatInputs];
        values.splice(index, 1);
        state['chatInputs'] = values;
        state.formDirty = true;
        this.setState(state);
    };

    setWizard = async (data) => {
        this.setState({
            wizard: data
        });
    };
    deactivateAccount = async () => {
        if (this.state.deactivateAccountFlag) {
            let obj = {
                userId: this.props.auth.userId,
                userType: this.props.auth.userType
            };
            let deactivate = await this.props.actions.deactivateAccount(
                { ...this.props, obj },
                this.props.history
            );
            if (deactivate) {
                this.setState({ deactivateAccountConfirmModal: false });
                setTimeout(async () => {
                    let logout = await this.props.actions.logout(this.props.history);
                }, [2000]);
            }
        } else {
            this.setState({ deactivateAccountConfirmModal: true });
        }
    };

    handleEnable(e) {
        console.log(e);
        let state = this.state;
        if (e === 'phoneNo') {
            state.disablePhone = false;
        }
        if (e === 'email') {
            state.disableEmail = false;
        }
        if (e === 'password') {
            state.disablePass = false;
        }
        this.setState(state);
    }

    static getDerivedStateFromProps(props, state) {
        const { profile } = props;
        if (state.formDirty == false) {
            if (
                props.auth.isAuthenticated &&
                props.auth.user &&
                profile &&
                Object.keys(profile).length > 0
            ) {
                if (profile.firstName) {
                    state.firstName.value = profile.firstName;
                }
                if (profile.isSignUpWizardCompleted) {
                    state.isSignUpWizardCompleted = profile.isSignUpWizardCompleted;
                    state.wizard = false;
                } else if (!state.firstLoad) {
                    state.isSignUpWizardCompleted = false;
                    state.wizard = true;
                }
                state.firstLoad = false;
                if (profile.lastName) {
                    state.lastName.value = profile.lastName;
                }
                if (profile.dob) {
                    state.dob.value = profile.dob;
                }
                if (profile.phoneNo) {
                    state.phoneNo.value = profile.phoneNo;
                }
                if (profile.password) {
                    state.password.value = profile.password;
                }
                if (profile.email) {
                    state.email.value = profile.email;
                }
                if (profile.countryCode) {
                    state.countryCode.value = profile.countryCode;
                }
                if (profile.websiteName) {
                    state.websiteName.value = profile.websiteName;
                }
                if (profile.profileSummary) {
                    state.profileSummary.value = profile.profileSummary;
                }
                if (localStorage.getItem('currency') && !profile.currency) {
                    state.currency.value = localStorage.getItem('currency');
                }

                if (profile.currency) {
                    state.currency.value = profile.currency;
                }

                if (!state.formImageDirty) {
                    if (profile.coverImage) {
                        state.coverImage = profile.coverImage;
                    }
                }

                if (!state.formDPDirty) {
                    if (profile.profileImage) {
                        state.profileImage = profile.profileImage;
                    }
                }
                if (profile.profileImage) {
                    state.profileImage = profile.profileImage;
                }
                if (profile.coverImage) {
                    state.coverImage = profile.coverImage;
                }
                if (profile.social) {
                    state.social.facebook = profile.social?.facebook || '';
                    state.social.instagram = profile.social?.instagram || '';
                    state.social.twitter = profile.social?.twitter || '';
                    state.social.linkedin = profile.social?.linkedin || '';
                }

                if (profile.gender) {
                    state.gender.value = profile.gender;
                    if (profile.gender == 'Male') {
                        state.genderPlaceholderImg = malePlaceholder;
                    } else {
                        state.genderPlaceholderImg = femalePlaceholder;
                    }
                }

                if (profile.dob) {
                    state.dob.value = profile.dob;
                }
                if (profile.language) {
                    state.language.value = profile.language;
                }

                if (profile.currency) {
                    state.currency.value = profile.currency;
                }
                if (profile.chatInputs && !state.formDirty) {
                    state.chatInputs = profile.chatInputs;
                }

                if (profile.appointmentBlock) {
                    state.appointmentBlock.value = profile.appointmentBlock;
                }

                if (profile.contact) {
                    if (profile.contact == null) {
                        if (profile.email) {
                            state.contactEmail.value = profile.email;
                        }
                        if (profile.phoneNo) {
                            state.contactPhone.value = profile.phoneNo;
                        }
                        if (profile.countryCode) {
                            state.contactCountryCode.value = profile.countryCode;
                        }
                    } else {
                        if (profile.contact.email) {
                            state.contactEmail.value = profile.contact.email;
                        } else if (profile.email) {
                            state.contactEmail.value = profile.email;
                        }
                        if (profile.contact.phoneNo) {
                            state.contactPhone.value = profile.contact.phoneNo;
                        } else if (profile.phone) {
                            state.contactPhone.value = profile.phone;
                        }
                        if (profile.contact.countryCode) {
                            state.contactCountryCode.value = profile.contact.countryCode;
                        }

                        if (profile.contact.billing) {
                            if (profile.contact.billing.billingEmail) {
                                state.billingEmail.value = profile.contact.billing.billingEmail;
                            }
                            if (profile.contact.billing.billingPhone) {
                                state.billingPhone.value = profile.contact.billing.billingPhone;
                            }
                            if (profile.contact.billing.billingCountryCode) {
                                state.billingCountryCode.value =
                                    profile.contact.billing.billingCountryCode;
                            }
                            if (profile.contact.billing.billingAddress) {
                                state.billingAddress.value = profile.contact.billing.billingAddress;
                            }
                            if (profile.contact.billing.billingCity) {
                                state.billingCity.value = profile.contact.billing.billingCity;
                            }
                            if (profile.contact.billing.billingState) {
                                state.billingState.value = profile.contact.billing.billingState;
                            }
                            if (profile.contact.billing.billingZip) {
                                state.billingZip.value = profile.contact.billing.billingZip;
                            }
                            if (profile.contact.billing.billingCountry) {
                                state.billingCountry.value = profile.contact.billing.billingCountry;
                            }
                            if (profile.contact.billing.coordinates) {
                                state.billingCoordinates.value =
                                    profile.contact.billing.coordinates;
                            }
                        }
                    }
                }

                state.allowChat = profile.allowChat ? true : false;
                state.shareSocial = profile.shareSocial ? true : false;

                state.firstSave = profile.firstSave ? true : false;

                state.syncGoogleCalendar = profile.syncGoogleCalendar ? true : false;
                state.syncOutlookCalendar = profile.syncOutlookCalendar ? true : false;

                if (profile.pharmacyType) {
                    state.pharmacyType.value = profile.pharmacyType;
                }
                if (profile.pharmacyName) {
                    state.pharmacyName.value = profile.pharmacyName;
                }

                if (profile.pharmacyRegistrationNumber) {
                    state.pharmacyRegistrationNumber.value = profile.pharmacyRegistrationNumber;
                }
            }
        }
        return state;
    }

    componentDidMount() {
        this.props.actions.getProductTypes(this.props, this.props.history);
    }
    handleDisable(e) {
        let state = this.state;
        if (e === 'pharmacyType') {
            state.disablePharmarcyType = !state.disablePharmarcyType;
        }
        if (e === 'pharmacyName') {
            state.disablePharmacyName = !state.disablePharmacyName;
        }
        if (e === 'pharmacyRegistrationNumber') {
            state.disableRegNo = !state.disableRegNo;
        }
        if (e === 'phoneNo') {
            state.disablePhone = !state.disablePhone;
        }
        if (e === 'email') {
            state.disableEmail = !state.disableEmail;
        }
        if (e === 'password') {
            state.disablePass = !state.disablePass;
        }
        if (e === 'disableAll') {
            state.disablePharmacyName = true;
            state.disableRegNo = true;
            state.disablePhone = true;
            state.disableEmail = true;
            state.disablePass = true;
        }
        this.setState(state);
    }
    saveCoverImage = async () => {
        if (this.state.coverImage && this.state.tempCoverImage) {
            let obj = new FormData();
            obj.append('userId', this.props.auth.user._id);
            obj.append('image', this.state.coverImage);
            obj.append('profileImage', false);
            obj.append('coverImage', true);

            let upload = await this.props.actions.saveProfileImageOrCoverImage(
                { obj, ...this.props },
                this.props.history
            );

            if (upload) {
                this.setState({ tempCoverImage: null });
                let profileShort = {
                    userId: this.props.auth.user._id,
                    userType: this.props.auth.user.userType
                };
                await this.props.actions.getProfile(profileShort, this.props);
                this.setState({
                    formImageDirty: false
                });
            }
        }
    };
    async clearCoverImage() {
        this.setState({
            tempCoverImage: null,
            coverImage: null,
            formImageDirty: false
        });
    }
    async deleteCoverImage() {
        let obj = {
            userId: this.props.auth && this.props.auth.user && this.props.auth.user._id,
            coverImage: ''
        };
        let deleteImage = await this.props.actions.deleteCoverImage(
            { ...this.props, obj },
            this.props.history
        );
        if (deleteImage) {
            let profileShort = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            await this.props.actions.getProfile(profileShort, this.props);
            this.setState({
                deleteCover: false,
                formImageDirty: false,
                coverImage: '',
                tempCoverImage: ''
            });
        }
    }

    async deleteProfileImage() {
        let obj = {
            userId: this.props.auth && this.props.auth.user && this.props.auth.user._id,
            coverImage: ''
        };
        let deleteImage = await this.props.actions.deleteProfileImage(
            { ...this.props, obj },
            this.props.history
        );
        if (deleteImage) {
            let profileShort = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            this.setState({
                deleteProfileImage: false,
                formImageDirty: false
            });
            await this.props.actions.getProfile(profileShort, this.props);
            this.setState({
                formImageDirty: false,
                profileImage: '',
                tempProfileImage: '',
                formDPDirty: false
            });
        }
    }

    saveProfileImage = async () => {
        if (this.state.profileImage) {
            let obj = new FormData();
            obj.append('userId', this.props.auth.user._id);
            obj.append('image', this.state.profileImage);
            obj.append('profileImage', true);
            obj.append('coverImage', false);

            let upload = await this.props.actions.saveProfileImageOrCoverImage(
                { obj, ...this.props },
                this.props.history
            );

            if (upload) {
                this.setState({ tempProfileImage: null });
                let profileShort = {
                    userId: this.props.auth.user._id,
                    userType: this.props.auth.user.userType
                };
                await this.props.actions.getProfile(profileShort, this.props);
                this.setState({
                    formDPDirty: false
                });
            }
            // message.success("image uploaded");
        }
    };

    onChange(e, string) {
        var state = this.state;
        if (e && e.target && e.target.name) {
            state[e.target.name].value = e.target.value;
        }
        state.formDirty = true;
        if (!string) {
            if (e.target.name == 'firstName') {
                if (e.target.value == '') {
                    state['firstName'].isValid = false;
                    state['firstName'].message = 'First name cannot be left empty';
                } else {
                    state['firstName'].isValid = true;
                    state['firstName'].message = '';
                }
            }
            if (e.target.name == 'pharmacyName') {
                if (e.target.value == '') {
                    state['pharmacyName'].isValid = false;
                    state['pharmacyName'].message = 'Pharmacy Name cannot be empty';
                } else {
                    state['pharmacyName'].isValid = true;
                    state['pharmacyName'].message = '';
                }
            }
            if (e.target.name == 'pharmacyType') {
                if (e.target.value == '') {
                    state['pharmacyType'].isValid = false;
                    state['pharmacyType'].message = 'Pharmacy Type cannot be empty';
                } else {
                    state['pharmacyType'].isValid = true;
                    state['pharmacyType'].message = '';
                }
            }
            if (e.target.name == 'pharmacyRegistrationNumber') {
                if (e.target.value == '') {
                    state['pharmacyRegistrationNumber'].isValid = false;
                    state['pharmacyRegistrationNumber'].message = 'Pharmacy Name cannot be empty';
                } else {
                    state['pharmacyRegistrationNumber'].isValid = true;
                    state['pharmacyRegistrationNumber'].message = '';
                }
            }
            if (e.target.name == 'lastName') {
                if (e.target.value == '') {
                    state['lastName'].isValid = false;
                    state['lastName'].message = 'First name cannot be left empty';
                } else {
                    state['lastName'].isValid = true;
                    state['lastName'].message = '';
                }
            }

            if (e.target.name == 'contactPhone') {
                if (e.target.value == '') {
                    state['contactPhone'].isValid = false;
                    state['contactPhone'].message = 'Phone number cannot be left empty';
                } else if (e.target.value.length !== 10) {
                    state['contactPhone'].isValid = false;
                    state['contactPhone'].message = 'Phone number must be 10 digits';
                } else if (!e.target.value.match(/^[0-9 -+]+$/)) {
                    state['contactPhone'].isValid = false;
                    state['contactPhone'].message = 'Phone number must only contain numbers';
                } else {
                    state['contactPhone'].isValid = true;
                    state['contactPhone'].message = '';
                }
            }

            if (e.target.name == 'contactEmail') {
                if (e.target.value == '') {
                    state['contactEmail'].isValid = false;
                    state['contactEmail'].message = 'Email cannot be left empty';
                } else if (!validator.isEmail(e.target.value)) {
                    state['contactEmail'].isValid = false;
                    state['contactEmail'].message = 'Not a valid email address';
                } else {
                    state['contactEmail'].isValid = true;
                    state['contactEmail'].message = '';
                }
            }
            if (e.target.name == 'billingPhone') {
                if (e.target.value == '') {
                    state['billingPhone'].isValid = false;
                    state['billingPhone'].message = 'Phone number cannot be left empty';
                } else if (e.target.value.length !== 10) {
                    state['billingPhone'].isValid = false;
                    state['billingPhone'].message = 'Phone number must be 10 digits';
                } else if (!e.target.value.match(/^\d+$/)) {
                    state['billingPhone'].isValid = false;
                    state['billingPhone'].message = 'Phone number must only contain numbers';
                } else {
                    state['billingPhone'].isValid = true;
                    state['billingPhone'].message = '';
                }
            }

            if (e.target.name == 'billingCity') {
                if (e.target.value == '') {
                    state['billingCity'].isValid = false;
                    state['billingCity'].message = 'Billing City cannot be left empty';
                } else {
                    state['billingCity'].isValid = true;
                    state['billingCity'].message = '';
                }
            }
            if (e.target.name == 'billingCountry') {
                if (e.target.value == '') {
                    state['billingCountry'].isValid = false;
                    state['billingCountry'].message = 'Billing Country cannot be left empty';
                } else {
                    state['billingCountry'].isValid = true;
                    state['billingCountry'].message = '';
                }
            }

            if (e.target.name == 'billingState') {
                if (e.target.value == '') {
                    state['billingState'].isValid = false;
                    state['billingState'].message = 'Billing State cannot be left empty';
                } else {
                    state['billingState'].isValid = true;
                    state['billingState'].message = '';
                }
            }

            if (e.target.name == 'billingAddress') {
                if (e.target.value == '') {
                    state['billingAddress'].isValid = false;
                    state['billingAddress'].message = 'Billing Address cannot be left empty';
                } else {
                    state['billingAddress'].isValid = true;
                    state['billingAddress'].message = '';
                }
            }

            if (e.target.name == 'billingZip') {
                if (e.target.value == '') {
                    state['billingZip'].isValid = false;
                    state['billingZip'].message = 'Billing billing zip cannot be left empty';
                } else {
                    state['billingZip'].isValid = true;
                    state['billingZip'].message = '';
                }
            }

            if (e.target.name == 'billingEmail') {
                if (e.target.value == '') {
                    state['billingEmail'].isValid = false;
                    state['billingEmail'].message = 'Email cannot be left empty';
                } else if (!validator.isEmail(e.target.value)) {
                    state['billingEmail'].isValid = false;
                    state['billingEmail'].message = 'Not a valid email address';
                } else {
                    state['billingEmail'].isValid = true;
                    state['billingEmail'].message = '';
                }
            }
            if (e.target.name == 'websiteName') {
                if (e.target.value == '') {
                    state['websiteName'].isValid = false;
                    state['websiteName'].message = 'Website cannot be left empty';
                } else {
                    state['websiteName'].isValid = true;
                    state['websiteName'].message = '';
                }
            }

            if (e.target.name == 'countryCode') {
                if (e.target.value == '') {
                    state['countryCode'].isValid = false;
                    state['countryCode'].message = 'Country code cannot be left empty';
                }

                if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                    state['countryCode'].isValid = false;
                    state['countryCode'].message = 'Country code must only contain numbers';
                }

                if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                    state['countryCode'].isValid = true;
                    state['countryCode'].message = '';
                }
            }

            if (e.target.name == 'contactCountryCode') {
                if (e.target.value == '') {
                    state['contactCountryCode'].isValid = false;
                    state['contactCountryCode'].message = 'Country code cannot be left empty';
                }

                if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                    state['contactCountryCode'].isValid = false;
                    state['contactCountryCode'].message = 'Country code must only contain numbers';
                }
                if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                    state['contactCountryCode'].isValid = true;
                    state['contactCountryCode'].message = '';
                }
            }

            if (e.target.name == 'billingCountryCode') {
                if (e.target.value == '') {
                    state['billingCountryCode'].isValid = false;
                    state['billingCountryCode'].message = 'Country code cannot be left empty';
                }

                if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                    state['billingCountryCode'].isValid = false;
                    state['billingCountryCode'].message = 'Country code must only contain numbers';
                }
                if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                    state['billingCountryCode'].isValid = true;
                    state['billingCountryCode'].message = '';
                }
            }
        }
        this.setState(state);
    }

    deleteAddressInfo(index) {
        let ind = index;
        let arr = this.state.addressInfo;
        let tempAddressInfo = this.state.addressInfo;
        if (tempAddressInfo.length > 1) {
            let newTemp = tempAddressInfo.splice(index, 1);
            this.setState({ addressInfo: tempAddressInfo });
        }
    }

    changeAddressInfo(e, index) {
        let temp = this.state.addressInfo;
        temp[index].city = e.target.value;
        this.setState({ addressInfo: temp });
    }
    onChangeDate(date, dateString) {
        this.setState({
            formDirty: true,
            dob: { value: dateString, isValid: true, message: '' }
        });
    }

    saveProfile = async (e) => {
        e.preventDefault();
        let state = this.state;

        if (
            state.pharmacyName.value == '' ||
            state.pharmacyRegistrationNumber.value == '' ||
            state.pharmacyType.value == '' ||
            state.firstName.value == '' ||
            state.lastName.value == '' ||
            state.billingAddress.value == '' ||
            state.billingCity.value == '' ||
            state.billingState.value == '' ||
            state.billingZip.value == '' ||
            state.billingCountry.value == ''
        ) {
            message.error('Fill in the required fields');
            return;
        }

        let profileData = {
            data: {
                pharmacyName: state.pharmacyName.value,
                language: state.language.value,
                currency: state.currency.value,
                websiteName: state.websiteName.value,
                profileSummary: state.profileSummary.value,
                pharmacyRegistrationNumber: state.pharmacyRegistrationNumber.value,
                appointmentBlock: state.appointmentBlock.value,
                pharmacyType: state.pharmacyType.value,
                contact: {
                    email: state.contactEmail.value,
                    phoneNo: state.contactPhone.value,
                    countryCode: state.contactCountryCode.value,
                    billing: {
                        billingAddress: state.billingAddress.value,
                        billingCity: state.billingCity.value,
                        billingState: state.billingState.value,
                        billingZip: state.billingZip.value,
                        billingCountry: state.billingCountry.value,
                        billingEmail: state.billingEmail.value,
                        billingPhone: state.billingPhone.value,
                        billingCountryCode: state.billingCountryCode.value,
                        billingCoordinates: {
                            lat: state.billingCoordinates[0],
                            lng: state.billingCoordinates[1]
                        }
                    }
                },
                pharmacyRegNoVerified: 'PENDING',
                social: state.social,
                firstName: state.firstName.value,
                lastName: state.lastName.value,
                email: state.email.value,
                phoneNo: state.phoneNo.value,
                countryCode: state.countryCode.value.replace('+', ''),
                language: state.language.value,
                currency: state.currency.value,
                password: state.password.value,
                chatInputs: state.chatInputs,
                allowChat: this.state.allowChat ? true : false,
                shareSocial: this.state.shareSocial ? true : false
            },
            userType: this.props.auth.user.userType,
            userId: this.props.auth.user._id
        };
        await this.props.actions.saveProfile(profileData, this.props.history);
        this.setState({ formDirty: false });

        let profileDate = {
            userId: this.props.auth.user._id,
            userType: this.props.auth.user.userType
        };
        await this.props.actions.getProfile(profileDate, this.props.history);

        state.formDirty = false;
        this.setState(state);
    };

    closePasswordModal() {
        this.setState({
            oldPassword: { value: '', message: '', isValid: true },
            newPassword: { value: '', message: '', isValid: true },
            confirmPassword: { value: '', message: '', isValid: true },
            passwordModal: false
        });
    }

    async updateBillingAddress(addr) {
        this.setState({ formDirty: true });
        var placeSearch, autocomplete;
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);
        console.log(address);

        address = address[0];
        componentForm.formattedAddress = address.formatted_address;
        for (var i = 0; i < address.address_components.length; i++) {
            var addressType = address.address_components[i].types[0];
            if (componentForm[addressType] == '') {
                let val = address.address_components[i][componentForm[addressType]];
                componentForm[addressType] = address.address_components[i].long_name;
            }
        }

        let state = this.state;
        if (address && address.geometry) {
            // state.billingCoordinates.lat = address.geometry.location.lat();
            // state.billingCoordinates.lng = address.geometry.location.lng();
            state.billingCoordinates = [
                address.geometry.location.lat(),
                address.geometry.location.lng()
            ];
        }

        if (componentForm.country !== '')
            state.billingCountry = {
                value: componentForm.country,
                isValid: true,
                message: ''
            };
        // debugger
        if (componentForm.postal_code !== '')
            state.billingZip = {
                value: componentForm.postal_code,
                isValid: true,
                message: ''
            };
        state.billingCity = {
            value:
                componentForm.postal_town != 'Town' && componentForm.postal_town
                    ? componentForm.postal_town
                    : componentForm.locality != 'locality'
                    ? componentForm.locality
                    : '',
            isValid: true,
            message: ''
        };
        state.billingState = {
            value:
                componentForm.administrative_area_level_1 != 'State '
                    ? componentForm.administrative_area_level_1
                    : '',
            isValid: true,
            message: ''
        };

        if (componentForm.street_number && componentForm.route) {
            state.billingAddress = {
                value: componentForm.street_number + ' ' + componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.route) {
            state.billingAddress = {
                value: componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.formattedAddress) {
            state.billingAddress = {
                value: componentForm.formattedAddress,
                isValid: true,
                message: ''
            };
        }

        this.setState(state);
    }

    handleSelectionCanadaPost(item) {
        let state = this.state;
        state.billingAddress.value = item.Line1 ? item.Line1 : '';
        state.billingAddress.isValid = '';
        state.billingAddress.message = '';
        state.billingCity.value = item.City ? item.City : '';
        state.billingCity.isValid = '';
        state.billingCity.message = '';
        state.billingCountry.value = item.CountryName ? item.CountryName : '';
        state.billingCountry.isValid = '';
        state.billingCountry.message = '';
        state.billingState.value = item.Province ? item.Province : '';
        state.billingState.isValid = '';
        state.billingState.message = '';
        state.billingZip.value = item.PostalCode ? item.PostalCode : '';
        state.billingZip.isValid = '';
        state.billingZip.message = '';
        state.billingCoordinates.value = item.coordinates ? item.coordinates : {};
        if (item.coordinates) {
            // state.billingCoordinates = [item.coordinates.lat,
            //   item.coordinates.lng]
        }

        state.billingCoordinates.isValid = '';
        state.billingCoordinates.message = '';
        state.formDirty = true;
        this.setState(state);
    }

    async syncGoogleCalendar(checked) {
        console.log(checked);
        if (checked) {
            let getAuthUrl = await this.props.actions.getGoogleAuthorizationUrl(
                {},
                this.props.history
            );
        } else {
            let obj = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            let unsync = await this.props.actions.unsyncGoogleCalendar(
                { ...this.props, obj },
                this.props.history
            );
            if (unsync) {
                this.props.actions.getProfile(obj, this.props.history);
            }
        }
    }
    async syncOutlookCalendar(checked) {
        console.log(checked);
        if (checked) {
            let getAuthUrl = await this.props.actions.getOutlookAuthorizationUrl(
                {},
                this.props.history
            );
        } else {
            let obj = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            let unsync = await this.props.actions.unsyncOutlookCalendar(
                { ...this.props, obj },
                this.props.history
            );
            if (unsync) {
                this.props.actions.getProfile(obj, this.props.history);
            }
        }
    }

    async connectStripe() {
        this.setState({ stripeLoading: true });
        let obj = {};
        obj.countryCode = 'US';
        let { url } = await this.props.stripeActions.setupStripeAccount({ obj });
        this.setState({ stripeLoading: false });
        if (url) {
            window.location.assign(url);
        }
    }

    render() {
        const dateFormat = 'YYYY/MM/DD';

        const locationFields = [
            { field: 'billingAddress', label: 'Billing Address' },
            { field: 'billingCountry', label: 'Billing Country' },
            { field: 'billingCity', label: 'Billing City' },
            { field: 'billingState', label: 'Billing State' },
            { field: 'billingZip', label: 'Billing Zip' }
        ];
        console.log('got in profile');
        const { profile } = this.props;
        const state = this.state;
        return (
            <div className='user-profile tab'>
                <CoverImage
                    savedCoverImage={profile.coverImage}
                    tempCoverImage={this.state.tempCoverImage}
                    coverImage={this.state.coverImage}
                    formImageDirty={this.state.formImageDirty}
                    setState={(obj) => {
                        this.setState(obj);
                    }}
                    clearCoverImage={this.clearCoverImage}
                    deleteCoverImage={this.deleteCoverImage}
                    saveCoverImage={this.saveCoverImage}
                />
                <ProfileImage
                    savedProfileImage={profile.profileImage}
                    tempProfileImage={this.state.tempProfileImage}
                    profileImage={this.state.profileImage}
                    genderPlaceholderImg={this.state.genderPlaceholderImg}
                    formDPDirty={this.state.formDPDirty}
                    setState={(obj) => {
                        this.setState(obj);
                    }}
                    saveProfileImage={this.saveProfileImage}
                    deleteProfileImage={this.deleteProfileImage}
                />
                <div className='viewPublicProfile'>
                    <button
                        className=''
                        onClick={() =>
                            this.props.history.push({
                                pathname: `/pharmacy-view/${profile._id}`,
                                state: {
                                    prevPath: 'profile'
                                }
                            })
                        }
                    >
                        View Public Profile
                    </button>
                </div>
                {profile.subscriptionUrlLink && (
                    <Alert
                        message={
                            <a href={profile.subscriptionUrlLink}>
                                Please pay for your subscription
                            </a>
                        }
                        type='error'
                    />
                )}
                <ProfileSection title='Pharmacy Information'>
                    <div className='input-row' style={{ alignItems: 'center' }}>
                        <Input
                            className='fullLabel'
                            value={this.state.pharmacyName.value}
                            label='Pharmacy Name'
                            item={this.state.pharmacyName}
                            name='pharmacyName'
                            placeholder='Pharmacy Name'
                            type='text'
                            maxLength='70'
                            innerContStyles={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem'
                            }}
                            onChange={this.onChange}
                            disabled={this.state.disablePharmacyName}
                            suffix={
                                <div onClick={() => this.handleDisable('pharmacyName')}>
                                    <FiEdit2 />
                                </div>
                            }
                        />
                    </div>
                    <div className='input-row' style={{ alignItems: 'center' }}>
                        <label className='fullWidth'>
                            Pharmacy Type
                            <Select
                                value={state.pharmacyType.value || undefined}
                                placeholder='Pharmacy Type'
                                style={{ width: '100%' }}
                                // disabled={state.disablePharmarcyType}
                                onChange={(value) => {
                                    let state = this.state;
                                    state.formDirty = true;
                                    state.pharmacyType.value = value;
                                    this.setState(state);
                                }}
                            >
                                {PharmacyTypes.map((pharmacyType) => (
                                    <Option key={pharmacyType} value={pharmacyType}>
                                        {pharmacyType}
                                    </Option>
                                ))}
                            </Select>
                        </label>
                    </div>
                    <div className='input-row'>
                        <Input
                            className='one-half'
                            label='Registration No'
                            item={this.state.pharmacyRegistrationNumber}
                            name='pharmacyRegistrationNumber'
                            placeholder='Registration No.'
                            type='text'
                            maxLength='70'
                            innerContStyles={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem'
                            }}
                            onChange={this.onChange}
                            value={this.state.pharmacyRegistrationNumber.value}
                            disabled={this.state.disableRegNo}
                            suffix={
                                <div
                                    onClick={() => this.handleDisable('pharmacyRegistrationNumber')}
                                >
                                    <FiEdit2 />
                                </div>
                            }
                        />
                    </div>
                </ProfileSection>

                <div className='dividerL'></div>
                <ProfileSection title='Billing Contact'>
                    <div className='input-row'>
                        <Input
                            value={this.state.firstName?.value}
                            label='First Name'
                            item={this.state.firstName}
                            name='firstName'
                            placeholder='First Name'
                            type='text'
                            onChange={this.onChange}
                            required
                        />

                        <Input
                            label='Last Name'
                            value={this.state.lastName?.value}
                            item={this.state.lastName}
                            name='lastName'
                            placeholder='Last Name'
                            type='text'
                            onChange={this.onChange}
                            required
                        />
                    </div>

                    <div className='input-row'>
                        <Input
                            label='Email Address'
                            value={this.state.billingEmail.value}
                            item={this.state.billingEmail}
                            name='billingEmail'
                            placeholder='Email address'
                            type='text'
                            onChange={this.onChange}
                        />

                        <label>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Billing Location:</div>
                                <Tooltip
                                    placement='topLeft'
                                    title={`You can use the search function to locate and choose your address`}
                                >
                                    <span
                                        style={{
                                            display: 'flex',
                                            height: 14,
                                            width: 14,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: 8,
                                            fontSize: 11,
                                            backgroundColor: 'lavender'
                                        }}
                                    >
                                        i
                                    </span>
                                </Tooltip>
                            </div>
                            <Geolocate
                                icon={<i className='fa fa-map-marker' />}
                                chooseAddress={(address) => this.updateBillingAddress(address)}
                            />
                        </label>
                    </div>
                    <div className='input-row two-part'>
                        {locationFields?.slice(0, 2).map((item, index) => (
                            <Input
                                key={`${index}-${item?.field}`}
                                className={
                                    !this.state[item.field].isValid ? 'error one-half' : 'one-half'
                                }
                                label={item.label}
                                required
                                item={this.state[item.field]}
                                name={item.field}
                                placeholder={item.label}
                                type='text'
                                value={this.state[item.field]?.value}
                                onChange={this.onChange}
                            />
                        ))}
                    </div>

                    <div className='input-row three-part'>
                        {locationFields?.slice(2, 5).map((item, index) => (
                            <Input
                                key={`${index}-${item?.field}`}
                                className={
                                    !this.state[item?.field].isValid
                                        ? 'error one-half'
                                        : 'one-third'
                                }
                                required
                                label={item?.label}
                                item={this.state[item?.field]}
                                name={item?.field}
                                placeholder={item?.label}
                                type='text'
                                value={this.state[item?.field]?.value}
                                onChange={this.onChange}
                            />
                        ))}
                    </div>
                </ProfileSection>
                <div className='dividerL'></div>

                <SocialSection social={this.state.social} setState={(obj) => this.setState(obj)} />
                <div className='dividerL'></div>
                <ProfileSection title='Pharmacy Settings'>
                    <div className='input-row2'>
                        <div className='user-Chats'>
                            <p>Allow Chat Messaging</p>
                            <div className='user-chat-option'>
                                <p>Enable Chat messaging between logged in users</p>
                                <div className='user-switch'>
                                    <Switch
                                        checked={this.state.allowChat}
                                        onChange={(checked) =>
                                            this.setState({
                                                allowChat: checked,
                                                formDirty: true
                                            })
                                        }
                                    />
                                    {this.state.allowChat ? <p>Enabled</p> : <p>Disabled</p>}
                                </div>
                            </div>
                        </div>
                        {this.state.allowChat && (
                            <div className='user-Chats'>
                                <div onClick={this.handleAddInput} className='chats-add'>
                                    <div className='chats-add-legend'>
                                        <p>Add a custom reply</p>
                                        <sup
                                            style={{
                                                fontStyle: 'italic',
                                                fontSize: 11,
                                                marginLeft: 5,
                                                fontFamily: "'Inter Var'"
                                            }}
                                        >
                                            <i
                                                className='fe fe-info'
                                                onMouseOver={() => {
                                                    this.setState({ toolTip: true });
                                                }}
                                                onMouseOut={() => this.setState({ toolTip: false })}
                                            />
                                            {this.state.toolTip && (
                                                <div className='infoToolTip'>
                                                    <span>
                                                        (You caanot add more than three forms)
                                                    </span>
                                                </div>
                                            )}
                                            {/* (This information will be displayed on your profile) */}
                                        </sup>
                                    </div>
                                    <AiOutlinePlus />
                                </div>

                                {this.state.chatInputs &&
                                    this.state.chatInputs.map((input, index) => (
                                        <div key={index} className='lexp-inputs'>
                                            <div className='lexp-input-conatiner3'>
                                                <input
                                                    autoComplete='sdhfjsd'
                                                    className={
                                                        this.state.chatInputs[index]['short'] !== ''
                                                            ? 'dirty'
                                                            : 'sdsd'
                                                    }
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, index)
                                                    }
                                                    name='short'
                                                    value={this.state.chatInputs[index].short}
                                                />
                                                <span className='lexp-placeholder'>
                                                    {' '}
                                                    Short Form
                                                </span>
                                            </div>
                                            <div className='lexp-input-conatiner4'>
                                                <input
                                                    autoComplete='sdhfjsd'
                                                    className={
                                                        this.state.chatInputs[index]['message'] !==
                                                        ''
                                                            ? 'dirty'
                                                            : 'sdsd'
                                                    }
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, index)
                                                    }
                                                    name='message'
                                                    value={this.state.chatInputs[index].message}
                                                />
                                                <span className='lexp-placeholder'> Message</span>
                                            </div>
                                            <IoCloseCircleOutline
                                                style={{ color: 'red', fontSize: 24 }}
                                                D
                                                onClick={() => this.handleRemoveInput(index)}
                                            />
                                        </div>
                                    ))}
                            </div>
                        )}
                        {/* <div className='user-Chats'>
                            <p>Enable Outlook Connection</p>
                            <div className='user-chat-option'>
                                <p>Suncronized Appointments with Microsoft Outlook</p>
                                <div className='user-switch'>
                                    <Switch
                                        checked={this.state.syncOutlookCalendar}
                                        onChange={(checked) => this.syncOutlookCalendar(checked)}
                                    />
                                    {this.state.syncOutlookCalendar ? (
                                        <p>Enabled</p>
                                    ) : (
                                        <p>Disabled</p>
                                    )}
                                </div>
                            </div>
                        </div> */}

                        {/* <div className='user-Chats'>
                            <p>Enable Google Calendar</p>
                            <div className='user-chat-option'>
                                <p>Syncronize Appointments with Google Calendar</p>
                                <div className='user-switch'>
                                    <Switch
                                        checked={this.state.syncGoogleCalendar}
                                        onChange={(checked) => this.syncGoogleCalendar(checked)}
                                    />
                                    {this.state.syncGoogleCalendar ? (
                                        <p>Enabled</p>
                                    ) : (
                                        <p>Disabled</p>
                                    )}
                                </div>
                            </div>
                        </div> */}
                        <div className='user-Chats'>
                            <p>Enable Social Info Sharing</p>
                            <div className='user-chat-option'>
                                <p>Sharing of social info on profile.</p>
                                <div className='user-switch'>
                                    <Switch
                                        checked={this.state.shareSocial}
                                        onChange={(checked) =>
                                            this.setState({ shareSocial: checked, formDirty: true })
                                        }
                                    />
                                    {this.state.shareSocial ? <p>Enabled</p> : <p>Disabled</p>}
                                </div>
                            </div>
                        </div>
                        {/* <div className='user-Chats'>
                            <p>Enable Stripe</p>
                            <div className='user-chat-option'>
                                <p>Connect with Stripe Account</p>
                                <div className='user-switch'>
                                    <Button
                                        type='primary'
                                        onClick={(checked) => this.connectStripe(checked)}
                                        loading={this.state.stripeLoading}
                                    >
                                        Activate
                                    </Button>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='user-Chats2'>
                            <p>Select Appointment Blocks</p>
                            <div className='user-chat-option'>
                                <p>Select minimum appointment block</p>
                                <div className='user-switch'>
                                    <div
                                        className='user-block-grid'
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '1rem',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <Select
                                            value={this.state.appointmentBlock.value}
                                            onChange={(val) =>
                                                this.setState({
                                                    appointmentBlock: {
                                                        value: val,
                                                        message: '',
                                                        isValid: true
                                                    },
                                                    formDirty: true
                                                })
                                            }
                                            style={{ width: '10rem' }}
                                            placeholder='Select'
                                        >
                                            <Option value={15}>15 min</Option>
                                            <Option value={30}>30 min</Option>
                                            <Option value={60}>60 min</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </ProfileSection>
                <div className='dividerL'></div>

                <AccountSettings
                    disableEmail={this.state.disableEmail}
                    disablePass={this.state.disablePass}
                    disablePhone={this.state.disablePhone}
                    onChange={this.onChange}
                    handleEnable={this.handleEnable}
                    setState={(obj) => this.setState(obj)}
                    saveProfile={this.saveProfile}
                    deactivateAccount={this.deactivateAccount}
                    email={this.state.email}
                    phoneNo={this.state.phoneNo}
                    password={this.state.password}
                    countryCode={this.state.countryCode}
                />
                {this.state.deactivateAccountConfirmModal && (
                    <ConfirmModal
                        {...this.props}
                        acceptMethod={(e) =>
                            this.setState({ deactivateAccountFlag: true }, () =>
                                this.deactivateAccount()
                            )
                        }
                        onClose={() => this.setState({ deactivateAccountConfirmModal: false })}
                        headerText='Deactivate Account'
                        bodyText='Are you sure you want to deactivate this Account?'
                    />
                )}

                <ChangePhoneOrEmail
                    open={!this.state.disableEmail}
                    onClose={() => this.handleDisable('email')}
                    headerText={'Change Email'}
                    email={this.state.email.value}
                    phoneNo={this.state.password.value}
                    onChange={this.onChange}
                    input1={'New email'}
                    input2={'Password'}
                    type={'email'}
                    history={this.props.history}
                />

                <ChangePhoneOrEmail
                    open={!this.state.disablePhone}
                    onClose={() => this.handleDisable('phoneNo')}
                    headerText={'Change Phone Number'}
                    input1={'New Phone Number'}
                    input2={'Password'}
                    type={'phone'}
                    history={this.props.history}
                />

                <NavPrompt formDirty={this.state.formDirty} />
                <ChangePassword
                    passwordModal={this.state.passwordModal}
                    history={this.props.history}
                    closePasswordModal={this.closePasswordModal}
                />

                {!this.state.isSignUpWizardCompleted && (
                    <Elements stripe={stripePromise}>
                        <SignUpWIzard
                            {...this.props}
                            wizard={this.state.wizard}
                            setWizard={this.setWizard}
                        />
                    </Elements>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    stripe: state.stripe
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    stripeActions: bindActionCreators(stripeActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(Profile));
