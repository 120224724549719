import React from 'react';
import './newAppHeader.css';
import { Steps } from 'antd';

const NewAppHeader = (props) => {
    const { pointer, setPointer, history } = props;
    const description = 'This is a description.';
    return (
        <div className='new-app-header'>
            <Steps
                current={pointer}
                onChange={(val) => {
                    if (val === 0) {
                        history.push('/patient/appointments/new');
                    }
                    setPointer((prev) => {
                        return val < prev ? val : prev;
                    });
                    // setPointer(val);
                }}
                items={[
                    {
                        title: 'Select Service Provider'
                    },
                    {
                        title: 'Choose Date & Time'
                    },
                    // {
                    //     title: 'Payment Details'
                    // },
                    {
                        title: 'Confirm Appointment'
                    }
                ]}
            />
        </div>
    );
};

export default NewAppHeader;
