import React, { useState, useEffect } from 'react';
import CurrentSubscription from './CurrentSubscription';
import ChooseNewPlan from './ChooseNewPlan';
import './subscription.css';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '@/actions/userActions';
import '@/assets/css/subscriptionPage.css';
import ChangePaymentMethod from './ChangePaymentMethod';
import { Elements } from '@stripe/react-stripe-js';
import ConfirmModal from '../../../modals/ConfirmModal';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, Tag, message } from 'antd';
import Table from '../../Shared/Table';
import ChangeSubscription from './ChangeSubscription';
import { DateTime } from 'luxon';
import PageHeader from '../../Shared/PageHeader';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import getColumns from './Columns';
// let stripePK =
//     'pk_test_51Jat9xGdKelfS2valqmKF2YldfpvfxDlazZUvA6joCR5PAABvEwf3WzpwpmjPO6rUnjY2Bhzz342eHNIRJc13EQ600VeqkMeCR';

let stripePK =
    'pk_test_51OshNaP1wHcK7uYtaTkzbHiW99wgFGC1Tuxh6DyknlFbhKCk891aLsuL6ch5FdrlAe6fDPWOqPxT1TggnFgmR4nM007RIFoAkg';

const stripePromise = loadStripe(stripePK);

const iframeStyles = {
    base: {
        color: '#000',
        fontSize: '16px',
        iconColor: '#000',
        '::placeholder': {
            color: 'gray'
        }
    },
    invalid: {
        iconColor: '#f14c48',
        color: '#f14c48'
    },
    complete: {
        iconColor: '#cbf4c9'
    }
};

const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    hidePostalCode: true
};

const Subscription = (props) => {
    const auth = useSelector((state) => state.auth);
    const { user, userProfile: profile, productTypes, invoices, upcomingInvoice } = auth;
    const dispatch = useDispatch();
    const { stripeInvoiceColumns, sslCommerzInvoiceColumns } = getColumns();
    const [pmModal, setPmModal] = useState(false);
    const [cancelSubscriptionFlag, setCancelSubscriptionFlag] = useState(false);
    const [subscriptionChangeModal, setSubscriptionChangeModal] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [chosenProduct, setChosenProduct] = useState({
        value: '',
        isValid: true,
        message: ''
    });
    const [chosenPackage, setChosenPackage] = useState('Essentials');
    const [billingType, setBillingType] = useState('trial');
    const [yearlyBill, setYearlyBill] = useState(0);

    const [doctorCount, setDoctorCount] = useState(1);
    const [featured, setFeatured] = useState(false);
    const [featuredDoctorCount, setFeaturedDoctorCount] = useState(0);
    let packages = productTypes.filter((item, index) => item.baseSubscriptionPackage == true);

    useEffect(() => {
        dispatch(userActions.getProductTypes(props, props.history));
    }, []);

    const changeSubscription = async () => {
        let obj = {
            subscribedPackage: chosenPackage,
            billingCycle: 'year',
            userLimit: doctorCount,
            featuredUserLimit: featuredDoctorCount,
            featured: featured
        };

        let res = await dispatch(userActions.changeSubscription({ ...props, obj }, props.history));
        console.log('got in', res);
        if (res) {
            let profileShort = {
                userId: user._id,
                userType: user.userType
            };
            await dispatch(userActions.getProfile(profileShort, props));
            await dispatch(userActions.getinvoices(props, props.history));
            setSubscriptionChangeModal(false);
        }
    };

    useEffect(() => {
        setDoctorCount(user?.userLimit);
        setFeatured(user?.featured);
        setFeaturedDoctorCount(user?.featuredUserLimit);
        setChosenProduct({
            ...chosenProduct,
            value: packages.filter((item) => item.productName == user?.currentSubscribedPackage)[0]
                ?._id
        });
    }, [user]);

    const cancelSubscription = async () => {
        let subscriptionId = auth?.user?.stripeSubscriptionId;

        let res = await dispatch(
            userActions.cancelBusinessSubscription({ ...props, subscriptionId }, props.history)
        );
        if (res) {
            let profileShort = {
                userId: props.auth.user._id,
                userType: props.auth.user.userType
            };
            await dispatch(userActions.getProfile(profileShort, props));
            await dispatch(userActions.getinvoices(props, props.history));
        }
        setCancelSubscriptionFlag(false);
    };

    const submitChangePmForm = async (paymentMethod) => {
        console.log(paymentMethod);
        if (paymentMethod) {
            let obj = {
                paymentMethod
            };

            let res = await dispatch(
                userActions.changeStripePaymentMethod({ obj, ...props }, props.history)
            );
            if (res) {
                setPmModal(false);
                let profileShort = {
                    userId: props.auth.user._id,
                    userType: props.auth.user.userType
                };
                await dispatch(userActions.getProfile(profileShort, props));

                setActionLoading(false);
            } else {
                message.error('Server error');
                setActionLoading(false);
            }
        }
    };

    useEffect(() => {
        dispatch(userActions.getinvoices(props, props.history));
    }, []);

    const handleDoctorCount = (from) => {
        if (from === 'minus') {
            if (doctorCount > 1) {
                setDoctorCount(doctorCount - 1);
                // setMonthlyBill(monthlyBill - 5)
                // setYearlyBill(yearlyBill - 5)
            }
        }
        if (from === 'plus') {
            setDoctorCount(doctorCount + 1);
            // setMonthlyBill(monthlyBill + 5)
            // setYearlyBill(yearlyBill + 5)
        }
    };

    const handleFeaturedDoctorCount = (from) => {
        if (from === 'minus') {
            if (featuredDoctorCount > 0) {
                setFeaturedDoctorCount(featuredDoctorCount - 1);
                // setMonthlyBill(monthlyBill - 10)
                // setYearlyBill(yearlyBill - (10 * 12))
            }
        }
        if (from === 'plus') {
            if (doctorCount > featuredDoctorCount) {
                setFeaturedDoctorCount(featuredDoctorCount + 1);
                // setMonthlyBill(monthlyBill + 10)
                // setYearlyBill(yearlyBill + (10 * 12))
            }
        }
    };
    const handleCardDetailsChange = (cardElement) => {
        console.log(cardElement);
        // if (cardElement.error && cardElement.error.message) {
        //   setCardElementError(cardElement.error.message);
        //   setCardElementValid(false);
        // } else {
        //   setCardElementError("");
        //   setCardElementValid(true);
        // }
        // if(cardElement.empty){
        //   setCardElementValid(false);
        //   setCardElementError(cardElement.error.message)
        // }

        // ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };
    const invoiceColumns = invoices[0]?.invoice_pdf
        ? stripeInvoiceColumns
        : sslCommerzInvoiceColumns;

    return (
        <div className='subscriptionPage'>
            <PageHeader heading={'Subscriptions'} />
            {profile.subscriptionUrlLink && (
                <Alert
                    style={{ marginBottom: 20 }}
                    icon={<Tag color='error'>!</Tag>}showIcon
                    message={
                        <a
                            style={{ textDecoration: 'underline' }}
                            href={profile.subscriptionUrlLink}
                        >
                            Please pay for your subscription
                        </a>
                    }
                    type='error'
                />
            )}
            <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <CurrentSubscription />
                    <ChooseNewPlan
                        openPmModal={() => setPmModal(true)}
                        openSubscriptionChangeModal={() => setSubscriptionChangeModal(true)}
                        openSubscriptionCancelModal={() => setCancelSubscriptionFlag(true)}
                    />
                </div>
                <div style={{ overflow: 'auto', width: 900, maxWidth: '100%', flex: 2 }}>
                    <div>
                        <h3 style={{ fontWeight: 500, marginBottom: 20 }}>Invoice History</h3>
                        <Table tableName={'Invoices'} columns={invoiceColumns} data={invoices} />
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <h3 style={{ fontWeight: 500, marginBottom: 20 }}>Upcoming Invoice</h3>
                        <Table
                            tableName={'Upcoming Invoice'}
                            columns={invoiceColumns.filter(
                                (item) => item.title !== 'Invoice No.' && item.title !== 'Invoice'
                            )}
                            data={Object.keys(upcomingInvoice).length > 0 ? [upcomingInvoice] : []}
                        />
                    </div>
                </div>
            </div>
            <Elements stripe={stripePromise}>
                <ChangePaymentMethod
                    pmModal={pmModal}
                    setPmModal={setPmModal}
                    handleCardDetailsChange={handleCardDetailsChange}
                    cardElementOpts={cardElementOpts}
                    submitChangePmForm={submitChangePmForm}
                    actionLoading={actionLoading}
                    setActionLoading={setActionLoading}
                    productTypes={productTypes}
                    chosenProduct={chosenProduct}
                    setChosenProduct={setChosenProduct}
                    {...props}
                />
            </Elements>

            <ChangeSubscription
                subscriptionChangeModal={subscriptionChangeModal}
                setSubscriptionChangeModal={setSubscriptionChangeModal}
                actionLoading={actionLoading}
                setActionLoading={setActionLoading}
                productTypes={productTypes}
                chosenProduct={chosenProduct}
                yearlyBill={yearlyBill}
                setYearlyBill={setYearlyBill}
                chosenPackage={chosenPackage}
                setChosenPackage={setChosenPackage}
                billingType={billingType}
                setBillingType={setBillingType}
                setChosenProduct={setChosenProduct}
                changeSubscription={changeSubscription}
                doctorCount={doctorCount}
                setDoctorCount={setDoctorCount}
                featured={featured}
                setFeatured={setFeatured}
                featuredDoctorCount={featuredDoctorCount}
                setFeaturedDoctorCount={setFeaturedDoctorCount}
                handleDoctorCount={handleDoctorCount}
                handleFeaturedDoctorCount={handleFeaturedDoctorCount}
                user={user}
            />

            {cancelSubscriptionFlag && (
                <ConfirmModal
                    {...props}
                    onClose={() => setCancelSubscriptionFlag(false)}
                    acceptMethod={() => cancelSubscription()}
                    headerText='Cancel Subscription'
                    bodyText='Are you sure you want to cancel your active Subscription?'
                />
            )}
        </div>
    );
};

export default Subscription;
